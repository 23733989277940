import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, shareReplay, catchError } from 'rxjs/operators';
import { ApiService } from 'src/app/core/services/api.service';

@Injectable({ providedIn: 'root' })

export class HrService {

  constructor(private api: ApiService) { }

  agentCreateBank<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/Agent/CreateBank');
  }

  agentCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/Agent/Create');
  }

  agentUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Hr/Agent/Update');
  }

  agentDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Hr/Agent/Delete');
  }

  agentGet<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Hr/Agent/Get/${ query }`);
  }

  agentFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/Agent/Filter');
  }

  agentGetAll<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/Agent/GetAll');
  }

  agentGetOperationReport<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/Agent/GetOperationReport');
  }

  agentCreateWarning<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/Agent/CreateWarning');
  }

  agentUpdateWarning<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Hr/Agent/UpdateWarning');
  }

  agentDeleteWarning<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Hr/Agent/DeleteWarning');
  }

  agentGetWarnings<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Hr/Agent/GetWarnings/${ query }`);
  }

  agentSetActivation<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Hr/Agent/SetActivation');
  }

  agentFilterIncomeLogs<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/Agent/FilterIncomeLogs');
  }

  agentGetAgentIncomeLogsReport<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/Agent/GetAgentIncomeLogsReport');
  }

  agentSalaryDetailCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/AgentSalaryDetail/Create');
  }

  agentSalaryDetailUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Hr/AgentSalaryDetail/Update');
  }

  agentSalaryDetailDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Hr/AgentSalaryDetail/Delete');
  }

  agentSalaryDetailGet<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Hr/AgentSalaryDetail/Get/${ query }`);
  }

  agentSalaryDetailFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/AgentSalaryDetail/Filter');
  }

  agentSalaryDetailCalculateSalary<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/AgentSalaryDetail/CalculateSalary');
  }

  agentSalaryDetailApprove<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Hr/AgentSalaryDetail/Approve');
  }

  departmentCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/Department/Create');
  }

  departmentUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Hr/Department/Update');
  }

  departmentDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Hr/Department/Delete');
  }

  departmentFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/Department/Filter');
  }

  employeeCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/Employee/Create');
  }

  employeeCreateUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/Employee/CreateUpdate');
  }

  employeeUpdatePassportInfo<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Hr/Employee/UpdatePassportInfo');
  }

  employeeDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Hr/Employee/Delete');
  }

  employeeGetById<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Hr/Employee/GetById/${ query }`);
  }

  employeeGetByUserIdentifier<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Hr/Employee/GetByUserIdentifier/${ query }`);
  }

  employeeGetByUserId<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Hr/Employee/GetByUserId/${ query }`);
  }

  employeeFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/Employee/Filter');
  }

  employeeGetAll<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/Employee/GetAll');
  }

  employeeGetByBranches<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/Employee/GetByBranches');
  }

  employeeGetOrganizationalPosition<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/Employee/GetOrganizationalPosition');
  }

  employeeAddAttachment<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/Employee/AddAttachment');
  }

  employeeDeleteAttachment<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Hr/Employee/DeleteAttachment');
  }

  employeeGetAttachments<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Hr/Employee/GetAttachments/${ query }`);
  }

  employeeUpdatePersonnelCode<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Hr/Employee/UpdatePersonnelCode');
  }

  employeeContractCreateOrUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/EmployeeContract/CreateOrUpdate');
  }

  employeeContractDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Hr/EmployeeContract/Delete');
  }

  employeeContractGet<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/EmployeeContract/Get');
  }

  employeeContractFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/EmployeeContract/Filter');
  }

  employeeContractAddAttachment<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/EmployeeContract/AddAttachment');
  }

  employeeContractDeleteAttachment<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Hr/EmployeeContract/DeleteAttachment');
  }

  employeeContractGetAttachments<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/EmployeeContract/GetAttachments');
  }

  employeeDependantCreateUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/EmployeeDependant/CreateUpdate');
  }

  employeeDependantDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Hr/EmployeeDependant/Delete');
  }

  employeeDependantGet<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Hr/EmployeeDependant/Get/${ query }`);
  }

  employeeDependantGetByEmployee<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Hr/EmployeeDependant/GetByEmployee/${ query }`);
  }

  employeeDependantGetInfoByEmployee<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Hr/EmployeeDependant/GetInfoByEmployee/${ query }`);
  }

  employeeFineOrRewardCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/EmployeeFineOrReward/Create');
  }

  employeeFineOrRewardUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Hr/EmployeeFineOrReward/Update');
  }

  employeeFineOrRewardDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Hr/EmployeeFineOrReward/Delete');
  }

  employeeFineOrRewardGet<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/EmployeeFineOrReward/Get');
  }

  employeeRuleCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/EmployeeRule/Create');
  }

  employeeRuleUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Hr/EmployeeRule/Update');
  }

  employeeRuleDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Hr/EmployeeRule/Delete');
  }

  employeeRuleGet<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Hr/EmployeeRule/Get/${ query }`);
  }

  employeeRuleGetByEmployee<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Hr/EmployeeRule/GetByEmployee/${ query }`);
  }

  employeeRuleFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/EmployeeRule/Filter');
  }

  employeeRuleSalaryFactorCreateUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/EmployeeRuleSalaryFactor/CreateUpdate');
  }

  employeeRuleSalaryFactorDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Hr/EmployeeRuleSalaryFactor/Delete');
  }

  employeeRuleSalaryFactorGetByRule<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Hr/EmployeeRuleSalaryFactor/GetByRule/${ query }`);
  }

  employmentRequestCreateOrUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/EmploymentRequest/CreateOrUpdate');
  }

  employmentRequestSetUser<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Hr/EmploymentRequest/SetUser');
  }

  employmentRequestDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Hr/EmploymentRequest/Delete');
  }

  employmentRequestSetRequestReview<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/EmploymentRequest/SetRequestReview');
  }

  employmentRequestSetInterview<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/EmploymentRequest/SetInterview');
  }

  employmentRequestSetCanceled<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/EmploymentRequest/SetCanceled');
  }

  employmentRequestSetInterviewResult<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/EmploymentRequest/SetInterviewResult');
  }

  employmentRequestGet<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/EmploymentRequest/Get');
  }

  employmentRequestFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/EmploymentRequest/Filter');
  }

  employmentRequestAddAttachment<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/EmploymentRequest/AddAttachment');
  }

  employmentRequestDeleteAttachment<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Hr/EmploymentRequest/DeleteAttachment');
  }

  employmentRequestGetAttachments<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/EmploymentRequest/GetAttachments');
  }

  endOfCooperationRequestCreateUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/EndOfCooperationRequest/CreateUpdate');
  }

  endOfCooperationRequestSetStatus<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Hr/EndOfCooperationRequest/SetStatus');
  }

  endOfCooperationRequestConfirmDischarge<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Hr/EndOfCooperationRequest/ConfirmDischarge');
  }

  endOfCooperationRequestDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Hr/EndOfCooperationRequest/Delete');
  }

  endOfCooperationRequestGet<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Hr/EndOfCooperationRequest/Get/${ query }`);
  }

  endOfCooperationRequestFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/EndOfCooperationRequest/Filter');
  }

  exportGetAllAgentSalaryDetailReport<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/Export/GetAllAgentSalaryDetailReport');
  }

  exportFilterJobTitleReportBySalaryPeriodReport<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/Export/FilterJobTitleReportBySalaryPeriodReport');
  }

  exportFilterJobTitleReportWithTotalSalaryReport<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/Export/FilterJobTitleReportWithTotalSalaryReport');
  }

  exportFilterOrganizationalPositionReport<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/Export/FilterOrganizationalPositionReport');
  }

  imprestCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/Imprest/Create');
  }

  imprestUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Hr/Imprest/Update');
  }

  imprestDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Hr/Imprest/Delete');
  }

  imprestGet<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Hr/Imprest/Get/${ query }`);
  }

  imprestFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/Imprest/Filter');
  }

  imprestPaidToEmployee<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Hr/Imprest/PaidToEmployee');
  }

  imprestRepaid<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Hr/Imprest/Repaid');
  }

  imprestSetStatus<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Hr/Imprest/SetStatus');
  }

  jobGroupCreateUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/JobGroup/CreateUpdate');
  }

  jobGroupDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Hr/JobGroup/Delete');
  }

  jobGroupGet<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Hr/JobGroup/Get/${ query }`);
  }

  jobGroupFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/JobGroup/Filter');
  }

  jobTitleCreateUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/JobTitle/CreateUpdate');
  }

  jobTitleDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Hr/JobTitle/Delete');
  }

  jobTitleGet<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Hr/JobTitle/Get/${ query }`);
  }

  jobTitleFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/JobTitle/Filter');
  }

  jobTitleFilterJobTitleReportBySalaryPeriod<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/JobTitle/FilterJobTitleReportBySalaryPeriod');
  }

  jobTitleFilterJobTitleReportWithTotalSalary<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/JobTitle/FilterJobTitleReportWithTotalSalary');
  }

  loansCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/Loans/Create');
  }

  loansUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Hr/Loans/Update');
  }

  loansDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Hr/Loans/Delete');
  }

  loansReviewAndComplete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Hr/Loans/ReviewAndComplete');
  }

  loansCancelLoan<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Hr/Loans/CancelLoan');
  }

  loansPayLoanToEmployee<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Hr/Loans/PayLoanToEmployee');
  }

  loansGet<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/Loans/Get');
  }

  loansFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/Loans/Filter');
  }

  organizationalPositionCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/OrganizationalPosition/Create');
  }

  organizationalPositionUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Hr/OrganizationalPosition/Update');
  }

  organizationalPositionDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Hr/OrganizationalPosition/Delete');
  }

  organizationalPositionSetDescriptions<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Hr/OrganizationalPosition/SetDescriptions');
  }

  organizationalPositionGetByIds<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/OrganizationalPosition/GetByIds');
  }

  organizationalPositionFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/OrganizationalPosition/Filter');
  }

  organizationalPositionGetByJobTitleId<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/OrganizationalPosition/GetByJobTitleId');
  }

  salaryListCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/SalaryList/Create');
  }

  salaryListDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Hr/SalaryList/Delete');
  }

  salaryListSetFunctionDetail<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Hr/SalaryList/SetFunctionDetail');
  }

  salaryListAddNewEmployeeToSalaryList<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/SalaryList/AddNewEmployeeToSalaryList');
  }

  salaryListRemoveEmployeeFromSalaryList<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Hr/SalaryList/RemoveEmployeeFromSalaryList');
  }

  salaryListFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/SalaryList/Filter');
  }

  salaryListFilterSalaryListDetail<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/SalaryList/FilterSalaryListDetail');
  }

  salaryListGetDetailsBySalaryListId<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Hr/SalaryList/GetDetailsBySalaryListId/${ query }`);
  }

  salaryListSalaryCalculation<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Hr/SalaryList/SalaryCalculation');
  }

  salaryListConfirm<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Hr/SalaryList/Confirm');
  }

  salaryListReturn<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Hr/SalaryList/Return');
  }

  salaryPeriodGetDaysCount<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Hr/SalaryPeriod/GetDaysCount/${ query }`);
  }

  settingCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/Setting/Create');
  }

  settingFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/Setting/Filter');
  }

  settingGetSettingFormData<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/Setting/GetSettingFormData');
  }

  settingCreateRecord<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/Setting/CreateRecord');
  }

  settingUpdateRecord<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/Setting/UpdateRecord');
  }

  settingDeleteRecord<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/Setting/DeleteRecord');
  }

  settingFilterSettingData<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/Setting/FilterSettingData');
  }

  settingGetByConstant<T>(data: {}) {
    return this.api.post<T>(data, '/Hr/Setting/FilterSettingData').pipe(map((res) => res.data), shareReplay(), catchError(() => of([])));
  }

  settingFilterSettingDataWithSearch<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/Setting/FilterSettingDataWithSearch');
  }

  settingDefaultSettingUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Hr/Setting/DefaultSettingUpdate');
  }

  settingDefaultSettingFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/Setting/DefaultSettingFilter');
  }

  teamCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/Team/Create');
  }

  teamUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Hr/Team/Update');
  }

  teamDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Hr/Team/Delete');
  }

  teamFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/Team/Filter');
  }

  teamGet<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Hr/Team/Get/${ query }`);
  }

  userGet<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Hr/User/Get/${ query }`);
  }

  userCertificateCreateUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/UserCertificate/CreateUpdate');
  }

  userCertificateDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Hr/UserCertificate/Delete');
  }

  userCertificateGet<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Hr/UserCertificate/Get/${ query }`);
  }

  userCertificateFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/UserCertificate/Filter');
  }

  userEducationCreateUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/UserEducation/CreateUpdate');
  }

  userEducationSetIsLast<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Hr/UserEducation/SetIsLast');
  }

  userEducationDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Hr/UserEducation/Delete');
  }

  userEducationGet<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Hr/UserEducation/Get/${ query }`);
  }

  userEducationFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/UserEducation/Filter');
  }

  userJobCreateUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/UserJob/CreateUpdate');
  }

  userJobSetJobInfo<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Hr/UserJob/SetJobInfo');
  }

  userJobDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Hr/UserJob/Delete');
  }

  userJobGet<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Hr/UserJob/Get/${ query }`);
  }

  userJobFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/UserJob/Filter');
  }

  userJobSetTag<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/UserJob/SetTag');
  }

  userJobGetUserJobTags<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Hr/UserJob/GetUserJobTags/${ query }`);
  }

  userSkillCreateUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/UserSkill/CreateUpdate');
  }

  userSkillDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Hr/UserSkill/Delete');
  }

  userSkillGet<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Hr/UserSkill/Get/${ query }`);
  }

  userSkillFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/UserSkill/Filter');
  }

  userSkillSetTag<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Hr/UserSkill/SetTag');
  }

  userSkillGetUserSkillTags<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Hr/UserSkill/GetUserSkillTags/${ query }`);
  }

}
