import { DatePipe, DecimalPipe } from "@angular/common";
import { ButtonRenderer } from "src/app/shared/ui/grid-table/renderer/button-renderer";
import { CheckboxRenderer } from "src/app/shared/ui/grid-table/renderer/checkbox-renderer";
import { CustomHeader } from "src/app/shared/ui/grid-table/renderer/custom-header.component";
import { DateRenderer } from 'src/app/shared/ui/grid-table/renderer/date-renderer';
import { DropdownButtonRenderer } from "src/app/shared/ui/grid-table/renderer/dropdown-button-renderer";
import { DropdownRenderer } from "src/app/shared/ui/grid-table/renderer/dropdown-renderer";
import { DropdownTreeRenderer } from "src/app/shared/ui/grid-table/renderer/dropdown-tree-renderer";
import { LinkRenderer } from "src/app/shared/ui/grid-table/renderer/link-renderer";
import { ProgressRenderer } from "src/app/shared/ui/grid-table/renderer/progress-renderer";
import { StatusRenderer } from "src/app/shared/ui/grid-table/renderer/status-renderer";
import { TotalTimeRenderer } from "src/app/shared/ui/grid-table/renderer/total-time-renderer";
import { environment } from "../../../environments/environment";
import { PAY_STATUS, PAY_STATUS_2, REQUEST_STATUS, LENGTH_UNIT, WEIGHT_UNIT } from "../constants";
import { TranslatePipe } from "../pipes/translate.pipe";
import { TranslateService } from "../services/translate.service";
import {
  ActionStatuses,
  ActiveStatuses,
  AidRequest2Statuses,
  AidRequestStatuses,
  AidTypes,
  Ancestry,
  ApprovalStatuses,
  ApprovalStatuses2,
  BoxStatuses,
  CashAidStatus,
  CashDeliveryStatuses,
  CashTransferDeliveryStatus,
  CollectionLocationsStatuses,
  CollectionPeriodsStatuses,
  CooperationStatuses,
  CureStatuses,
  DeliveryToCashierStatus,
  DeliveryTypes,
  DonorTypes,
  EconomicStatuses, EducationStatuses,
  EmployeeContractStatuses,
  EmployeeEndOfCooperationRequestStatuses,
  EmployeeLoanStatuses,
  EmploymentRequestStatuses,
  ExitGoodOrderStatus,
  FamilyRegisterRequestSteps,
  FamilyRegisterRequestTypes,
  FamilyTypes,
  FoodIntakeStatuses,
  Gender,
  GoodDonationRequestStatuses,
  GoodsBuyingRequestStatus,
  ImprestStatus, IncomeTypes,
  MaritalStatus,
  NecessityTypes,
  ObjectiveAidStatuses,
  PaymentReceiptToTheAgentStatuses,
  QualityStatuses,
  QualityStatuses2,
  ReceiptAndPaymentTypes,
  ReceiveStatuses,
  ReturnFromCashierStatuses,
  ServiceDonationStatuses,
  SponsorshipPaySalaryRequestStatuses,
  SponsorshipPaySalaryRequestStatuses2,
  SponsorshipStatuses,
  TransferCashStatuses,
  TransferGoodDeliveryStatuses,
  TransferGoodStatuses,
  TransferStatus,
  HealthStatuses,
  ProgramRequestStatuses,
  TeamTypes,
  FixAssetStatuses,
  Payers,
  BookValueHistoryFixAssetStatuses,
} from './enums';

const env = environment.api;
const siteUrl = environment.siteUrl;
const accountingSiteUrl = environment.accountingSiteUrl;
const datePipe = new DatePipe("en-Us");
const pricePipe = new DecimalPipe("en-Us");
let translatePipe: TranslatePipe;

export class ColDefsClass {
  setTranslatePipe() {
    translatePipe = new TranslatePipe(new TranslateService());
  }
}

export const CancelReasonCols = [
  { field: "#", headerName: "#", minWidth: 60, pinned: "right" },
  {
    field: "title",
    headerName: "TITLE",
    minWidth: 100,
  },
  {
    field: "reasonType",
    headerName: "REASON_TYPE",
    minWidth: 100,
  },
  {
    field: "description",
    headerName: "DESCRIPTIONS",
    minWidth: 150,
  },
  {
    field: "action",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["EDIT", "DELETE"] },
  },
];

export const PersonDonorCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60 },
  { field: 'donorCode', headerName: 'DONOR_CODE', minWidth: 150 },
  { field: 'fullName', headerName: 'FULL_NAME', minWidth: 130 },
  { field: 'createDate', headerName: 'CREATE_DATE', minWidth: 180 },
  { field: 'branchesTitles', headerName: 'BRANCH', minWidth: 130 },
  { field: 'agentsTitles', headerName: 'ACCOUNT_PARTIES', minWidth: 200 },
  { field: '', headerName: 'ANCESTRY', minWidth: 115, cellRenderer: (params: any) => translatePipe.transform(Ancestry[params.data.ancestry]) },
  { field: '', headerName: 'GENDER', minWidth: 115, cellRenderer: (params: any) => translatePipe.transform(Gender[params.data.gender]) },
  { field: 'job', headerName: 'JOB', minWidth: 80 },
  { field: 'phone', headerName: 'PHONE', minWidth: 170 },
  { field: 'email', headerName: 'EMAIL', minWidth: 100 },
  { field: '', headerName: 'STATUS', pinned: 'left', minWidth: 190, cellRenderer: StatusRenderer, cellRendererParams: (params: any) => {
    params.data.statusName = ActiveStatuses[params.data.isActive ? 1 : 0];
    params.data.theme = params.data.isActive ? 'green' : 'red';
  }},
  { field: 'action', headerName: '', pinned: 'left', minWidth: 85, cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ['EDIT', 'BRANCHES', 'ACCOUNT_PARTIES', 'CHANGE_STATUS'] }
  }
];

export const InstituteDonorCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60 },
  { field: 'donorCode', headerName: 'DONOR_CODE', minWidth: 150 },
  { field: 'fullName', headerName: 'ORGANIZATION_NAME', minWidth: 130 },
  { field: 'accountParty', headerName: 'RELATED_PERSON', minWidth: 130 },
  { field: 'createDate', headerName: 'CREATE_DATE', minWidth: 180 },
  { field: 'branchesTitles', headerName: 'BRANCH', minWidth: 130 },
  { field: 'job', headerName: 'JOB', minWidth: 80 },
  { field: 'phone', headerName: 'PHONE', minWidth: 170 },
  { field: '', headerName: 'STATUS', pinned: 'left', minWidth: 190, cellRenderer: StatusRenderer, cellRendererParams: (params: any) => {
    params.data.statusName = ActiveStatuses[params.data.isActive ? 1 : 0];
    params.data.theme = params.data.isActive ? 'green' : 'red';
  }},
  { field: 'action', headerName: '', pinned: 'left', minWidth: 85, cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ['BRANCHES', 'ACCOUNT_PARTIES', 'CHANGE_STATUS'] } // 'EDIT'
  }
];

export const JobListCols = [
  { field: "#", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "title", headerName: "TITLE", minWidth: 100 },
  { field: "description", headerName: "DESCRIPTION", minWidth: 200 },
  {
    field: "action",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["EDIT", "DELETE"] },
  },
];

export const FinancialDonationRequestCols = [
  { field: "#", headerName: "#", minWidth: 60, pinned: "right" },
  {
    field: "requestDate",
    sortable: true,
    headerName: "DONATION_REQUEST_DATE",
    minWidth: 180,
    cellRenderer: (params) => {
      return datePipe.transform(params.data.requestDate, "YYYY-MM-dd");
    },
  },
  {
    field: "cashDonationRequestCode",
    headerName: "DONATION_REQUEST_CODE",
    minWidth: 100,
  },
  {
    field: "amount",
    headerName: "REQUESTED_AMOUNT",
    sortable: true,
    minWidth: 170,
    cellRenderer: (params) => {
      if (params.data.amountIQD !== null) {
        const amountIQD = pricePipe.transform(params.data.amountIQD);
        return amountIQD;
      }
      return 0;
    },
  },
  {
    field: "receiptAmount",
    headerName: "PAID_AMOUNT",
    sortable: true,
    minWidth: 170,
    cellRenderer: (params) => {
      if (params.data.receiptAmount !== null) {
        const receiptAmount = pricePipe.transform(params.data.receiptAmount);
        return receiptAmount;
      }
      return 0;
    },
  },
  {
    field: "remainingAmount",
    headerName: "REMAINING_DEBT",
    minWidth: 150,
    sortable: true,
    cellRenderer: (params) => {
      if (params.data.remainingAmount !== null) {
        const remainingAmount = pricePipe.transform(
          params.data.remainingAmount
        );
        return remainingAmount;
      }
      return 0;
    },
  },
  {
    field: "withdrawalAmount",
    headerName: "WITHDRAWAL_AMOUNT",
    minWidth: 150,
    sortable: true,
    cellRenderer: (params) => {
      if (
        params.data.amountIQD !== null &&
        params.data.remainingAmount !== null &&
        params.data.receiptAmount !== null
      ) {
        const withdrawalAmount = pricePipe.transform(
          params.data.amountIQD -
            (params.data.remainingAmount + params.data.receiptAmount)
        );
        return withdrawalAmount;
      }
      return 0;
    },
  },
  { field: "branchTitle", headerName: "BRANCH", minWidth: 150 },
  {
    field: "donorFullName",
    headerName: "DONOR",
    sortable: true,
    minWidth: 150,
  },
  {
    field: "donorCode",
    headerName: "DONOR_CODE",
    minWidth: 150,
  },
  { field: "agentUser", headerName: "BRANCH_AGENT", minWidth: 150 },
  { field: "description", headerName: "DESCRIPTION", minWidth: 200 },

  /* {
    field: "",
    headerName: "TATVIRI_AMOUNT_AR",
    minWidth: 170,
    cellRenderer: (params) => {
      if (params.data.tatviriAmount !== null) {
        const tatviriAmount = pricePipe.transform(params.data.tatviriAmount);
        return tatviriAmount;
      }
      return 0;
    },
  }, */
  {
    field: "status",
    headerName: "STATUS",
    pinned: "left",
    minWidth: 150,
    type: "template",
    style: { left: "48px" },
    cellRenderer: (params) => {
      // translatePipe = new TranslatePipe(new TranslateService());
      const index = params.data.status;
      return `<div class="status status-${
        PAY_STATUS[index].color
      }">${translatePipe.transform(PAY_STATUS[index].title)}</div>`;
    },
  },
  // {
  //   field: "refuseStatus",
  //   headerName: "",
  //   minWidth: 100,
  //   type: "template",
  //   cellRenderer: (params) => {
  //     switch (params.data.statusId) {
  //       case 1:
  //         return `<i class="bx bx-x font-size-22 fw-bolder"></i>`;

  //       case 2:
  //         return `<i class="bx bx-check font-size-22 fw-bolder"></i>`;

  //       default:
  //         break;
  //     }
  //   },
  // },
  {
    field: "action",
    headerName: "",
    minWidth: 50,
    pinned: "left",
    type: "dropdown",
    cellStyle: { overflow: "unset" },
    cellRendererParams: (params) => {
      let dropdownItems = [];
      if (params.data.status === 1) {
        dropdownItems = ["EDIT", "PRINT"];
      } else {
        dropdownItems = ["EDIT", "PRINT", "DELETE"];
      }
      return dropdownItems;
    },
  },
];

export const FinancialDonationReceiptCols = [
  { field: "#", headerName: "#", minWidth: 60, pinned: "right" },
  {
    field: "receiptDate",
    headerName: "RECEIPT_PAPER_DATE",
    minWidth: 120,
    sortable: true,
    cellRenderer: (params) => {
      return datePipe.transform(params.data.receiptDate, "YYYY-MM-dd");
    },
  },
  {
    field: "paperReceiptNumber",
    headerName: "RECEIPT_PAPER_NUMBER",
    minWidth: 120,
    sortable: true,
  },
  { field: "donationTree", headerName: "PROJECT_TREE", minWidth: 150 },
  { field: "cashTree", headerName: "CASH_TREE_FIELD", minWidth: 150, cellRenderer: (params) => params.data.cashTree || params.data.product || params.data.service },
  {
    field: "amount",
    headerName: "RECEIPT_AMOUNT",
    minWidth: 100,
    cellRenderer: (params) => pricePipe.transform(params.data.amount),
  },
  { field: "beneficiaryType", headerName: "BENEFICIARY_TYPE", minWidth: 150 },
  { field: 'campaign', headerName: 'CAMPAIGN_OR_FESTIVAL', minWidth: 150, cellRenderer: (params: any) => params.data.campaign || params.data.festival },
  { field: "agentFullName", headerName: "AGENT", minWidth: 180 },
  { field: "receiptCode", headerName: "RECEIPT_CODE", minWidth: 180 },
  { field: "donorCode", headerName: "DONOR_CODE", minWidth: 180 },
  { field: "donor", headerName: "DONOR", minWidth: 180 },
  {
    field: "donationRequestCode",
    headerName: "DONATION_REQUEST_CODE",
    minWidth: 180,
    type: "link",
    cellRenderer: LinkRenderer,
    cellRendererParams: (params) => {
      return {
        url: siteUrl + "/donation/cash/create-request?code=" + params.data.donationRequestCode,
      };
    },
  },
  {
    field: "requestDate",
    headerName: "DONATION_REQUEST_DATE",
    minWidth: 120,
    cellRenderer: (params) => {
      return datePipe.transform(params.data.requestDate, "YYYY-MM-dd");
    },
  },
  {
    field: "deliveryDate",
    headerName: "CASH_DELIVERY_DATE",
    minWidth: 120,
    cellRenderer: (params) => {
      return datePipe.transform(params.data.deliveryDate, "YYYY-MM-dd");
    },
  },
  {
    field: "deliveryToCashierCode",
    headerName: "CASH_DELIVERY_CODE",
    minWidth: 180,
    type: "link",
    cellRenderer: LinkRenderer,
    cellRendererParams: (params) => {
      return {
        url: siteUrl + "/donation/cash/cash-receipt-view/" + params.data.deliveryToCashierCode,
      };
    },
  },
  { field: "transfereeUserFullName", headerName: "RECEIVER", minWidth: 150 },
  {
    field: "deliveryToCashierApproveDate",
    headerName: "CASH_DELIVERY_CONFIRM_DATE",
    minWidth: 120,
    cellRenderer: (params) => {
      return datePipe.transform(params.data.deliveryToCashierApproveDate, "YYYY-MM-dd");
    },
  },
  { field: "branch", headerName: "BRANCH", minWidth: 180 },
  { field: "description", headerName: "DESCRIPTION", minWidth: 180 },
  {
    field: "accountingDocumentNumber",
    headerName: "ACCOUNTING_DOCUMENT_NUMBER",
    minWidth: 100,
    type: "link",
    cellRenderer: LinkRenderer,
    cellRendererParams: (params: any) => {
      return {
        url:
          accountingSiteUrl + "/#/app/newdoc2/" +
          params.data.accountingDocumentNumber,
      };
    },
  },
  {
    field: "",
    headerName: "STATUS",
    pinned: "left",
    minWidth: 250,
    // customWidth: 250,
    type: "status",
    cellRenderer: StatusRenderer,
    cellRendererParams: (params: any) => {
      switch (params.data.deliveryStatus) {
        case 0:
          params.data.statusName = ReceiveStatuses[params.data.deliveryStatus];
          params.data.theme = "yellow";
          break;
        case 1:
          params.data.statusName =
            ReceiveStatuses[params.data.deliveryStatus] +
            "_" +
            DeliveryToCashierStatus[params.data.deliveryToCashierStatus];
          switch (params.data.deliveryToCashierStatus) {
            case 0:
              params.data.theme = "green-300";
              break;
            case 1:
              params.data.theme = "green-600";
              break;
            case 2:
              params.data.theme = "green-900";
              break;
          }
          break;
        case 2:
          params.data.statusName =
            ReceiveStatuses[params.data.deliveryStatus] +
            "_" +
            ReturnFromCashierStatuses[params.data.returnFromCashierStatus];
          switch (params.data.returnFromCashierStatus) {
            case 1:
              params.data.theme = "red-300";
              break;
            case 2:
              params.data.theme = "red-600";
              break;
            case 3:
              params.data.theme = "red-900";
              break;
          }
          break;
      }
      return params;
    },
  },
];

export const FinancialDonationRequestDetailCols = [
  { field: "#", headerName: "#", width: 60, pinned: "right" },
  { field: "campaign", headerName: "CAMPAIGN", minWidth: 180 },
  { field: "donationTree", headerName: "INCOME", minWidth: 150 },
  { field: "", headerName: "CASH_TREE_FIELD", minWidth: 150, cellRenderer: (params) => params.data.cashTree || params.data.product || params.data.service },
  {
    field: "beneficiaryType",
    headerName: "BENEFICIARY_TYPE",
    minWidth: 100,
  },
  { field: "familyMember", headerName: "FAMILY_MEMBERS", minWidth: 150 },
  {
    field: "",
    headerName: "REQUESTED_AMOUNT",
    minWidth: 170,
    cellRenderer: (params) => {
      if (params.data.amount !== null) {
        const amount = pricePipe.transform(params.data.amount);
        return amount;
      }
      return 0;
    },
  },
  {
    field: "",
    headerName: "PAID_AMOUNT",
    minWidth: 170,
    cellRenderer: (params) => {
      if (params.data.receiptAmount !== null) {
        const receiptAmount = pricePipe.transform(params.data.receiptAmount);
        return receiptAmount;
      }
      return 0;
    },
  },
  /* {
    field: "",
    headerName: "TATVIRI_AMOUNT_AR",
    minWidth: 170,
    cellRenderer: (params) => {
      if (params.data.tatviriAmount !== null) {
        const tatviriAmount = pricePipe.transform(params.data.tatviriAmount);
        return tatviriAmount;
      }
      return 0;
    },
  }, */
  {
    field: "",
    headerName: "REMAINING_DEBT",
    minWidth: 150,
    cellRenderer: (params) => {
      if (params.data.remainingAmount !== null) {
        const remainingAmount = pricePipe.transform(
          params.data.remainingAmount
        );
        return remainingAmount;
      }
      return 0;
    },
  },
  {
    field: "withdrawalAmount",
    headerName: "WITHDRAWAL_AMOUNT",
    minWidth: 150,
    sortable: true,
    cellRenderer: (params) => {
      if (
        params.data.amountIQD !== null &&
        params.data.remainingAmount !== null &&
        params.data.receiptAmount !== null
      ) {
        const withdrawalAmount = pricePipe.transform(
          params.data.amountIQD -
            (params.data.remainingAmount + params.data.receiptAmount)
        );
        return withdrawalAmount;
      }
      return 0;
    },
  },
  { field: "description", headerName: "DESCRIPTION", minWidth: 200 },
  {
    field: "statusId",
    headerName: "STATUS",
    pinned: "left",
    width: 150,
    type: "template",
    style: { paddingLeft: "45px" },
    cellRenderer: (params) => {
      const index = params.data.status;
      return `<div class="status status-${
        PAY_STATUS[index].color
      }">${translatePipe.transform(PAY_STATUS[index].title)}</div>`;
    },
  },
  // {
  //   field: "refuseStatus",
  //   headerName: "",
  //   minWidth: 100,
  //   type: "template",
  //   cellRenderer: (params) => {
  //     switch (params.data.statusId) {
  //       case 1:
  //         return `<i class="bx bx-x font-size-22 fw-bolder"></i>`;

  //       case 2:
  //         return `<i class="bx bx-check font-size-22 fw-bolder"></i>`;

  //       default:
  //         break;
  //     }
  //   },
  // },
  {
    field: "action",
    headerName: "",
    width: 80,
    pinned: "left",
    type: "dropdown",
    // cellRenderer: DropdownButtonRenderer,
    cellStyle: { overflow: "unset" },
    cellRendererParams: (params) => {
      let dropdownItems = [];
      switch (params.data.status) {
        case 0:
          dropdownItems = ["ADD_INQUIRY", "INQUIRY_HISTORY"];
          break;
        case 1:
          dropdownItems = ["INQUIRY_HISTORY"];
          break;
        case 2:
          dropdownItems = [
            // "ADD_INQUIRY",
            "INQUIRY_HISTORY",
            "REVIEW_WITHDRAW_PAYMENT",
          ];
          break;
      }
      return dropdownItems;
    },
  },
];

export const CashDonationListCols = [
  { field: "#", headerName: "#", pinned: "right" },
  {
    field: "cashDonationRequestDate",
    headerName: "DONATION_REQUEST_DATE",
    minWidth: 120,
    cellRenderer: (params) => {
      return datePipe.transform(params.data.cashDonationRequestDate, "YYYY-MM-dd");
    },
  },
  { field: "donor", headerName: "DONOR" },
  { field: "donationTree", headerName: "INCOME" },
  { field: "titleTree", headerName: "CASH_TREE_FIELD", minWidth: 150, cellRenderer: (params) => params.data.cashTree || params.data.product || params.data.service },
  {
    field: "beneficiaryType",
    headerName: "BENEFICIARY_TYPE",
  },
  {
    field: "cashDonationRequestCode",
    headerName: "REQUEST_CODE",
    minWidth: 160,
    type: "link",
    cellRenderer: LinkRenderer,
    cellRendererParams: (params) => {
      return {
        url: siteUrl + "/donation/cash/create-request?code=" + params.data.cashDonationRequestCode,
      };
    },
  },
  { field: "campaign", headerName: "CAMPAIGN" },
  { field: "supervisor", headerName: "SUPERVISOR" },
  { field: "beneficiaryName", headerName: "FAMILY_MEMBERS" },
  { field: "agentFullName", headerName: "AGENT", minWidth: 150 },
  {
    field: "amount",
    headerName: "REQUESTED_AMOUNT",

    cellRenderer: (params) => {
      return params.data.amount !== null ? pricePipe.transform(params.data.amount) : 0;
    },
  },
  {
    field: "receiptAmount",
    headerName: "PAID_AMOUNT",

    cellRenderer: (params) => {
      return params.data.receiptAmount !== null ? pricePipe.transform(params.data.receiptAmount) : 0;
    },
  },
  /* {
    field: "",
    headerName: "TATVIRI_AMOUNT_AR",

    cellRenderer: (params) => {
      if (params.data.tatviriAmount !== null) {
        const tatviriAmount = pricePipe.transform(params.data.tatviriAmount);
        return tatviriAmount;
      }
      return 0;
    },
  }, */
  {
    field: "remainingAmount",
    headerName: "REMAINING_DEBT",

    cellRenderer: (params) => {
      return params.data.remainingAmount !== null ? pricePipe.transform(params.data.remainingAmount) : 0;
    },
  },
  {
    field: "withdrawalAmount",
    headerName: "WITHDRAWAL_AMOUNT",

    sortable: true,
    cellRenderer: (params) => {
      if (
        params.data.amountIQD !== null &&
        params.data.remainingAmount !== null &&
        params.data.receiptAmount !== null
      ) {
        const withdrawalAmount = pricePipe.transform(
          params.data.amountIQD -
            (params.data.remainingAmount + params.data.receiptAmount)
        );
        return withdrawalAmount;
      }
      return 0;
    },
  },
  { field: "description", headerName: "DESCRIPTION" },
  {
    field: "",
    headerName: "STATUS",
    pinned: "left",
    // resizable: false,
    type: "status",
    style: { left: "75px" },
    // cellRenderer: (params) => {
    //   const index = params.data.status;
    //   return `<div class="status status-${
    //     PAY_STATUS[index].color
    //   }">${translatePipe.transform(PAY_STATUS[index].title)}</div>`;
    // },
    cellRenderer: StatusRenderer,
    cellRendererParams: (params) => {
      params.data.statusName = PAY_STATUS[params.data?.status]?.title;
      switch (params.data.status) {
        case 0:
          params.data.theme = "yellow";
          break;
        case 1:
          params.data.theme = "green";
          break;
        case 2:
          params.data.theme = "orange";
          break;
        case 3:
          params.data.theme = "red";
          break;
      }
      return params;
    },
  },
];

export const FinancialDonationReceiveListCols = [
  { field: "#", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "donationRequestId", headerName: "DONATION_ID", minWidth: 160 }, // sabet
  { field: "donor", headerName: "DONOR_NAME", minWidth: 160 }, // khali
  {
    field: "requestDate",
    headerName: "DONATION_DATE",
    minWidth: 200,
    sortable: false,
  },
  {
    field: "id",
    headerName: "RECEIPT_ID",
    minWidth: 200,
    sortable: false,
  },
  {
    field: "receiptType",
    headerName: "RECEIPT_TYPE",
    minWidth: 200,
    cellRenderer: (params) => {
      return translatePipe.transform(
        ReceiptAndPaymentTypes[params.data.receiptType]
      );
    },
    sortable: false,
  },
  {
    field: "receiptDate",
    headerName: "DATE_AND_TIME",
    minWidth: 200,
    sortable: false,
  },
  {
    field: "",
    headerName: "AMOUNT_AR",
    minWidth: 200,
    sortable: false,
    cellRenderer: (params) => {
      const amount = pricePipe.transform(params.data.amount);
      return `<div>${amount}</div>`;
    },
  },
  {
    field: "",
    headerName: "DONATION_TATVIRI_AMOUNT",
    minWidth: 200,
    sortable: false,
    cellRenderer: (params) => {
      const tatviriAmount = pricePipe.transform(params.data.tatviriAmount);
      return `<div>${tatviriAmount}</div>`;
    },
  },
  {
    field: "",
    headerName: "TOTAL_AMOUNT_AR",
    minWidth: 200,
    sortable: false,
    cellRenderer: (params) => {
      const totalAmount = pricePipe.transform(params.data.totalAmount);
      return `<div>${totalAmount}</div>`;
    },
  },
  {
    field: "description",
    headerName: "RECEIPT_DESCRIPTION",
    minWidth: 200,
    sortable: false,
  },
  { field: "agentUserIdentifier", headerName: "BRANCH_AGENT", minWidth: 160 },
  {
    field: "deliveryToCashierId",
    headerName: "REGISTERING_USER",
    minWidth: 160,
  },
  {
    field: "createDate",
    headerName: "REGISTER_DATE_AND_TIME",
    minWidth: 170,
  },
  /* {
    field: "deliveryStatus",
    headerName: "DELIVERY_STATUS",
    pinned: "left",
    minWidth: 160,
    cellRenderer: StatusRenderer,
    cellRendererParams: (params) => {
      params.data.statusName = ReceiveStatus[params.data.deliveryStatus];
      switch (params.data.deliveryStatus) {
        case 0:
          params.data.theme = "red";
          break;

        case 1:
          params.data.theme = "green";
          break;

        default:
          break;
      }
    },
  }, */
  {
    field: "action",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["ATTACH", "PRINT", "DELETE"] },
  },
];

export const DonorModalDefCols = [
  { field: "#", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "name", headerName: "NAME", minWidth: 160, editable: true },
  { field: "job", headerName: "JOB", minWidth: 160, editable: true },
  { field: "mobile", headerName: "MOBILE", minWidth: 160, editable: true },
  { field: "address", headerName: "ADDRESS", minWidth: 160, editable: true },
];

export const CashReceiptDonationCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  {
    field: "deliveryToCashierCode",
    headerName: "FUND_RECEIPT_CODE",
    minWidth: 180,
    sortable: true,
    cellRenderer: (param) =>
      param.data.lastStatus ? param.data.deliveryToCashierCode : null,
  },
  {
    field: "deliveryDate",
    headerName: "CASH_DELIVERY_DATE",
    minWidth: 170,
    sortable: true,
    cellRenderer: (param) =>
      datePipe.transform(param.data.deliveryDate, "YYYY-MM-dd"),
  },
  {
    field: "branchTitle",
    headerName: "BRANCH",
    minWidth: 150,
    maxWidth: 150,
    cellStyle: { "text-overflow": "ellipsis", "white-space": "nowrap" },
  },
  {
    field: "",
    headerName: "TOTAL_AMOUNT_AR",
    minWidth: 160,
    cellRenderer: (params) => pricePipe.transform(params.data.totalAmount),
  },
  { field: "agentUser", headerName: "AGENT", minWidth: 120 },
  { field: "transfereeUser", headerName: "RECEIVER", minWidth: 120 },
  { field: "description", headerName: "DESCRIPTION", minWidth: 200 },
  {
    field: "",
    headerName: "AUDIT_DATE",
    minWidth: 150,
    cellRenderer: (param) =>
      datePipe.transform(param.data.auditDate, "YYYY-MM-dd"),
  },
  {
    field: "",
    headerName: "CONFIRM_DATE",
    minWidth: 150,
    cellRenderer: (param) =>
      datePipe.transform(param.data.confirmDate, "YYYY-MM-dd"),
  },
  {
    field: "accountingDocumentNumber",
    headerName: "ACCOUNTING_DOCUMENT_NUMBER",
    minWidth: 100,
    type: "link",
    cellRenderer: LinkRenderer,
    cellRendererParams: (params: any) => {
      return {
        url:
          accountingSiteUrl + "/#/app/newdoc2/" +
          params.data.accountingDocumentNumber,
      };
    },
  },
  {
    field: "",
    headerName: "STATUS",
    pinned: "left",
    minWidth: 190,
    cellRenderer: StatusRenderer,
    cellRendererParams: (params) => {
      params.data.statusName = DeliveryToCashierStatus[params.data.lastStatus];
      switch (params.data.lastStatus) {
        case 0:
          params.data.theme = "orange";
          break;
        case 1:
          params.data.theme = "yellow";
          break;
        case 2:
          params.data.theme = "green";
          break;
      }
    },
  },
  {
    field: "action",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: (params) => {
      let dropdownItems = [];
      switch (params.data.lastStatus) {
        case 0:
          dropdownItems = ["VIEW", "EDIT", "AUDIT", "DELETE"];
          break;
        case 1:
          dropdownItems = ["VIEW", "VIEW_AUDIT_INFO", "CONFIRM"];
          break;
        case 2:
          dropdownItems = ["VIEW", "VIEW_APPROVE_INFO", "RETURN"]; // , "HISTORY"
          break;
      }
      return { dropdownItems: dropdownItems };
    },
  },
];

export const CashReceiptDonationModalCols = [
  {
    field: "check",
    headerName: "SELECT",
    minWidth: 100,
    pinned: "right",
    cellRenderer: CheckboxRenderer,
    cellRendererParams: { disabled: true },
  },
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  {
    field: "paperReceiptNumber",
    headerName: "RECEIPT_PAPER_NUMBER",
    minWidth: 150,
  },
  {
    field: "receiptDate",
    headerName: "RECEIPT_PAPER_DATE",
    minWidth: 150,
    cellRenderer: (params) =>
      datePipe.transform(params.data.receiptDate, "YYYY-MM-dd"),
  },
  { field: "receiptCode", headerName: "DONATION_RECEIPT_CODE", minWidth: 170 },
  {
    field: "donationTree",
    headerName: "INCOME",
    minWidth: 120,
  },
  { field: "cashTree", headerName: "CASH_TREE_FIELD", minWidth: 150, cellRenderer: (params) => params.data.cashTree || params.data.product || params.data.service },
  { field: "beneficiaryType", headerName: "BENEFICIARY_TYPE", minWidth: 150 },
  {
    field: "requestDate",
    headerName: "DONATION_DATE",
    minWidth: 150,
    cellRenderer: (params) =>
      datePipe.transform(params.data.donationRequestDate, "YYYY-MM-dd"),
  },
  { field: "donor", headerName: "DONOR", minWidth: 150 },
  { field: "donorCode", headerName: "DONOR_CODE", minWidth: 150 },
  { field: "campaign", headerName: "CAMPAIGN", minWidth: 150 },
  {
    field: "createDate",
    headerName: "SUBMIT_RECEIVE_DATE",
    minWidth: 150,
    cellRenderer: (params) =>
      datePipe.transform(params.data.createDate, "YYYY-MM-dd"),
  },
  {
    field: "donationRequestCode",
    headerName: "CASH_DONATION_REQUEST_CODE",
    minWidth: 170,
  },
  {
    field: "receiptType",
    headerName: "RECEIVE_TYPE",
    minWidth: 150,
    cellRenderer: (params) =>
      translatePipe.transform(ReceiptAndPaymentTypes[params.data.receiptType]),
  },
  {
    field: "",
    headerName: "AMOUNT",
    minWidth: 120,
    cellRenderer: (params) => pricePipe.transform(params.data.amount),
  },
  /* {
    field: "",
    headerName: "TATVIRI_AMOUNT",
    minWidth: 120,
    cellRenderer: (params) => pricePipe.transform(params.data.tatviriAmount),
  },
  {
    field: "",
    headerName: "TOTAL_AMOUNT",
    minWidth: 120,
    cellRenderer: (params) => pricePipe.transform(params.data.totalAmount),
  }, */
  { field: "description", headerName: "RECEIVE_DESCRIPTION", minWidth: 200 },
];

export const CashReceiptDonationModal1Cols = [
  {
    field: "check",
    headerName: "RETURN",
    minWidth: 100,
    pinned: "right",
    cellRenderer: CheckboxRenderer,
    cellRendererParams: { disabled: true },
  },
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  {
    field: "paperReceiptNumber",
    headerName: "RECEIPT_PAPER_NUMBER",
    minWidth: 150,
  },
  {
    field: "receiptDate",
    headerName: "RECEIPT_PAPER_DATE",
    minWidth: 150,
    cellRenderer: (params) =>
      datePipe.transform(params.data.receiptDate, "YYYY-MM-dd"),
  },
  { field: "receiptCode", headerName: "DONATION_RECEIPT_CODE", minWidth: 170 },
  {
    field: "donationTree",
    headerName: "INCOME",
    minWidth: 120,
  },
  { field: "cashTree", headerName: "CASH_TREE_FIELD", minWidth: 150, cellRenderer: (params) => params.data.cashTree || params.data.product || params.data.service },
  { field: "beneficiaryType", headerName: "BENEFICIARY_TYPE", minWidth: 150 },
  {
    field: "requestDate",
    headerName: "DONATION_DATE",
    minWidth: 150,
    cellRenderer: (params) =>
      datePipe.transform(params.data.donationRequestDate, "YYYY-MM-dd"),
  },
  { field: "donor", headerName: "DONOR_NAME", minWidth: 150 },
  { field: "donorCode", headerName: "DONOR_CODE", minWidth: 150 },
  { field: "campaign", headerName: "CAMPAIGN", minWidth: 150 },
  {
    field: "createDate",
    headerName: "SUBMIT_RECEIVE_DATE",
    minWidth: 150,
    cellRenderer: (params) =>
      datePipe.transform(params.data.createDate, "YYYY-MM-dd"),
  },
  {
    field: "donationRequestCode",
    headerName: "CASH_DONATION_REQUEST_CODE",
    minWidth: 170,
  },
  {
    field: "receiptType",
    headerName: "RECEIVE_TYPE",
    minWidth: 150,
    cellRenderer: (params) =>
      translatePipe.transform(ReceiptAndPaymentTypes[params.data.receiptType]),
  },
  {
    field: "",
    headerName: "AMOUNT",
    minWidth: 120,
    cellRenderer: (params) => pricePipe.transform(params.data.amount),
  },
  /* {
    field: "",
    headerName: "TATVIRI_AMOUNT",
    minWidth: 120,
    cellRenderer: (params) => pricePipe.transform(params.data.tatviriAmount),
  },
  {
    field: "",
    headerName: "TOTAL_AMOUNT",
    minWidth: 120,
    cellRenderer: (params) => pricePipe.transform(params.data.totalAmount),
  }, */
  { field: "description", headerName: "RECEIVE_DESCRIPTION", minWidth: 200 },
];

export const FinancialDonationReceiveCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "id", headerName: "RECEIPT_ID", minWidth: 120 },
  {
    field: "receiptType",
    headerName: "RECEIVE_TYPE",
    minWidth: 140,
    cellRenderer: (params) => {
      return translatePipe.transform(
        ReceiptAndPaymentTypes[params.data.receiptType]
      );
    },
  },
  {
    field: "receiptDate",
    headerName: "DATE",
    minWidth: 170,
    cellRendererParams: (params) => {
      params.data.receiptDate = datePipe.transform(
        params.data.receiptDate,
        "YYYY-MM-dd"
      );
    },
  },
  {
    field: "",
    headerName: "AMOUNT_AR",
    minWidth: 120,
    cellRenderer: (params) => {
      const amount = pricePipe.transform(params.data.amount);
      return `<div>${amount}</div>`;
    },
  },
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  {
    field: "",
    headerName: "TATVIRI_AMOUNT_AR",
    minWidth: 200,
    cellRenderer: (params) => {
      const tatviriAmount = pricePipe.transform(params.data.tatviriAmount);
      return `<div>${tatviriAmount}</div>`;
    },
  },
  {
    field: "",
    headerName: "TOTAL_AMOUNT_AR",
    minWidth: 160,
    cellRenderer: (params) => {
      const totalAmount = pricePipe.transform(params.data.totalAmount);
      return `<div>${totalAmount}</div>`;
    },
  },
  { field: "description", headerName: "DESCRIPTION", minWidth: 160 },
  {
    field: "agentUserIdentifier2",
    headerName: "BRANCH_AGENT",
    minWidth: 220,
  },
  {
    field: "",
    headerComponent: CustomHeader,
    headerComponentParams: {
      data: ["REGISTERING_USER", "SUBMIT_DATE"],
    },
    cellRenderer: (params) =>
      `<div> <div> ${params.data.registrar} </div> <div class='mt-n3'> ${params.data.createDate} </div> </div>`,
    minWidth: 180,
  },
  {
    field: "action",
    headerName: "",
    minWidth: 100,
    pinned: "left",
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["ATTACH", "PRINT", "DELETE"] },
  },
];

export const FinancialReturnReceiptCols = [
  { field: "#", headerName: "#", pinned: "right" },
  {
    field: "returnFromCashierCode",
    headerName: "RETURN_TO_CASH_RECEIPT_CODE",
  },
  {
    field: "returnDate",
    headerName: "CASH_RETURN_DATE",

    cellRenderer: (params) => {
      return datePipe.transform(params.data.returnDate, "YYYY-MM-dd");
    },
  },
  {
    field: "deliveryToCashierCode",
    headerName: "CASH_DELIVERY_CODE",
    type: 'link',
    cellRenderer: LinkRenderer,
    cellRendererParams: (params: any) => { return { url: siteUrl + '/donation/cash/cash-receipt-view/' + params.data.deliveryToCashierCode }}
  },
  {
    field: "deliveryDate",
    headerName: "CASH_DELIVERY_DATE",

    cellRenderer: (params) => {
      return datePipe.transform(params.data.deliveryDate, "YYYY-MM-dd");
    },
  },
  { field: "branch", headerName: "BRANCH" },
  {
    field: "",
    headerName: "AMOUNT",

    cellRenderer: (params) => pricePipe.transform(params.data.amountIQD),
  },
  { field: "agent", headerName: "AGENT" },
  { field: "transferee", headerName: "RECEIVER" },
  {
    field: "returnDescription",
    headerName: "DESCRIPTION",
  },
  {
    field: "deliveryToCashiersApproveDate",
    headerName: "CONFIRM_DATE",

    cellRenderer: (params) =>
      datePipe.transform(
        params.data.deliveryToCashiersApproveDate,
        "YYYY-MM-dd"
      ),
  },
  {
    field: "deliveryToCashiersApproveUser",
    headerName: "CORROBORANT",
  },
  {
    field: "accountingDocumentNumber",
    headerName: "ACCOUNTING_DOCUMENT_NUMBER",
    minWidth: 100,
    type: "link",
    cellRenderer: LinkRenderer,
    cellRendererParams: (params: any) => {
      return {
        url:
          accountingSiteUrl + "/#/app/newdoc2/" +
          params.data.accountingDocumentNumber,
      };
    },
  },
  {
    field: "",
    headerName: "STATUS",
    pinned: "left",

    cellRenderer: StatusRenderer,
    cellRendererParams: (params: any) => {
      params.data.statusName = ReturnFromCashierStatuses[params.data.status];
      switch (params.data.status) {
        case 1:
          params.data.theme = "orange";
          break;
        case 2:
          params.data.theme = "yellow";
          break;
        case 3:
          params.data.theme = "green";
          break;
        default:
          break;
      }
    },
  },
  {
    field: "action",
    headerName: "",
    pinned: "left",

    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: (params: any) => {
      let dropdownItems = ["VIEW"];
      switch (params.data.status) {
        case 1:
          dropdownItems.push("AUDIT", "DELETE");
          break;
        case 2:
          dropdownItems.push("CONFIRM");
          break;
        // case 3: dropdownItems = [""]; break;
        default:
          break;
      }
      return { dropdownItems };
    },
  },
];

export const CampaignListCols = [
  { field: "#", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "type", headerName: "CAMPAIGN_TYPE", minWidth: 200 },
  { field: "title", headerName: "TITLE", minWidth: 200 },
  {
    field: "",
    headerName: "START_DATE",
    minWidth: 120,
    cellRenderer: (params) =>
      datePipe.transform(params.data.startDate, "YYYY-MM-dd"),
  },
  {
    field: "",
    headerName: "END_DATE",
    minWidth: 120,
    cellRenderer: (params) =>
      datePipe.transform(params.data.endDate, "YYYY-MM-dd"),
  },
  { field: "description", headerName: "DESCRIPTION", minWidth: 200 },
  {
    field: "action",
    headerName: "",
    minWidth: 100,
    pinned: "left",
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["EDIT", "DELETE"] },
  },
];

export const ReturnFromWarehouseCols = [
  { field: "#", headerName: "#", minWidth: 60, pinned: "right" },
  {
    field: "goodReturnReceiptId",
    headerName: "GOOD_RETURN_RECEIPT_ID",
    minWidth: 180,
  },
  {
    field: "returnDateFromWarehouse",
    headerName: "RETURN_DATE_FROM_WAREHOUSE",
    minWidth: 180,
  },
  { field: "registrar", headerName: "REGISTERAR", minWidth: 160 },
  { field: "submitDate", headerName: "SUBMIT_DATE", minWidth: 160 },
  {
    field: "receiptWarehouseId",
    headerName: "WAREHOUSE_RECEIPT_ID",
    minWidth: 160,
  },
  {
    field: "receiptWarehouseDate",
    headerName: "WAREHOUSE_RECEIPT_DATE",
    minWidth: 160,
  },
  { field: "branch", headerName: "BRANCH", minWidth: 160 },
  { field: "donationId", headerName: "DONATION_ID", minWidth: 160 },
  { field: "donationDate", headerName: "DONATION_DATE", minWidth: 160 },
  { field: "donor", headerName: "DONOR", minWidth: 160 },
  { field: "agent", headerName: "AGENT", minWidth: 160 },
  { field: "receiver", headerName: "RECEIVER", minWidth: 160 },
  { field: "descriptions", headerName: "DESCRIPTIONS", minWidth: 160 },
  {
    field: "action",
    headerName: "",
    minWidth: 85,
    pinned: "left",
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["EDIT", "DELETE"] },
  },
];

export const WarehouseReceiptListCols = [
  { field: "#", headerName: "#", minWidth: 60, pinned: "right" },
  {
    field: "warehouseReceiptId",
    headerName: "WAREHOUSE_RECEIPT_ID",
    minWidth: 160,
  },
  {
    field: "date",
    headerName: "DATE",
    minWidth: 160,
  },
  {
    field: "branch",
    headerName: "BRANCH",
    minWidth: 160,
  },
  {
    field: "transporter",
    headerName: "TRANSPORTER",
    minWidth: 160,
  },
  {
    field: "donationId",
    headerName: "DONATION_ID",
    minWidth: 160,
  },
  {
    field: "donationDate",
    headerName: "DONATION_DATE",
    minWidth: 160,
  },
  {
    field: "donor",
    headerName: "DONOR",
    minWidth: 160,
  },
  // {
  //   field: 'evaluatedId',
  //   headerName: 'EVALUATION_ID',
  //   minWidth: 160,
  // },
  {
    field: "evaluatedDate",
    headerName: "EVALUATION_DATE",
    minWidth: 160,
  },
  {
    field: "evaluators",
    headerName: "EVALUATORS",
    minWidth: 160,
  },
  {
    field: "descriptions",
    headerName: "DESCRIPTIONS",
    minWidth: 160,
  },
  {
    field: "status",
    headerName: "STATUS",
    pinned: "left",
    minWidth: 190,
    cellRenderer: StatusRenderer,
    cellRendererParams: (params) => {
      params.data.statusName = ApprovalStatuses[params.data.status];
      switch (params.data.status) {
        case 0:
          params.data.theme = "yellow";
          break;
        case 1:
          params.data.theme = "green";
          break;
        case 2:
          params.data.theme = "red";
          break;
        default:
          break;
      }
    },
  },
  {
    field: "action",
    headerName: "",
    minWidth: 85,
    pinned: "left",
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: (params) => {
      let temp = [];
      switch (params.data.status) {
        case 1: temp = ["SHOW", "EVALUATION", "HISTORY"]; break;
        default: temp = ["SHOW", "EVALUATION", "EDIT", "HISTORY", "DELETE"]; break;
      }
      return { dropdownItems: temp };
    },
  },
];

export const GoodRequestConfirmCols = [
  { field: "#", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "donationTree", headerName: "INCOME", minWidth: 100 },
  { field: "beneficiaryType", headerName: "BENEFICIARY_TYPE", minWidth: 160 },
  {
    field: "description",
    headerName: "DESCRIPTION",
    minWidth: 160,
    editable: true,
  },
  {
    field: "action",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    type: "button",
    cellRenderer: {
      text: "DELETE",
      color: "danger",
      type: "fill",
    },
  },
  // {
  //   field: "action",
  //   headerName: "",
  //   minWidth: 80,
  //   pinned: "left",
  //   // type: "dropdown",
  //   cellRenderer: ButtonRenderer,
  //   cellRendererParams: {
  //     type: "fill",
  //     color: "danger",
  //     text: "DELETE",
  //     action: "modal",

  //   },
  //   cellStyle: { overflow: "unset" },
  //   // cellRendererParams: {
  //   //   dropdownItems: ["DELETE"],
  //   // },
  // },
];

export const WarehouseReceiptCols = [
  { field: "#", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "donationTree", headerName: "INCOME", minWidth: 100 },
  { field: "beneficiaryType", headerName: "BENEFICIARY_TYPE", minWidth: 160 },
  { field: 'estimatedPrice', headerName: 'ESTIMATED_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.estimatedPrice || 0) },
  {
    field: "action",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    type: "button",
    cellRenderer: {
      text: "SUBMIT_PRODUCT",
      color: "blue",
      type: "fill",
    },
  },
  // {
  //   field: "action",
  //   headerName: "",
  //   minWidth: 80,
  //   pinned: "left",
  //   type: "dropdown",
  //   cellStyle: { overflow: "unset" },
  //   cellRendererParams: {
  //     dropdownItems: ["REGISTER_NEW_GOOD"],
  //   },
  // },
];

export const WarehouseReceiptSubCols = [
  { field: "#", headerName: "#", pinned: "right", minWidth: 60 },
  { field: "serialNumber", headerName: "GOOD_SERIAL_NUMBER", minWidth: 150 },
  { field: "productCode", headerName: "GOOD_CODE", minWidth: 150 },
  { field: "product", headerName: "GOOD", minWidth: 180, cellRenderer: (params: any) => params.data.productGroup + ' / ' + params.data.product },
  { field: "count", headerName: "COUNT", minWidth: 100 },
  { field: "unit", headerName: "UNIT", minWidth: 100 },
];

export const GoodDonationConfirmCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "donationId", headerName: "DONATION_ID", minWidth: 160 },
  { field: "donationId", headerName: "WAREHOUSE_RECEIPT_ID", minWidth: 160 },
  { field: "donationId", headerName: "WAREHOUSE", minWidth: 160 },
  { field: "donationId", headerName: "GOOD_ID", minWidth: 160 },
  { field: "donationId", headerName: "GOOD", minWidth: 160 },
  { field: "donationId", headerName: "COUNT", minWidth: 160 },
  { field: "donationId", headerName: "UNIT", minWidth: 160 },
  { field: "donationId", headerName: "UNIT_AMOUNT", minWidth: 160 },
  { field: "donationId", headerName: "TOTAL_AMOUNT_AR", minWidth: 160 },
  { field: "donationId", headerName: "EXPIRY_DATE", minWidth: 140 },
  { field: "donationId", headerName: "SERIAL_NUMBER", minWidth: 160 },
  { field: "donationId", headerName: "DESCRIPTION", minWidth: 160 },
  { field: "donationId", headerName: "BUDGETED_COUNT", minWidth: 160 },
  { field: "donationId", headerName: "REMAINED_COUNT", minWidth: 160 },
  {
    field: "action",
    headerName: "",
    minWidth: 85,
    pinned: "left",
    cellRenderer: ButtonRenderer,
    cellRendererParams: {
      type: "fill",
      color: "blue",
      icon: "cog",
      action: "modal",
    },
  },
];

export const GoodDonationCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "goodGroup", headerName: "PRODUCT_GROUP", minWidth: 110 },
  { field: "description", headerName: "DESCRIPTION", minWidth: 140 },
];

export const BudgetedDonationCols = [
  { field: "", headerName: "#", minWidth: 60, editable: true, pinned: "right" },
  { field: "count", headerName: "COUNT", minWidth: 80, editable: true },
  { field: "income", headerName: "INCOME", minWidth: 90, editable: true },
  {
    field: "beneficiaryType",
    headerName: "BENEFICIARY_TYPE",
    minWidth: 150,
    editable: true,
  },
  {
    field: "familyPerson",
    headerName: "PERSON_FAMILY",
    minWidth: 140,
    editable: true,
  },
  {
    field: "action",
    pinned: "left",
    headerName: "",
    minWidth: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["EDIT", "SELECT", "DELETE"] },
  },
];

export const ReturnGoodsCols = [
  { field: "#", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "warehouse", headerName: "WAREHOUSE", minWidth: 125 },
  { field: "goodGroup", headerName: "GOOD_GROUP", minWidth: 130 },
  { field: "goodCode", headerName: "GOOD_CODE", minWidth: 120 },
  { field: "good", headerName: "GOOD", minWidth: 80 },
  { field: "count", headerName: "COUNT", minWidth: 90 },
  { field: "unit", headerName: "UNIT", minWidth: 75 },
  { field: "expiryDate", headerName: "EXPIRY_DATE", minWidth: 125 },
  { field: "serialNumber", headerName: "SERIAL_NUMBER", minWidth: 145 },
  { field: "descriptions", headerName: "DESCRIPTIONS", minWidth: 135 },
  {
    field: "checkbox",
    headerName: "RETURN",
    pinned: "right",
    cellRenderer: CheckboxRenderer,
    minWidth: 95,
  },
  {
    field: "returnCount",
    headerName: "RETURN_COUNT",
    editable: (params) => params.data.checked,
    singleClickEdit: true,
    minWidth: 145,
  },
  {
    field: "returnReason",
    headerName: "RETURN_REASON",
    editable: (params) => params.data.checked,
    singleClickEdit: true,
    minWidth: 150,
  },
];

export const CashReceiptConfirmCols = [
  { field: "#", headerName: "#", minWidth: 60, pinned: "right" },
  {
    field: "paperReceiptNumber",
    headerName: "PAPER_RECEIPT_NUMBER",
    minWidth: 100,
  },
  {
    field: "receiptDate",
    headerName: "RECEIPT_PAPER_DATE",
    minWidth: 160,
    cellRenderer: (params) => {
      return datePipe.transform(params.data.receiptDate, "YYYY-MM-dd");
    },
  },
  {
    field: "deliveryToCashierCode",
    headerName: "FUND_RECEIPT_CODE",
    minWidth: 170,
  }, //?
  {
    field: "donationRequestDate",
    headerName: "DONATION_DATE",
    minWidth: 160,
    cellRenderer: (params) => {
      return datePipe.transform(params.data.donationRequestDate, "YYYY-MM-dd");
    },
  },
  { field: "donor", headerName: "DONOR", minWidth: 160 },
  { field: "donorCode", headerName: "DONOR_CODE", minWidth: 150 },
  { field: "campaign", headerName: "CAMPAIGN", minWidth: 160 },
  { field: "donationTree", headerName: "INCOME", minWidth: 90 },
  { field: "accountTreeName", headerName: "INCOME_ACCOUNT", minWidth: 120 },
  { field: "beneficiaryType", headerName: "BENEFICIARY_TYPE", minWidth: 150 },
  {
    field: "createDate",
    headerName: "SUBMIT_RECEIVE_DATE",
    minWidth: 160,
    cellRenderer: (params) => {
      return datePipe.transform(params.data.createDate, "YYYY-MM-dd");
    },
  },
  {
    field: "receiptCode",
    headerName: "RECEIPT_CODE",
    minWidth: 160,
  },
  {
    field: "receiptType",
    headerName: "RECEIVE_TYPE",
    minWidth: 160,
    cellRenderer: (params) => {
      return translatePipe.transform(
        ReceiptAndPaymentTypes[params.data.receiptType]
      );
    },
  }, //?
  {
    field: "",
    headerName: "AMOUNT_AR",
    minWidth: 160,
    cellRenderer: (params) => pricePipe.transform(params.data.amount),
  },
  /* {
    field: "",
    headerName: "TATVIRI_AMOUNT_AR",
    minWidth: 160,
    cellRenderer: (params) => pricePipe.transform(params.data.tatviriAmount),
  },
  {
    field: "",
    headerName: "TOTAL_AMOUNT_AR",
    minWidth: 160,
    cellRenderer: (params) => pricePipe.transform(params.data.totalAmount),
  }, */
  {
    field: "description",
    headerName: "RECEIVE_DESCRIPTION",
    minWidth: 160,
  },
];

export const CashReceiptConfirmPrintCols = [
  { field: "#", headerName: "#", minWidth: 60, pinned: "right" },
  {
    field: "paperReceiptNumber",
    headerName: "PAPER_RECEIPT_NUMBER",
    minWidth: 100,
  },
  {
    field: "donationRequestDate",
    headerName: "DONATION_DATE",
    minWidth: 130,
    cellRenderer: (params) =>
      datePipe.transform(params.data.donationRequestDate, "YYYY-MM-dd"),
  },
  {
    field: "",
    headerName: "AMOUNT_AR",
    minWidth: 100,
    cellRenderer: (params) => pricePipe.transform(params.data.amount),
  },
  { field: "donor", headerName: "DONOR", minWidth: 160 },
  // { field: "donationTree", headerName: "INCOME", minWidth: 90 },
  { field: "beneficiaryType", headerName: "BENEFICIARY_TYPE", minWidth: 150 },
];

export const GoodsRequestCols = [
  { field: "#", headerName: "#", pinned: "right", minWidth: 60 },
  { field: "branch", headerName: "BRANCH", minWidth: 150 },
  {
    field: "requestDate",
    headerName: "DONATION_REQUEST_DATE",
    minWidth: 120,
    cellRenderer: (param) =>
      datePipe.transform(param.data.requestDate, "YYYY-MM-dd"),
  },
  { field: "requestCode", headerName: "DONATION_REQUEST_CODE", minWidth: 180 },
  {
    field: "paperNumber",
    headerName: "OBJECTIVE_RECEIPT_NUMBER",
    minWidth: 150,
  },
  {
    field: "paperDate",
    headerName: "RECEIPT_PAPER_DATE",
    minWidth: 120,
    cellRenderer: (param) =>
      datePipe.transform(param.data.paperDate, "YYYY-MM-dd"),
  },
  { field: "agent", headerName: "BRANCH_AGENT", minWidth: 150 },
  { field: "donor", headerName: "DONOR", minWidth: 150 },
  { field: "campaign", headerName: "CAMPAIGN", minWidth: 150 },
  // { field: 'tatviriPercentage', headerName: 'TATVIRI_DONATION_PERCENTAGE', minWidth: 100 },
  { field: "transferee", headerName: "RECEIVER", minWidth: 150 },
  // { field: 'evaluators', headerName: 'EVALUATORS', minWidth: 200 },
  {
    field: "sumOfEstimatedPrice",
    headerName: "TOTAL_ESTIMATED_AMOUNT",
    minWidth: 100,
    cellRenderer: (params) => pricePipe.transform(params.data.sumOfEstimatedPrice),
  },
  {
    field: "totalAmount",
    headerName: "EVALUATED_TOTAL_AMOUNT",
    minWidth: 100,
    cellRenderer: (params) => pricePipe.transform(params.data.totalAmount),
  },
  {
    field: "accountingDocumentNumber",
    headerName: "ACCOUNTING_DOCUMENT_NUMBER",
    minWidth: 100,
    type: "link",
    cellRenderer: LinkRenderer,
    cellRendererParams: (params: any) => {
      return {
        url:
          accountingSiteUrl + "/#/app/newdoc2/" +
          params.data.accountingDocumentNumber,
      };
    },
  },
  // { field: 'description', headerName: 'DESCRIPTION', minWidth: 200 },
  {
    field: "",
    headerName: "STATUS",
    pinned: "left",
    minWidth: 180,
    type: "status",
    cellRenderer: StatusRenderer,
    cellRendererParams: (params) => {
      params.data.statusName =
        GoodDonationRequestStatuses[params.data.lastStatus];
      switch (params.data.lastStatus) {
        case 1:
          params.data.theme = "yellow";
          break;
        case 2:
          params.data.theme = "yellow-pink";
          break;
        case 3:
          params.data.theme = "brown";
          break;
        case 4:
          params.data.theme = "orange";
          break;
        case 5:
          params.data.theme = "green";
          break;
        default:
          break;
      }
      return params;
    },
  },
  {
    field: "action",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: (params) => {
      let dropdownItems = [];
      switch (params.data.lastStatus) {
        case 1:
          dropdownItems = !environment.isApp ? ["VIEW", "EDIT", "SAVE_RECEIPT_WAREHOUSE", "DELETE"] : ["EDIT", "DELETE"];
          break;
        case 2:
          dropdownItems = !environment.isApp ? ["VIEW", "RECEIPT_WAREHOUSE"] : [];
          break;
        case 3:
          dropdownItems = !environment.isApp ? ["VIEW", "EVALUATION"] : ["EVALUATION"];
          break;
        case 4:
          dropdownItems = !environment.isApp ? ["VIEW", "APPROVAL"] : ["APPROVAL"];
          break;
        case 5:
          dropdownItems = !environment.isApp ? ["VIEW"] : [];
          break;
        default:
          break;
      }
      return { dropdownItems };
    },
  },
];

export const GoodsRequestCreateUpdateCols = [
  { field: "#", headerName: "#", minWidth: 60 },
  { field: "donationTree", headerName: "INCOME", minWidth: 120 },
  { field: "beneficiaryType", headerName: "BENEFICIARY_TYPE", minWidth: 150 },
  { field: 'estimatedPrice', headerName: 'ESTIMATED_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.estimatedPrice || 0) },
  {
    field: "action",
    headerName: "",
    width: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["EDIT", "DELETE"] },
  },
];

export const GoodsRequestViewCols = [
  { field: "#", headerName: "#", pinned: "right", minWidth: 60 },
  { field: "donationTree", headerName: "INCOME", minWidth: 120 },
  { field: "beneficiaryType", headerName: "BENEFICIARY_TYPE", minWidth: 150 },
  { field: 'estimatedPrice', headerName: 'ESTIMATED_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.estimatedPrice || 0) }
];

export const RequestListDonationServiceCols = [
  { field: "#", headerName: "#", minWidth: 60 },
  {
    field: "requestDate",
    headerName: "DONATION_REQUEST_DATE",
    minWidth: 130,
    cellRenderer: (param) =>
      datePipe.transform(param.data.requestDate, "YYYY-MM-dd"),
  },
  { field: "branchTitle", headerName: "BRANCH", minWidth: 180 },
  { field: "requestCode", headerName: "DONATION_REQUEST_CODE", minWidth: 180 },
  { field: "receiptNumber", headerName: "RECEIPT_PAPER_NUMBER", minWidth: 180 },
  { field: 'receiptDate', headerName: 'RECEIPT_PAPER_DATE', minWidth: 130, cellRenderer: (param) => datePipe.transform(param.data.receiptDate, "YYYY-MM-dd"), },
  { field: "agentFullName", headerName: "BRANCH_AGENT", minWidth: 150 },
  { field: "donor", headerName: "DONOR", minWidth: 180 },
  { field: "donorCode", headerName: "DONOR_CODE", minWidth: 150 },
  {
    field: "totalEstimatedPrice",
    headerName: "TOTAL_ESTIMATED_AMOUNT",
    minWidth: 100,
    cellRenderer: (params) => pricePipe.transform(params.data.totalEstimatedPrice),
  },
  {
    field: "totalAmount",
    headerName: "TOTAL_AMOUNT",
    minWidth: 100,
    cellRenderer: (params) => pricePipe.transform(params.data.totalAmount),
  },
  { field: "description", headerName: "DESCRIPTIONS", minWidth: 200 },
  /* {
    field: "",
    headerName: "STATUS",
    pinned: "left",
    minWidth: 190,
    cellRenderer: StatusRenderer,
    cellRendererParams: (params) => {
      params.data.statusName =
        ServiceDonationRequestStatuses[params.data.lastStatus];
      switch (params.data.lastStatus) {
        case 1:
          params.data.theme = "yellow";
          break;
        case 2:
          params.data.theme = "blue";
          break;
        case 3:
          params.data.theme = "green";
          break;
        default:
          break;
      }
      return params;
    },
  }, */
  {
    field: "action",
    headerName: "",
    type: "dropdown",
    pinned: "left",
    minWidth: 85,
    cellStyle: { overflow: "unset" },
    cellRendererParams: (params) => {
      switch (params.data.hasEvaluatedStatus) {
        case false:
          return !environment.isApp ? ["VIEW_", "EDIT", "DELETE"] : ["EDIT", "DELETE"];
        case true:
          return !environment.isApp ? ["VIEW_", "EVALUATION_INFORMATION", "PRINT"] : ["EVALUATION_INFORMATION", "PRINT"];
        default:
          break;
      }
    },
  },
];

export const RequestList2DonationServiceCols = [
  { field: "#", headerName: "#", minWidth: 60 },
  {
    field: "",
    headerName: "DONATION_REQUEST_DATE",
    minWidth: 130,
    cellRenderer: (param) =>
      datePipe.transform(param.data.requestDate, "YYYY-MM-dd"),
  },
  { field: "branchTitle", headerName: "BRANCH", minWidth: 180 },
  { field: "requestCode", headerName: "DONATION_REQUEST_CODE", minWidth: 180 },
  { field: "agentFullName", headerName: "BRANCH_AGENT", minWidth: 150 },
  { field: "donor", headerName: "DONOR", minWidth: 180 },
  { field: "donorCode", headerName: "DONOR_CODE", minWidth: 150 },
  { field: "donationTree", headerName: "PROJECT_SERVICE", minWidth: 150 },
  // { field: "service", headerName: "SERVICE", minWidth: 150 },
  { field: "beneficiaryType", headerName: "BENEFICIARY_TYPE", minWidth: 150 },
  { field: "quantity", headerName: "BENEFICIARY_COUNT", minWidth: 100 },
  { field: "placeOfService", headerName: "SERVICE_LOCATION", minWidth: 100 },
  {
    field: "serviceDescription",
    headerName: "SERVICE_DESCRIPTION",
    minWidth: 160,
  },
  { field: 'estimatedPrice', headerName: 'ESTIMATED_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.estimatedPrice || 0) },
  {
    field: "",
    headerName: "AMOUNT",
    minWidth: 100,
    cellRenderer: (params) => pricePipe.transform(params.data.amount),
  },
  {
    field: "",
    headerName: "TOTAL_AMOUNT",
    minWidth: 100,
    cellRenderer: (params) => pricePipe.transform(params.data.totalAmount),
  },
  {
    field: "",
    headerName: "EVALUATION_DATE",
    minWidth: 130,
    cellRenderer: (param) =>
      datePipe.transform(param.data.evaluationDate, "YYYY-MM-dd"),
  },
  { field: "evaluatorName", headerName: "EVALUATORS", minWidth: 150 },
  {
    field: "",
    headerName: "CONFIRM_DATE",
    minWidth: 130,
    cellRenderer: (param) =>
      datePipe.transform(param.data.confirmDate, "YYYY-MM-dd"),
  },
  { field: "corroborantName", headerName: "CORROBORANT", minWidth: 150 },
  { field: "description", headerName: "DESCRIPTIONS", minWidth: 200 },
  {
    field: "accountingDocumentNumber",
    headerName: "ACCOUNTING_DOCUMENT_NUMBER",
    minWidth: 100,
    type: "link",
    cellRenderer: LinkRenderer,
    cellRendererParams: (params: any) => {
      return {
        url:
          accountingSiteUrl + "/#/app/newdoc2/" +
          params.data.accountingDocumentNumber,
      };
    },
  },
  {
    field: "",
    headerName: "STATUS",
    type: "status",
    pinned: "left",
    minWidth: 190,
    cellRenderer: StatusRenderer,
    cellRendererParams: (params) => {
      params.data.statusName = ServiceDonationStatuses[params.data.lastStatus];
      switch (params.data.lastStatus) {
        case 1:
          params.data.theme = "yellow";
          break;
        case 2:
          params.data.theme = "orange";
          break;
        case 3:
          params.data.theme = "blue";
          break;
        case 4:
          params.data.theme = "green";
          break;
        case 5:
          params.data.theme = "red";
          break;
        default:
          break;
      }
      return params;
    },
  },
  {
    field: "action",
    headerName: "",
    minWidth: 85,
    pinned: "left",
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: (params) => {
      let dropdownItems = [];
      switch (params.data.lastStatus) {
        case 1:
          dropdownItems = ["CREATE_BENEFICIARY", "DELETE"];
          break;
        case 2:
          dropdownItems = ["EVALUATION", "DELETE"]; // + ==> 'EDIT'
          break;
        case 3:
          dropdownItems = ["CONFIRM"];
          break;
        case 4:
          dropdownItems = ["CREATE_RETURN"];
          break;
        case 5:
          dropdownItems = ["CONFIRM_RETURN"];
          break;
        default:
          break;
      }
      return { dropdownItems }; // + "VIEW" + "HISTORY" + "PRINT"
    },
  },
];

export const RequestList2DonationServiceAdvancedCols = [
  { field: "#", headerName: "#", minWidth: 60 },
  { field: "donationTree", headerName: "PROJECT_SERVICE", minWidth: 150 },
  // { field: "service", headerName: "SERVICE", minWidth: 150 },
  { field: "beneficiaryType", headerName: "BENEFICIARY_TYPE", minWidth: 150 },
  { field: "quantity", headerName: "BENEFICIARY_COUNT", minWidth: 100 },
  { field: 'estimatedPrice', headerName: 'ESTIMATED_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.estimatedPrice || 0) },
  {
    field: "",
    headerName: "AMOUNT",
    minWidth: 100,
    cellRenderer: (params) => pricePipe.transform(params.data.amount),
  },
  {
    field: "",
    headerName: "TOTAL_AMOUNT",
    minWidth: 100,
    cellRenderer: (params) => pricePipe.transform(params.data.totalAmount),
  },
  {
    field: "",
    headerName: "EVALUATION_DATE",
    minWidth: 130,
    cellRenderer: (param) =>
      datePipe.transform(param.data.evaluationDate, "YYYY-MM-dd"),
  },
  { field: "evaluatorName", headerName: "EVALUATORS", minWidth: 150 },
  {
    field: "",
    headerName: "CONFIRM_DATE",
    minWidth: 130,
    cellRenderer: (param) =>
      datePipe.transform(param.data.confirmDate, "YYYY-MM-dd"),
  },
  { field: "corroborantName", headerName: "CORROBORANT", minWidth: 150 },
  { field: "description", headerName: "DESCRIPTIONS", minWidth: 200 },
  {
    field: "accountingDocumentNumber",
    headerName: "ACCOUNTING_DOCUMENT_NUMBER",
    minWidth: 100,
    type: "template",
    cellRenderer: (params: any) => {
      return params.data.accountingDocumentNumber ? `<a href="${accountingSiteUrl}/#/app/newdoc2/${params.data.accountingDocumentNumber}" target="_blank">
        <i class="bx bx-link"> </i>
        <span> ${params.data.accountingDocumentNumber} </span>
      </a>` : '';
    }
  },
  {
    field: "status",
    headerName: "STATUS",
    type: "template",
    pinned: "left",
    minWidth: 150,
    style: { left: "80px" },
    cellRenderer: (params) => {
      params.data.statusName = ServiceDonationStatuses[params.data.lastStatus];
      switch (params.data.lastStatus) {
        case 1:
          params.data.theme = "yellow";
          break;
        case 2:
          params.data.theme = "orange";
          break;
        case 3:
          params.data.theme = "blue";
          break;
        case 4:
          params.data.theme = "green";
          break;
        case 5:
          params.data.theme = "red";
          break;
        default:
          break;
      }
      return `<div class="status status-${
        params.data.theme
      }"> ${translatePipe.transform(params.data.statusName)} </div>`;
    },
  },
  {
    field: "action",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    type: "dropdown",
    cellStyle: { overflow: "unset" },
    cellRendererParams: (params) => {
      switch (params.data.lastStatus) {
        case 1:
          return ["CREATE_BENEFICIARY", "DELETE"];
        case 2:
          return ["EVALUATION", "DELETE"];
        case 3:
          return ["CONFIRM"];
        default:
          break;
      }
    },
  },
];

export const RequestList2DonationServiceSubmitCols = [
  { field: "#", headerName: "#", pinned: "right", minWidth: 60 },
  { field: "donationTree", headerName: "PROJECT_SERVICE", minWidth: 150 },
  { field: "beneficiaryType", headerName: "BENEFICIARY_TYPE", minWidth: 150 },
  { field: "quantity", headerName: "BENEFICIARY_COUNT", minWidth: 100 },
  { field: "campaign", headerName: "CAMPAIGN", minWidth: 180 },
  { field: "festival", headerName: "PROGRAM", minWidth: 180 },
  { field: "placeOfService", headerName: "SERVICE_LOCATION", minWidth: 180 },
  { field: 'estimatedPrice', headerName: 'ESTIMATED_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.estimatedPrice || 0) },
  {
    field: "serviceDescription",
    headerName: "SERVICE_DESCRIPTION",
    minWidth: 180,
  },
  { field: "description", headerName: "DESCRIPTION", minWidth: 200 },
  {
    field: "",
    headerName: "STATUS",
    pinned: "left",
    minWidth: 180,
    type: "status",
    cellRenderer: StatusRenderer,
    cellRendererParams: (params) => {
      params.data.statusName = ServiceDonationStatuses[params.data.lastStatus];
      switch (params.data.lastStatus) {
        case 1:
          params.data.theme = "yellow";
          break;
        case 2:
          params.data.theme = "orange";
          break;
        case 3:
          params.data.theme = "blue";
          break;
        case 4:
          params.data.theme = "green";
          break;
        default:
          break;
      }
      return params;
    },
  },
  {
    field: "action",
    headerName: "",
    minWidth: 85,
    pinned: "left",
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: (params) => {
      let dropdownItems = [];
      switch (params.data.lastStatus) {
        case 1:
          dropdownItems = ["CREATE_BENEFICIARY", "DELETE"];
          break;
        case 2:
          dropdownItems = !environment.isApp ? ["EDIT", "EVALUATION", "DELETE"] : ["EDIT", "DELETE"];
          break;
        case 3:
          dropdownItems = ["CONFIRM"];
          break;
        default:
          break;
      }
      return { dropdownItems }; // + "VIEW" + "HISTORY" + "PRINT"
    },
  },
];

export const RequestList2DonationServiceViewCols = [
  { field: "#", headerName: "#", pinned: "right", minWidth: 60 },
  { field: "donationTree", headerName: "PROJECT_SERVICE", minWidth: 150 },
  // { field: "service", headerName: "SERVICE", minWidth: 150 },
  { field: "beneficiaryType", headerName: "BENEFICIARY_TYPE", minWidth: 150 },
  { field: "quantity", headerName: "BENEFICIARY_COUNT", minWidth: 100 },
  { field: "campaign", headerName: "CAMPAIGN", minWidth: 180 },
  { field: 'estimatedPrice', headerName: 'ESTIMATED_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.estimatedPrice || 0) },
  { field: "description", headerName: "DESCRIPTION", minWidth: 200 },
  {
    field: "",
    headerName: "STATUS",
    pinned: "left",
    minWidth: 200,
    cellRenderer: StatusRenderer,
    cellRendererParams: (params) => {
      params.data.statusName = ServiceDonationStatuses[params.data.lastStatus];
      switch (params.data.lastStatus) {
        case 1:
          params.data.theme = "yellow";
          break;
        case 2:
          params.data.theme = "orange";
          break;
        case 3:
          params.data.theme = "blue";
          break;
        case 4:
          params.data.theme = "green";
          break;
        case 5:
          params.data.theme = "red";
          break;
        default:
          break;
      }
      return params;
    },
  },
];

export const RequestList2DonationServicePrintCols = [
  { field: "#", headerName: "#", minWidth: 60 },
  { field: "donationTree", headerName: "PROJECT_SERVICE", minWidth: 100 },
  // { field: "service", headerName: "SERVICE", minWidth: 150 },
  { field: "beneficiaryType", headerName: "BENEFICIARY_TYPE", minWidth: 150 },
  { field: "quantity", headerName: "BENEFICIARY_COUNT", minWidth: 100 },
  {
    field: "",
    headerName: "AMOUNT",
    minWidth: 100,
    cellRenderer: (params) => pricePipe.transform(params.data.amount),
  },
  {
    field: "",
    headerName: "TOTAL_AMOUNT",
    minWidth: 100,
    cellRenderer: (params) => pricePipe.transform(params.data.totalAmount),
  },
  {
    field: "",
    headerName: "EVALUATION_DATE",
    minWidth: 130,
    cellRenderer: (param) =>
      datePipe.transform(param.data.evaluationDate, "YYYY-MM-dd"),
  },
  {
    field: "",
    headerName: "CONFIRM_DATE",
    minWidth: 130,
    cellRenderer: (param) =>
      datePipe.transform(param.data.confirmDate, "YYYY-MM-dd"),
  },
  { field: "evaluatorName", headerName: "EVALUATORS", minWidth: 150 },
  { field: "corroborantName", headerName: "CORROBORANT", minWidth: 150 },
  { field: "description", headerName: "DESCRIPTIONS", minWidth: 200 },
  {
    field: "accountingDocumentNumber",
    headerName: "ACCOUNTING_DOCUMENT_NUMBER",
    minWidth: 100,
  },
];

export const ReturnCashReceiptCols = [
  {
    field: "check",
    headerName: "SELECT",
    minWidth: 120,
    pinned: "right",
    cellRenderer: CheckboxRenderer,
    cellRendererParams: (params) =>
      params.data.deliveryStatus !== 1
        ? { disabled: true }
        : { disabled: false },
  },
  {
    field: "dropdownItems",
    headerName: "RETURN_REASON",
    minWidth: 150,
    pinned: "left",
    cellRenderer: DropdownRenderer,
    cellRendererParams: (params) => {
      params.data.bindLabel = "title";
      if (params.data.check) {
        return params;
      } else {
        params.data.dropdownItems = [];
        return params;
      }
    },
  },
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  {
    field: "donationRequestCode",
    headerName: "DONATION_REQUEST_CODE",
    minWidth: 210,
  },
  {
    field: "donationRequestDate",
    headerName: "DONATION_DATE",
    minWidth: 120,
    cellRenderer: (params) => {
      return datePipe.transform(params.data.donationRequestDate, "YYYY-MM-dd");
    },
  },
  { field: "donor", headerName: "DONOR", minWidth: 180 },
  { field: "donorCode", headerName: "DONOR_CODE", minWidth: 150 },
  { field: "campaign", headerName: "CAMPAIGN", minWidth: 140 },
  {
    field: "receiptDate",
    headerName: "RECEIPT_PAPER_DATE",
    minWidth: 120,
    cellRenderer: (params) => {
      return datePipe.transform(params.data.receiptDate, "YYYY-MM-dd");
    },
  },
  {
    field: "paperReceiptNumber",
    headerName: "PAPER_RECEIPT_NUMBER",
    minWidth: 100,
  },
  {
    field: "receiptCode",
    headerName: "RECEIPT_CODE",
    minWidth: 160,
  },
  {
    field: "receiptType",
    headerName: "RECEIVE_TYPE",
    minWidth: 120,
    cellRenderer: (params) => {
      return translatePipe.transform(
        ReceiptAndPaymentTypes[params.data.receiptType]
      );
    },
  },
  {
    field: "",
    headerName: "AMOUNT_AR",
    minWidth: 100,
    cellRenderer: (params) => pricePipe.transform(params.data.amount),
  },
  {
    field: "createDate",
    headerName: "SUBMIT_RECEIVE_DATE",
    minWidth: 120,
    cellRenderer: (params) => {
      return datePipe.transform(params.data.createDate, "YYYY-MM-dd");
    },
  },
  /* {
    field: "",
    headerName: "TATVIRI_AMOUNT_AR",
    minWidth: 150,
    cellRenderer: (params) => pricePipe.transform(params.data.tatviriAmount),
  }, */
  // { field: "currencyUnit", headerName: "CURRENCY_UNIT", minWidth: 150 },
  // { field: "currencyRate", headerName: "CURRENCY_RATE", minWidth: 150 },
  /* {
    field: "",
    headerName: "TOTAL_AMOUNT_AR",
    minWidth: 140,
    cellRenderer: (params) => pricePipe.transform(params.data.totalAmount),
  }, */
  {
    field: "description",
    headerName: "RECEIVE_DESCRIPTION",
    minWidth: 220,
  },
];

export const confirmServiceDonationCols = [
  { field: "#", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "program", headerName: "PROGRAM", minWidth: 75 },
  { field: "donationId", headerName: "DONATION_ID", minWidth: 130 },
  { field: "helpServiceId", headerName: "HELP_SERVICE_ID", minWidth: 150 },
  { field: "serviceCode", headerName: "SERVICE_CODE", minWidth: 135 },
  { field: "service", headerName: "SERVICE", minWidth: 95 },
  { field: "count", headerName: "COUNT", minWidth: 85 },
  { field: "unitPrice", headerName: "UNIT_PRICE", minWidth: 115 },
  {
    field: "",
    headerName: "TOTAL_AMOUNT_AR",
    minWidth: 145,
    cellRenderer: (params) => pricePipe.transform(params.data.totalAmount),
  },
  { field: "beneficiaryType", headerName: "BENEFICIARY_TYPE", minWidth: 160 },
  { field: "personFamily", headerName: "PERSON_FAMILY", minWidth: 145 },
  { field: "date", headerName: "DATE", minWidth: 75 },
  { field: "descriptions", headerName: "DESCRIPTIONS", minWidth: 135 },
];

export const GoodDonationDetailCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  // { field: 'campaign', headerName: 'CAMPAIGN', minWidth: 60 },
  { field: "objectGroupId", headerName: "PRODUCT_GROUP", minWidth: 120 },
  { field: "description", headerName: "DESCRIPTION", minWidth: 120 },
  {
    field: "action",
    pinned: "left",
    headerName: "",
    minWidth: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["DELETE"] },
  },
];

export const GoodDonationViewCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  // { field: 'campaign', headerName: 'CAMPAIGN', minWidth: 60 },
  { field: "objectGroupId", headerName: "PRODUCT_GROUP", minWidth: 120 },
  { field: "description", headerName: "DESCRIPTION", minWidth: 120 },
];

export const AidServiceRequestCols = [
  { field: "id", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "branch", headerName: "BRANCH", minWidth: 100 },
  { field: "requestId", headerName: "REQUEST_ID", minWidth: 150 },
  { field: "program", headerName: "PROGRAM", minWidth: 110 },
  { field: "registrar", headerName: "REGISTRAR", minWidth: 110 },
  { field: "requestDate", headerName: "REQUEST_DATE", minWidth: 140 },
  {
    field: "confirmReqeustId",
    headerName: "CONFIRM_REQUEST_ID",
    minWidth: 185,
  },
  { field: "seconder", headerName: "SECONDER", minWidth: 110 },
  { field: "confirmDate", headerName: "CONFIRM_DATE", minWidth: 150 },
  { field: "description", headerName: "DESCRIPTION", minWidth: 140 },
  {
    field: "",
    headerName: "STATUS",
    pinned: "left",
    minWidth: 190,
    cellRenderer: StatusRenderer,
    cellRendererParams: (params) => {
      params.data.statusName = ApprovalStatuses[params.data.status];
      switch (params.data.status) {
        case 0:
          params.data.theme = "yellow";
          break;
        case 1:
          params.data.theme = "green";
          break;
        case 2:
          params.data.theme = "red";
          break;
        default:
          break;
      }
    },
  },
  {
    field: "action",
    headerName: "",
    minWidth: 85,
    pinned: "left",
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: {
      dropdownItems: ["VIEW", "EDIT", "CONFIRM", "DELETE"],
    },
  },
];

export const AidServiceRegisterCols = [
  { field: "id", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "section", headerName: "SECTION", minWidth: 100 },
  { field: "date", headerName: "DATE", minWidth: 100 },
  { field: "responsible", headerName: "RESPONSIBLE", minWidth: 100 },
  { field: "cost", headerName: "COST", minWidth: 100 },
  {
    field: "",
    headerName: "AMOUNT_AR",
    minWidth: 100,
    cellRenderer: (params) => pricePipe.transform(params.data.amount),
  },
  {
    field: "accountPartyReceiptId",
    headerName: "ACCOUNT_PARTY_RECEIPT_ID",
    minWidth: 220,
  },
  { field: "accountParty", headerName: "ACCOUNT_PARTY", minWidth: 100 },
  { field: "description", headerName: "DESCRIPTION", minWidth: 100 },
  {
    field: "action",
    headerName: "",
    minWidth: 85,
    pinned: "left",
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["EDIT", "DELETE"] },
  },
];

export const AgentsReceiptCols = [
  { field: "#", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "", headerName: "AGENT_DELIVERY_RECEIPT_CODE", minWidth: 120 },
  { field: "", headerName: "DATE", minWidth: 120 },
  { field: "", headerName: "BRANCH", minWidth: 120 },
  { field: "", headerName: "TOTAL_AMOUNT_AR", minWidth: 120 },
  { field: "", headerName: "AGENT", minWidth: 120 },
  { field: "", headerName: "TRANSPORTER", minWidth: 120 },
  { field: "", headerName: "DESCRIPTION", minWidth: 120 },
  {
    field: "action",
    headerName: "",
    minWidth: 85,
    pinned: "left",
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: {
      dropdownItems: ["VIEW", "EDIT", "HISTORY", "DELETE"],
    },
  },
];

export const CashDeliveryWorkListCols = [
  { field: "#", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "branch", headerName: "BRANCH", minWidth: 120 },
  {
    field: "deliveryTypeTitle",
    headerName: "DELIVERY_TYPE",
    minWidth: 120,
    translate: true,
  },
  {
    field: "deliveryMethodTitle",
    headerName: "DELIVERY_WAY",
    minWidth: 120,
    translate: true,
  },
  { field: "agent", headerName: "AGENT", minWidth: 120 },
  { field: "familyCode", headerName: "FAMILY_CODE", minWidth: 120 },
  { field: "supervisor", headerName: "SUPERVISOR", minWidth: 120 },
  { field: "supervisorPhone", headerName: "SUPERVISOR_PHONE", minWidth: 120 },
  { field: "geoName", headerName: "AREA", minWidth: 120 },
  { field: "address", headerName: "ADDRESS", minWidth: 200 },
  // { field: "phone", headerName: "PHONE", minWidth: 120 },
  {
    field: "action",
    headerName: "",
    minWidth: 85,
    pinned: "left",
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: (params) => {
      let dropdownItems = ["VIEW_RESIDENT_LOCATION"];
      if (params.data.deliveryMethod) {
        dropdownItems.push("DELIVER");
      }
      if (params.data.deliveryType === DeliveryTypes.DELIVERY_NOT_IN_PERSON) {
        dropdownItems.push("DELIVERY_WAY");
        dropdownItems.push("PRINT");
      }
      dropdownItems.push("RETURN");
      return { dropdownItems };
      // dropdownItems: ["RETURN", "DELIVER", "DELIVERY_TYPE"],
    },
  },
];

export const CashDeliveryWorkPrintAllCols = [
  { field: "#", headerName: "#", pinned: "right", minWidth: 60 },
  { field: "agent", headerName: "AGENT", minWidth: 150 },
  { field: "deliveryTypeTitle", headerName: "DELIVERY_TYPE", minWidth: 120 },
  { field: "deliveryMethodTitle", headerName: "DELIVERY_WAY", minWidth: 120 },
  { field: "familyCode", headerName: "FAMILY_CODE", minWidth: 120 },
  { field: "supervisor", headerName: "SUPERVISOR", minWidth: 150 },
  {
    field: "",
    headerName: "ADDRESS",
    minWidth: 200,
    cellRenderer: (params) =>
      (params.data.geoName || "") +
      (params.data.address && params.data.geoName ? " - " : "") +
      (params.data.address || ""),
  },
  { field: "supervisorPhone", headerName: "SUPERVISOR_PHONE", minWidth: 120 },
  {
    field: "aidDetails",
    headerName: "AID_DETAILS",
    type: "long",
    minWidth: 250,
  },
  { field: "", headerName: "DELIVERY_DATE", minWidth: 150 },
  { field: "finger", headerName: "FINGER_PRINT", minWidth: 150 },
];

export const CashDeliveryWorkPrintCols = [
  { field: "#", headerName: "#", pinned: "right", minWidth: 60 },
  { field: "familyCode", headerName: "FAMILY_CODE", minWidth: 120 },
  { field: "supervisor", headerName: "SUPERVISOR", minWidth: 150 },
  {
    field: "",
    headerName: "ADDRESS",
    minWidth: 200,
    cellRenderer: (params) =>
      (params.data.geoName || "") +
      (params.data.address && params.data.geoName ? " - " : "") +
      (params.data.address || ""),
  },
  { field: "supervisorPhone", headerName: "SUPERVISOR_PHONE", minWidth: 120 },
  {
    field: "aidDetails",
    headerName: "AID_DETAILS",
    type: "long",
    minWidth: 250,
  },
  { field: "", headerName: "DELIVERY_DATE", minWidth: 150 },
  { field: "finger", headerName: "FINGER_PRINT", minWidth: 150 },
];

export const CashDeliveryReportCols = [
  { field: "#", headerName: "#", pinned: "right", minWidth: 60 },
  { field: "code", headerName: "CODE", minWidth: 120 },
  { field: "branchTitle", headerName: "BRANCH", minWidth: 150 },
  {
    field: "",
    headerName: "REPORT_DATE",
    minWidth: 120,
    cellRenderer: (params) =>
      datePipe.transform(params.data.reportDate, "YYYY-MM-dd"),
  },
  { field: "deliveryType", headerName: "DELIVERY_TYPE", minWidth: 120 },
  { field: "deliveryMethod", headerName: "DELIVERY_WAY", minWidth: 120 },
  { field: "reason", headerName: "RETURN_REASON", minWidth: 150 },
  { field: "agentFullName", headerName: "AGENT", minWidth: 150 },
  { field: "familyCode", headerName: "FAMILY_CODE", minWidth: 120 },
  { field: "supervisor", headerName: "SUPERVISOR_NAME", minWidth: 150 },
  { field: "supervisorPhone", headerName: "SUPERVISOR_PHONE", minWidth: 120 },
  {
    field: "accountingDocumentNumber",
    headerName: "ACCOUNTING_DOCUMENT_NUMBER",
    minWidth: 100,
    type: "link",
    cellRenderer: LinkRenderer,
    cellRendererParams: (params: any) => {
      return {
        url:
          accountingSiteUrl + "/#/app/newdoc2/" +
          params.data.accountingDocumentNumber,
      };
    },
  },
  {
    field: "",
    headerName: "STATUS",
    type: "status",
    pinned: "left",
    minWidth: 190,
    cellRenderer: StatusRenderer,
    cellRendererParams: (params) => {
      params.data.statusName = CashDeliveryStatuses[params.data.lastStatus];
      switch (params.data.lastStatus) {
        case 1:
          params.data.theme = "yellow";
          break;
        case 2:
          params.data.theme = "red";
          break;
        case 3:
          params.data.theme = "green";
          break;
        default:
          break;
      }
      return params;
    },
  },
  {
    field: "action",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: (params) => {
      let dropdownItems = [];
      switch (params.data.lastStatus) {
        case 1:
          dropdownItems = ["VIEW"];
          break;
        case 2:
          dropdownItems = ["VIEW", "CHECK", "DELETE"];
          break;
        case 3:
          dropdownItems = ["VIEW", "DELETE"];
          break;
        default:
          break;
      }
      return { dropdownItems };
    },
  },
];

export const CashDeliveryReturnCols = [
  { field: "#", headerName: "#", pinned: "right", minWidth: 80 },
  { field: "aidRequestCode", headerName: "REQUEST_CODE", minWidth: 150 },
  { field: "festival", headerName: "PROGRAM", minWidth: 150 },
  // { field: "project", headerName: "PROJECT", minWidth: 150 },
  { field: "familyMemberCode", headerName: "PERSON_CODE", minWidth: 120 },
  { field: "beneficiaryName", headerName: "BENEFICIARY_NAME", minWidth: 150 },
  { field: "donationTree", headerName: "CASH_AID_ACCOUNT_TREE", minWidth: 120 },
  { field: "cashTree", headerName: "CASH_PRODUCT", minWidth: 120 },
  {
    field: "approvedAmount",
    headerName: "AMOUNT_AR",
    minWidth: 100,
    cellRenderer: (params) => pricePipe.transform(params.data.approvedAmount),
  },
];

export const GoodsDeliveryReturnCols = [
  { field: "#", headerName: "#", pinned: "right", minWidth: 80 },
  { field: "aidRequestCode", headerName: "REQUEST_CODE", minWidth: 150 },
  { field: "festival", headerName: "PROGRAM", minWidth: 150 },
  // { field: 'project', headerName: 'PROJECT', minWidth: 150 },
  { field: "familyMemberCode", headerName: "PERSON_CODE", minWidth: 120 },
  { field: "beneficiaryName", headerName: "BENEFICIARY_NAME", minWidth: 150 },
  {
    field: "donationTree",
    headerName: "OBJECTIVE_AID_ACCOUNT_TREE",
    minWidth: 120,
  },
  { field: "product", headerName: "OBJECTIVE_PRODUCT_AID", minWidth: 140 },
  { field: "serialNumber", headerName: "GOOD_SERIAL_CODE", minWidth: 140 },
  { field: "count", headerName: "QUANTITY", minWidth: 140 },
  { field: "unit", headerName: "UNIT", minWidth: 100 },
];

export const reviewDeliveryCols = [
  { field: "#", headerName: "#", pinned: "right", minWidth: 80 },
  { field: "aidRequestCode", headerName: "REQUEST_CODE", minWidth: 120 },
  { field: "familyCode", headerName: "FAMILY_CODE", minWidth: 120 },
  { field: "supervisor", headerName: "SUPERVISOR_NAME", minWidth: 150 },
  { field: "beneficiaryName", headerName: "BENEFICIARY_NAME", minWidth: 150 },
  {
    field: "approvedAmount",
    headerName: "AMOUNT_AR",
    minWidth: 100,
    cellRenderer: (params) => pricePipe.transform(params.data.approvedAmount),
  },
];

export const ConfirmGoodsNeedsOfFamilyCols = [
  { field: "#", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "familyCode", headerName: "FAMILY_CODE", minWidth: 120 },
  { field: "supervisor", headerName: "SUPERVISOR_NAME", minWidth: 150 },
  { field: "beneficiaryName", headerName: "BENEFICIARY_NAME", minWidth: 150 },
  { field: "beneficiaryType", headerName: "BENEFICIARY_TYPE", minWidth: 150 },
  {
    field: "donationTree",
    headerName: "OBJECTIVE_AID_ACCOUNT_TREE",
    minWidth: 200,
  },
  { field: "product", headerName: "OBJECTIVE_PRODUCT_AID", minWidth: 180 },
  { field: "count", headerName: "REQUESTED_COUNT", minWidth: 100 },
  { field: "unit", headerName: "UNIT", minWidth: 100 },
  { field: "approvedCount", headerName: "PROVIDED_COUNT", minWidth: 100 },
  { field: "description", headerName: "DESCRIPTION", minWidth: 200 },
  {
    field: "status",
    headerName: "STATUS",
    minWidth: 150,
    pinned: "left",
    type: "template",
    style: { left: "80px" },
    cellRenderer: (params) => {
      params.data.statusName =
        ObjectiveAidStatuses[params.data.objectiveAidStatus];
      switch (params.data.objectiveAidStatus) {
        case 0:
          params.data.theme = "yellow";
          break;
        case 1:
          params.data.theme = "light-green";
          break;
        case 2:
          params.data.theme = "green";
          break;
        case 3:
          params.data.theme = "red";
          break;
        default:
          break;
      }
      return `<div class="status status-${
        params.data.theme
      }"> ${translatePipe.transform(params.data.statusName)} </div>`;
    },
  },
  {
    field: "action",
    headerName: "",
    minWidth: 85,
    pinned: "left",
    type: "dropdown",
    cellStyle: { overflow: "unset" },
    cellRendererParams: (params) => {
      let dropdownItems = [];
      return params.data.objectiveAidStatus !== 3
        ? (dropdownItems = ["SUPPLY", "CANCEL"])
        : (dropdownItems = ["SUPPLY"]);
    },
  },
];

export const ConfirmGoodsNeedsOfFamily2Cols = [
  { field: "", headerName: "#", pinned: "right", minWidth: 60 },
  { field: "familyCode", headerName: "FAMILY_CODE", minWidth: 160 },
  { field: "supervisor", headerName: "SUPERVISOR_NAME", minWidth: 150 },
  { field: "beneficiaryName", headerName: "BENEFICIARY_NAME", minWidth: 150 },
  { field: "beneficiaryType", headerName: "BENEFICIARY_TYPE", minWidth: 150 },
  {
    field: "donationTree",
    headerName: "CASH_AID_NAME",
    minWidth: 200,
  },
  {
    field: "",
    headerName: "AMOUNT_AR",
    minWidth: 100,
    cellRenderer: (params) => pricePipe.transform(params.data.amount),
  },
  { field: "accountTree", headerName: "LOCATION_COST", minWidth: 150 },
  {
    field: "confirm",
    headerName: "CONFIRM",
    minWidth: 150,
    cellRenderer: DropdownRenderer,
    cellRendererParams: (params) => {
      params.data.dropdownItems = [
        { id: 0, name: "AWAITING_CONFIRM" },
        { id: 2, name: "CONFIRM" },
        { id: 3, name: "REJECT" },
      ];
      params.data.selectedItem = params.data.cashAidStatus;
      return params;
    },
  },
  {
    field: "credit",
    headerName: "INVENTORY",
    minWidth: 120,
    cellStyle: { direction: "ltr" },
    cellClass: (params) =>
      params.data.cashAidStatus === 2 ? "d-block" : "d-none",
  },
  {
    field: "approvedAmount",
    headerName: "CONFIRMED_AMOUNT",
    minWidth: 120,
    cellClass: (params) =>
      params.data.selectedItem === 2 ? "input-grid" : "input-grid disabled",
    editable: (params) => params.data.selectedItem === 2,
    valueParser: (params) => +params.newValue,
  },
  {
    field: "remaining",
    headerName: "REMAINING",
    minWidth: 120,
    cellStyle: { direction: "ltr" },
    cellClass: (params) =>
      params.data.cashAidStatus === 2 ? "d-block" : "d-none",
    cellRenderer: (params) =>
      params.data.credit - params.data.approvedAmount || "",
  },
  { field: "description", headerName: "DESCRIPTION", minWidth: 200 },
];

export const AuditAidCols = [
  { field: "", headerName: "#", pinned: "right", minWidth: 60 },
  { field: "code", headerName: "FAMILY_CODE", minWidth: 150 },
  { field: "supervisor", headerName: "SUPERVISOR_NAME", minWidth: 150 },
  { field: "beneficiaryName", headerName: "BENEFICIARY_NAME", minWidth: 150 },
  { field: "beneficiaryType", headerName: "BENEFICIARY_TYPE", minWidth: 120 },
  { field: "donationTree", headerName: "CASH_AID_ACCOUNT_TREE", minWidth: 200 },
  {
    field: "",
    headerName: "AMOUNT_AR",
    minWidth: 100,
    cellRenderer: (params) => pricePipe.transform(params.data.amount),
  },
  { field: "description", headerName: "DESCRIPTION", minWidth: 200 },
  {
    field: "accountTree",
    headerName: "CASH_BALANCE",
    minWidth: 300,
    cellRenderer: DropdownTreeRenderer,
    pinned: "left",
  },
];

export const SelectLocationCols = [
  {
    field: "checkbox",
    headerName: "SELECT",
    pinned: "right",
    minWidth: 85,
    cellRenderer: CheckboxRenderer,
  },
  { field: "#", headerName: "#", pinned: "right", minWidth: 60 },
  { field: "cashAssetTree", headerName: "CASH_ASSET_TREE", minWidth: 150 },
  { field: "beneficiary", headerName: "BENEFICIARY", minWidth: 150 },
  { field: "inventory", headerName: "INVENTORY", minWidth: 100 },
  {
    field: "",
    headerName: "WITHDRAW_AMOUNT",
    minWidth: 140,
    cellRenderer: (params) => pricePipe.transform(params.data.withdrawAmount),
  },
];

export const FamilyFinancialNeedsRequestCols = [
  { field: "", headerName: "#", pinned: "right", minWidth: 60 },
  {
    field: "check",
    headerName: "",
    pinned: "right",
    minWidth: 60,
    cellRenderer: CheckboxRenderer,
    cellRendererParams: (params) =>
      params.data.cashAidStatus === 0
        ? { disabled: false }
        : { disabled: true },
  },
  {
    field: "familyCode",
    headerName: "FAMILY_CODE",
    minWidth: 160,
    type: "link",
    cellRenderer: LinkRenderer,
    cellRendererParams: (params) => {
      return {
        url: siteUrl + "/family/family-information/" + params.data.familyIdentifier,
      };
    },
  },
  {
    field: "supervisor",
    headerName: "SUPERVISOR_NAME",
    minWidth: 150,
    type: "link",
    cellRenderer: LinkRenderer,
    cellRendererParams: (params) => {
      return {
        url:
          siteUrl +
          "/family/family-member-edit/" +
          params.data.supervisorMemberIdentifier
      };
    },
  },
  {
    field: "familyMemberCode",
    headerName: "BENEFICIARY_CODE",
    minWidth: 100,
    type: "link",
    cellRenderer: LinkRenderer,
    cellRendererParams: (params) => {
      return {
        url:
          siteUrl +
          "/family/family-member-edit/" +
          params.data.familyMemberIdentifier
      };
    },
  },
  {
    field: "beneficiaryName",
    headerName: "BENEFICIARY_NAME",
    minWidth: 200,
    type: "link",
    cellRenderer: LinkRenderer,
    cellRendererParams: (params) => {
      return {
        url:
          siteUrl +
          "/family/family-member-edit/" +
          params.data.familyMemberIdentifier
      };
    },
  },
  {
    field: "beneficiaryType",
    headerName: "BENEFICIARY_TYPE",
    minWidth: 150,
  },
  { field: "donationTree", headerName: "CASH_AID_ACCOUNT_TREE", minWidth: 200 },
  { field: "cashTree", headerName: "CASH_PRODUCT", minWidth: 200 },
  {
    field: "",
    headerName: "AMOUNT_AR",
    minWidth: 100,
    cellRenderer: (params) => pricePipe.transform(params.data.amount),
  },
  {
    field: "approvedAmount",
    headerName: "CONFIRMED_AMOUNT",
    minWidth: 100,
    cellRenderer: (params) => pricePipe.transform(params.data.approvedAmount),
  },
  {
    field: "status", // PRINT ==> set field to 'status'.
    headerName: "STATUS",
    pinned: "left",
    minWidth: 190,
    cellRenderer: StatusRenderer,
    cellRendererParams: (params) => {
      params.data.statusName = CashAidStatus[params.data.cashAidStatus];
      switch (params.data.cashAidStatus) {
        case 0:
          params.data.statusTheme = "yellow"; // PRINT ==> change params.data.status to params.data.statusTheme.
          break;
        case 1:
          params.data.statusTheme = "green-600";
          break;
        case 2:
          params.data.statusTheme = "green";
          break;
        case 3:
          params.data.statusTheme = "red-600";
          break;
        case 4:
          params.data.statusTheme = "yellow-pink";
          break;
        case 6:
          params.data.statusTheme = "yellow-2";
          break;
        case 7:
          params.data.statusTheme = "pink";
          break;
        case 8:
          params.data.statusTheme = "orange";
          break;
        case 9:
          params.data.statusTheme = "primary";
          break;
        case 10:
          params.data.statusTheme = "red";
          break;
        default:
          break;
      }
      return params; // PRINT ==> set return.
    },
  },
  { field: "description", headerName: "DESCRIPTION", minWidth: 200 },
  {
    field: "action",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: (params) =>
      params.data.cashAidStatus === 0
        ? { dropdownItems: ["EDIT", "DELETE"] }
        : { dropdownItems: ["EDIT"] },
  },
];

export const FamilyFinancialNeedsRequestDetailCols = [
  {
    field: "",
    headerName: "#",
    pinned: "right",
    minWidth: 60,
    suppressSizeToFit: true,
  },
  { field: "donationTree", headerName: "CASH_AID_ACCOUNT_TREE", minWidth: 200 },
  {
    field: "",
    headerName: "TOTAL_AMOUNT",
    minWidth: 100,
    cellRenderer: (params: any) => pricePipe.transform(params.data.totalAmount),
  },
];

export const FamilyFinancialNeedsRequestInModal1Cols = [
  { field: "", headerName: "#", pinned: "right", minWidth: 60 },
  { field: "family", headerName: "FAMILY", minWidth: 150 },
  { field: "familyMember", headerName: "FAMILY_MEMBER", minWidth: 150 },
  { field: "donationTree", headerName: "CASH_AID_ACCOUNT_TREE", minWidth: 150 },
  { field: "cashTree", headerName: "CASH_PRODUCT", minWidth: 150 },
  {
    field: "",
    headerName: "AMOUNT",
    minWidth: 120,
    cellRenderer: (params: any) => pricePipe.transform(params.data.amount),
  },
  { field: "description", headerName: "DESCRIPTION", minWidth: 200 },
];

export const FamilyFinancialNeedsRequestInModal2Cols = [
  { field: "", headerName: "#", pinned: "right", minWidth: 60 },
  {
    field: "conditionGroup",
    headerName: "FAMILY_PERSON_GROUP_NAME",
    minWidth: 150,
  },
  { field: "donationTree", headerName: "CASH_AID_ACCOUNT_TREE", minWidth: 150 },
  { field: "cashTree", headerName: "CASH_PRODUCT", minWidth: 150 },
  {
    field: "",
    headerName: "AMOUNT",
    minWidth: 120,
    cellRenderer: (params: any) => pricePipe.transform(params.data.amount),
  },
  { field: "description", headerName: "DESCRIPTION", minWidth: 200 },
];

export const FamilyFinancialNeedsRequestInPrintCols = [
  { field: "", headerName: "#", pinned: "right", minWidth: 60 },
  {
    field: "check",
    headerName: "",
    pinned: "right",
    minWidth: 60,
    cellRenderer: CheckboxRenderer,
    cellRendererParams: (params) =>
      params.data.cashAidStatus === 0
        ? { disabled: false }
        : { disabled: true },
  },
  {
    field: "familyCode",
    headerName: "FAMILY_CODE",
    minWidth: 160
  },
  {
    field: "supervisor",
    headerName: "SUPERVISOR_NAME",
    minWidth: 150
  },
  {
    field: "familyMemberCode",
    headerName: "BENEFICIARY_CODE",
    minWidth: 100
  },
  {
    field: "beneficiaryName",
    headerName: "BENEFICIARY_NAME",
    minWidth: 200
  },
  {
    field: "beneficiaryType",
    headerName: "BENEFICIARY_TYPE",
    minWidth: 150,
  },
  { field: "donationTree", headerName: "CASH_AID_ACCOUNT_TREE", minWidth: 200 },
  {
    field: "",
    headerName: "AMOUNT_AR",
    minWidth: 100,
    cellRenderer: (params) => pricePipe.transform(params.data.amount),
  },
  {
    field: "approvedAmount",
    headerName: "CONFIRMED_AMOUNT",
    minWidth: 100,
    cellRenderer: (params) => pricePipe.transform(params.data.approvedAmount),
  },
  {
    field: "status", // PRINT ==> set field to 'status'.
    headerName: "STATUS",
    pinned: "left",
    minWidth: 190,
    cellRenderer: StatusRenderer,
    cellRendererParams: (params) => {
      params.data.statusName = CashAidStatus[params.data.cashAidStatus];
      switch (params.data.cashAidStatus) {
        case 0:
          params.data.statusTheme = "yellow"; // PRINT ==> change params.data.status to params.data.statusTheme.
          break;
        case 1:
          params.data.statusTheme = "green-600";
          break;
        case 2:
          params.data.statusTheme = "green";
          break;
        case 3:
          params.data.statusTheme = "red-600";
          break;
        case 4:
          params.data.statusTheme = "yellow-pink";
          break;
        case 6:
          params.data.statusTheme = "yellow-2";
          break;
        case 7:
          params.data.statusTheme = "pink";
          break;
        case 8:
          params.data.statusTheme = "orange";
          break;
        case 9:
          params.data.statusTheme = "primary";
          break;
        case 10:
          params.data.statusTheme = "red";
          break;
        default:
          break;
      }
      return params; // PRINT ==> set return.
    },
  },
  { field: "description", headerName: "DESCRIPTION", minWidth: 200 },
  {
    field: "action",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: (params) =>
      params.data.cashAidStatus === 0
        ? { dropdownItems: ["EDIT", "DELETE"] }
        : { dropdownItems: ["EDIT"] },
  },
];

export const FamilyNeedsRequestListCols = [
  { field: "", headerName: "#", pinned: "right", minWidth: 60 },
  { field: "branchTitle", headerName: "BRANCH", minWidth: 180 },
  {
    field: "aidRequestCode",
    headerName: "REQUEST_CODE",
    minWidth: 160,
    sortable: true,
  },
  { field: "festival", headerName: "PROGRAM", minWidth: 180, sortable: true },
  // { field: "project", headerName: "PROJECT", minWidth: 180, sortable: true },
  { field: "creatorName", headerName: "REGISTRAR", minWidth: 180 },
  {
    field: "requestDate",
    headerName: "REQUEST_DATE",
    minWidth: 120,
    sortable: true,
    cellRenderer: (params) =>
      datePipe.transform(params.data.requestDate, "YYYY-MM-dd"),
  },
  { field: "auditorName", headerName: "AUDITOR", minWidth: 180 },
  {
    field: "",
    headerName: "AUDIT_DATE",
    minWidth: 120,
    cellRenderer: (params) =>
      datePipe.transform(params.data.auditDate, "YYYY-MM-dd"),
  },
  { field: "reviewerName", headerName: "REVIEWER", minWidth: 180 },
  {
    field: "",
    headerName: "REVIEW_DATE",
    minWidth: 120,
    cellRenderer: (params) =>
      datePipe.transform(params.data.reviewDate, "YYYY-MM-dd"),
  },
  { field: "description", headerName: "DESCRIPTION", minWidth: 200 },
  {
    field: "",
    headerName: "STATUS",
    pinned: "left",
    minWidth: 190,
    cellRenderer: StatusRenderer,
    cellRendererParams: (params) => {
      params.data.statusName = AidRequestStatuses[params.data.lastStatus];
      switch (params.data.lastStatus) {
        case 0:
          params.data.theme = "yellow";
          break;
        case 1:
          params.data.theme = "brown";
          break;
        case 2:
          params.data.theme = "orange";
          break;
        case 3:
          params.data.theme = "blue";
          break;
        case 4:
          params.data.theme = "green";
          break;
        case 5:
          params.data.theme = "red";
          break;
        default:
          break;
      }
    },
  },
  {
    field: "",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: (params) => {
      let items = [];
      switch (params.data.lastStatus) {
        case 0:
          items = ["EDIT", "AUDIT", "DELETE"];
          break;
        case 1:
          items = ["AUDIT"];
          break;
        case 2:
        case 3:
          items = ["MANAGER_REVIEW"];
          break;
        case 4:
          items = ["REQUEST_PAY_FUND"];
          break;
        default:
          break;
      }
      return { dropdownItems: ["VIEW", ...items] };
    },
  },
];

export const FamilyGoodsNeedsRequestListCols = [
  { field: "", headerName: "#", pinned: "right", minWidth: 60 },
  { field: "branchTitle", headerName: "BRANCH", minWidth: 150 },
  { field: "aidRequestCode", headerName: "REQUEST_CODE", minWidth: 150 },
  { field: "festival", headerName: "PROGRAM", minWidth: 150 },
  { field: "creatorName", headerName: "REGISTRAR", minWidth: 150 },
  {
    field: "",
    headerName: "REQUEST_DATE",
    minWidth: 120,
    cellRenderer: (params) =>
      datePipe.transform(params.data.requestDate, "YYYY-MM-dd"),
  },
  { field: "reviewerName", headerName: "REVIEWER", minWidth: 150 },
  {
    field: "",
    headerName: "REVIEW_DATE",
    minWidth: 120,
    cellRenderer: (params) =>
      datePipe.transform(params.data.reviewDate, "YYYY-MM-dd"),
  },
  { field: "description", headerName: "DESCRIPTION", minWidth: 200 },
  {
    field: "",
    headerName: "STATUS",
    pinned: "left",
    minWidth: 190,
    cellRenderer: StatusRenderer,
    cellRendererParams: (params) => {
      params.data.statusName = AidRequestStatuses[params.data.lastStatus];
      switch (params.data.lastStatus) {
        case 0:
          params.data.theme = "yellow";
          break;
        case 3:
          params.data.theme = "blue";
          break;
        case 4:
          params.data.theme = "green";
          break;
        case 5:
          params.data.theme = "red";
          break;
        default:
          break;
      }
    },
  },
  {
    field: "",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: (params) => {
      let dropdownItems = [];
      switch (params.data.lastStatus) {
        case 0:
          dropdownItems = ["VIEW", "EDIT", "MANAGER_REVIEW", "DELETE"];
          break;
        case 3:
          dropdownItems = ["VIEW", "MANAGER_REVIEW"];
          break;
        case 4:
          dropdownItems = ["VIEW", "EXIT_WAREHOUSE_REQUEST_SUBMIT"];
          break;
        case 5:
          dropdownItems = ["VIEW"];
          break;
        default:
          break;
      }
      return { dropdownItems: dropdownItems };
    },
  },
];

export const FamilyGoodsNeedsRequestCols = [
  { field: "", headerName: "#", pinned: "right", minWidth: 60 },
  {
    field: "check",
    headerName: "",
    pinned: "right",
    minWidth: 60,
    cellRenderer: CheckboxRenderer,
    cellRendererParams: (params) =>
      params.data.objectiveAidStatus === 1
        ? { disabled: false }
        : { disabled: true },
  },
  {
    field: "familyCode",
    headerName: "FAMILY_CODE",
    minWidth: 160,
    type: "link",
    cellRenderer: LinkRenderer,
    cellRendererParams: (params) => {
      return {
        url: siteUrl + "/family/family-information/" + params.data.familyIdentifier,
      };
    },
  },
  {
    field: "supervisor",
    headerName: "SUPERVISOR_NAME",
    minWidth: 150,
    type: "link",
    cellRenderer: LinkRenderer,
    cellRendererParams: (params) => {
      return {
        url:
          siteUrl +
          "/family/family-member-edit/" +
          params.data.supervisorMemberIdentifier
      };
    },
  },
  {
    field: "familyMemberCode",
    headerName: "BENEFICIARY_CODE",
    minWidth: 100,
    type: "link",
    cellRenderer: LinkRenderer,
    cellRendererParams: (params) => {
      return {
        url:
          siteUrl +
          "/family/family-member-edit/" +
          params.data.familyMemberIdentifier
      };
    },
  },
  {
    field: "beneficiaryName",
    headerName: "BENEFICIARY_NAME",
    minWidth: 200,
    type: "link",
    cellRenderer: LinkRenderer,
    cellRendererParams: (params) => {
      return {
        url:
          siteUrl +
          "/family/family-member-edit/" +
          params.data.familyMemberIdentifier
      };
    },
  },
  { field: "beneficiaryType", headerName: "BENEFICIARY_TYPE", minWidth: 150 },
  {
    field: "donationTree",
    headerName: "OBJECTIVE_AID_ACCOUNT_TREE",
    minWidth: 200,
  },
  { field: "product", headerName: "OBJECTIVE_PRODUCT_AID", minWidth: 180 },
  { field: "count", headerName: "QUANTITY", minWidth: 100 },
  { field: "unit", headerName: "UNIT", minWidth: 100 },
  { field: "description", headerName: "DESCRIPTION", minWidth: 200 },
  {
    field: "action",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: (params) =>
      params.data.objectiveAidStatus === 1
        ? { dropdownItems: ["EDIT", "DELETE"] }
        : { dropdownItems: ["EDIT"] },
  },
];

export const FamilyGoodsNeedsRequestInModal1Cols = [
  { field: "", headerName: "#", pinned: "right", minWidth: 60 },
  { field: "family", headerName: "FAMILY", minWidth: 150 },
  { field: "familyMember", headerName: "FAMILY_MEMBER", minWidth: 150 },
  {
    field: "donationTree",
    headerName: "OBJECTIVE_AID_ACCOUNT_TREE",
    minWidth: 150,
  },
  { field: "good", headerName: "OBJECTIVE_PRODUCT_AID", minWidth: 150 },
  { field: "count", headerName: "QUANTITY", minWidth: 120 },
  { field: "description", headerName: "DESCRIPTION", minWidth: 200 },
];

export const FamilyGoodsNeedsRequestInModal2Cols = [
  { field: "", headerName: "#", pinned: "right", minWidth: 60 },
  {
    field: "conditionGroup",
    headerName: "FAMILY_PERSON_GROUP_NAME",
    minWidth: 150,
  },
  {
    field: "donationTree",
    headerName: "OBJECTIVE_AID_ACCOUNT_TREE",
    minWidth: 150,
  },
  { field: "good", headerName: "OBJECTIVE_PRODUCT_AID", minWidth: 150 },
  { field: "count", headerName: "QUANTITY", minWidth: 120 },
  { field: "description", headerName: "DESCRIPTION", minWidth: 200 },
];

export const FamilyGoodsNeedsRequestInPrintCols = [
  { field: "", headerName: "#", pinned: "right", minWidth: 60 },
  {
    field: "check",
    headerName: "",
    pinned: "right",
    minWidth: 60,
    cellRenderer: CheckboxRenderer,
    cellRendererParams: (params) =>
      params.data.objectiveAidStatus === 1
        ? { disabled: false }
        : { disabled: true },
  },
  {
    field: "familyCode",
    headerName: "FAMILY_CODE",
    minWidth: 160
  },
  {
    field: "supervisor",
    headerName: "SUPERVISOR_NAME",
    minWidth: 150
  },
  {
    field: "familyMemberCode",
    headerName: "BENEFICIARY_CODE",
    minWidth: 100
  },
  {
    field: "beneficiaryName",
    headerName: "BENEFICIARY_NAME",
    minWidth: 200
  },
  { field: "beneficiaryType", headerName: "BENEFICIARY_TYPE", minWidth: 150 },
  {
    field: "donationTree",
    headerName: "OBJECTIVE_AID_ACCOUNT_TREE",
    minWidth: 200,
  },
  { field: "product", headerName: "OBJECTIVE_PRODUCT_AID", minWidth: 180 },
  { field: "", headerName: "QUANTITY", minWidth: 100, cellRenderer: (params: any) => (params.data.count || '') + ' ' + (params.data.unit || '') },
  { field: "description", headerName: "DESCRIPTION", minWidth: 200 },
  {
    field: "action",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: (params) =>
      params.data.objectiveAidStatus === 1
        ? { dropdownItems: ["EDIT", "DELETE"] }
        : { dropdownItems: ["EDIT"] },
  },
];

export const ServiceRequestSubmitCols = [
  { field: "id", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "code", headerName: "FAMILY_CODE", minWidth: 150 },
  { field: "motherName", headerName: "MOTHER_NAME", minWidth: 150 },
  { field: "", headerName: "DEAD_NAME", minWidth: 150 },
  { field: "", headerName: "BENEFICIARY_NAME", minWidth: 170 },
  { field: "", headerName: "BENEFICIARY_TYPE", minWidth: 170 },
  { field: "", headerName: "AID_TYPE", minWidth: 140 },
  { field: "", headerName: "SERVICE", minWidth: 140 },
  { field: "description", headerName: "DESCRIPTIONS", minWidth: 160 },
  {
    field: "action",
    headerName: "",
    minWidth: 85,
    pinned: "left",
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["EDIT", "DELETE"] },
  },
];

export const ExitGoodRequestCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "aidRequestCode", headerName: "REQUEST_CODE", minWidth: 150 },
  { field: "familyCode", headerName: "FAMILY_CODE", minWidth: 150 },
  { field: "supervisor", headerName: "SUPERVISOR_NAME", minWidth: 150 },
  { field: "beneficiaryName", headerName: "BENEFICIARY_NAME", minWidth: 170 },
  { field: "beneficiaryType", headerName: "BENEFICIARY_TYPE", minWidth: 170 },
  {
    field: "donationTree",
    headerName: "OBJECTIVE_AID_ACCOUNT_TREE",
    minWidth: 140,
  },
  { field: "product", headerName: "OBJECTIVE_PRODUCT_AID", minWidth: 140 },
  { field: "serialNumber", headerName: "GOOD_SERIAL_CODE", minWidth: 140 },
  { field: "count", headerName: "QUANTITY", minWidth: 140 },
  {
    field: "",
    headerName: "UNIT",
    minWidth: 140,
    cellRenderer: (params) => params.data.unit || params.data.unitTitle,
  },
];

export const ExitGoodRequest2Cols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "familyCode", headerName: "FAMILY_CODE", minWidth: 150 },
  {
    field: "",
    headerName: "DELIVERY_TYPE",
    minWidth: 150,
    cellRenderer: (params) =>
      translatePipe.transform(DeliveryTypes[params.data.deliveryType]),
  },
  { field: "supervisor", headerName: "SUPERVISOR_NAME", minWidth: 150 },
  { field: "beneficiaryName", headerName: "BENEFICIARY_NAME", minWidth: 170 },
  { field: "beneficiaryType", headerName: "BENEFICIARY_TYPE", minWidth: 170 },
  {
    field: "donationTree",
    headerName: "OBJECTIVE_AID_ACCOUNT_TREE",
    minWidth: 140,
  },
  { field: "product", headerName: "OBJECTIVE_PRODUCT_AID", minWidth: 140 },
  { field: "serialNumber", headerName: "GOOD_SERIAL_CODE", minWidth: 140 },
  { field: "count", headerName: "QUANTITY", minWidth: 140 },
  {
    field: "",
    headerName: "UNIT",
    minWidth: 140,
    cellRenderer: (params) => params.data.unit || params.data.unitTitle,
  },
];

export const ExitGoodConfirmRequestCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "code", headerName: "FAMILY_CODE", minWidth: 150 },
  { field: "", headerName: "DEAD_NAME", minWidth: 150 },
  { field: "", headerName: "WIDOW_NAME", minWidth: 150 },
  { field: "", headerName: "BENEFICIARY_NAME", minWidth: 170 },
  { field: "", headerName: "BENEFICIARY_TYPE", minWidth: 170 },
  { field: "", headerName: "OBJECTIVE_AID_ACCOUNT_TREE", minWidth: 140 },
  { field: "", headerName: "OBJECTIVE_PRODUCT_AID", minWidth: 140 },
  { field: "", headerName: "QUANTITY", minWidth: 140 },
  { field: "", headerName: "UNIT", minWidth: 140 },
  { field: "", headerName: "GOOD_SERIAL_CODE", minWidth: 140 },
  {
    field: "checkbox",
    headerName: "DELIVERY_TYPE",
    minWidth: 100,
    pinned: "right",
    cellRenderer: CheckboxRenderer,
  },
  {
    field: "checkbox",
    headerName: "SELECT",
    minWidth: 85,
    pinned: "right",
    cellRenderer: CheckboxRenderer,
  },
];

export const DeliveryGoodToFamilyCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "", headerName: "GOOD_SERIAL_CODE", minWidth: 140 },
  { field: "", headerName: "GOOD", minWidth: 140 },
  { field: "", headerName: "COUNT", minWidth: 140 },
  { field: "", headerName: "DELIVERY_COUNT", minWidth: 140 },
  { field: "", headerName: "UNIT", minWidth: 140 },
  { field: "code", headerName: "FAMILY_CODE", minWidth: 150 },
  { field: "", headerName: "BENEFICIARY_NAME", minWidth: 170 },
  { field: "", headerName: "AGENT", minWidth: 170 },
  {
    field: "checkbox",
    headerName: "DELIVERED_OR_NOT",
    minWidth: 85,
    pinned: "right",
    cellRenderer: CheckboxRenderer,
  },
];

export const ExitGoodRequestListCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "branchTitle", headerName: "BRANCH", minWidth: 180 },
  {
    field: "exitRequestFromWarehouseCode",
    headerName: "EXIT_REQUEST_CODE",
    minWidth: 180,
  },
  {
    field: "aidRequestCode",
    headerName: "GOOD_AID_REQUEST_CODE",
    minWidth: 180,
  },
  { field: "festival", headerName: "PROGRAM", minWidth: 150 },
  // { field: "project", headerName: "PROJECT", minWidth: 150 },
  {
    field: "aidRequestRequestDate",
    headerName: "AID_REQUEST_DATE",
    minWidth: 180,
    cellRenderer: (params) =>
      datePipe.transform(params.data.aidRequestRequestDate, "YYYY-MM-dd"),
  },
  {
    field: "",
    headerName: "EXIT_REQUESTER",
    minWidth: 150,
    cellRenderer: (params) => params.data.status.logs[0]?.user,
  },
  {
    field: "",
    headerName: "EXIT_REQUEST_DATE",
    minWidth: 150,
    cellRenderer: (params) =>
      datePipe.transform(params.data.requestDate, "YYYY-MM-dd"),
  },
  {
    field: "",
    headerName: "CORROBORANT",
    minWidth: 150,
    cellRenderer: (params) => params.data.status.logs[1]?.user,
  },
  {
    field: "",
    headerName: "CONFIRM_DATE",
    minWidth: 150,
    cellRenderer: (params) =>
      datePipe.transform(params.data.status?.logs[1]?.date, "YYYY-MM-dd"),
  },
  { field: "", headerName: "DESCRIPTION", minWidth: 150 },
  {
    field: "",
    headerName: "STATUS",
    pinned: "left",
    minWidth: 190,
    cellRenderer: StatusRenderer,
    cellRendererParams: (params) => {
      params.data.statusName = AidRequest2Statuses[params.data.lastStatus];
      switch (params.data.lastStatus) {
        case 1:
          params.data.theme = "yellow";
          break;
        case 2:
          params.data.theme = "green";
          break;
        default:
          break;
      }
    },
  },
  {
    field: "action",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: (params) => {
      if (params.data.lastStatus === AidRequest2Statuses.PENDING_APPROVAL) {
        return { dropdownItems: ["VIEW", "CONFIRM", "DELETE"] };
      } else {
        return { dropdownItems: ["VIEW"] };
      }
    },
  },
];

export const ExitGoodFromWarehouseCols = [
  { field: "", headerName: "#", pinned: "right", minWidth: 60 },
  { field: "aidRequestCode", headerName: "REQUEST_CODE", minWidth: 100 },
  { field: "festival", headerName: "PROGRAM", minWidth: 150 },
  {
    field: "",
    headerName: "EXIT_REQUEST_DATE",
    minWidth: 120,
    cellRenderer: (params) => {
      const date =
        params.data.exitRequestDate ??
        params.data.exitRequestFromWarehouseRequestDate;
      return datePipe.transform(date, "YYYY-MM-dd");
    },
  },
  { field: "familyCode", headerName: "FAMILY_CODE", minWidth: 100 },
  { field: "supervisor", headerName: "SUPERVISOR_NAME", minWidth: 150 },
  { field: "beneficiaryName", headerName: "BENEFICIARY_NAME", minWidth: 150 },
  {
    field: "donationTree",
    headerName: "OBJECTIVE_AID_ACCOUNT_TREE",
    minWidth: 120,
  },
  { field: "product", headerName: "OBJECTIVE_PRODUCT_AID", minWidth: 100 },
  { field: "serialNumber", headerName: "SERIAL_NUMBER", minWidth: 150 },
  {
    field: "",
    headerName: "QUANTITY",
    minWidth: 100,
    cellRenderer: (params) => params.data.count + " " + params.data.unit,
  },
  {
    field: "address",
    headerName: "ADDRESS",
    minWidth: 200,
  },
  { field: "phone", headerName: "PHONE", minWidth: 120 },
];

export const ExitGoodFromWarehouseInPrintCols = [
  { field: '', headerName: '#', pinned: 'right', minWidth: 60 },
  { field: 'beneficiaryType', headerName: 'BENEFICIARY_TYPE', minWidth: 100 },
  { field: 'beneficiaryName', headerName: 'BENEFICIARY_NAME', minWidth: 150 },
  { field: 'donationTree', headerName: 'OBJECTIVE_AID_ACCOUNT_TREE', minWidth: 120 },
  { field: 'product', headerName: 'OBJECTIVE_PRODUCT_AID', minWidth: 100 },
  { field: '', headerName: 'QUANTITY', minWidth: 100, cellRenderer: (params: any) => params.data.count + ' ' + params.data.unit }
];

export const AidExitGoodWarehouseCols = [
  { field: "#", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "branch", headerName: "BRANCH", minWidth: 170 },
  { field: "code", headerName: "EXIT_FROM_WAREHOUSE_CODE", minWidth: 130 },
  {
    field: "",
    headerName: "DATE",
    minWidth: 130,
    cellRenderer: (params) =>
      datePipe.transform(params.data.requestDate, "YYYY-MM-dd"),
  },
  { field: "deliveryAgent", headerName: "DELIVERY", minWidth: 130 },
  { field: "transferee", headerName: "TRANSPORTER", minWidth: 130 },
  {
    field: "",
    headerName: "STATUS",
    pinned: "left",
    minWidth: 190,
    cellRenderer: StatusRenderer,
    suppressSizeToFit: true,
    cellRendererParams: (params) => {
      params.data.statusName = ExitGoodOrderStatus[params.data?.status];
      switch (params.data?.status) {
        case 1:
          params.data.theme = "yellow";
          break;
        case 2:
          params.data.theme = "green";
          break;
        default:
          break;
      }
    },
  },
  {
    field: "action",
    headerName: "",
    minWidth: 85,
    pinned: "left",
    cellRenderer: DropdownButtonRenderer,
    suppressSizeToFit: true,
    cellRendererParams: (params) => {
      const temp = { dropdownItems: ["VIEW", "PRINT"] };
      if (params.data.status === 1) {
        temp.dropdownItems.push("EDIT", "DELETE");
      }
      return temp;
    },
  },
];

export const AidFinancialGoodDeliveryList = [
  { field: "#", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "branch", headerName: "BRANCH", minWidth: 100 },
  { field: "program", headerName: "PROGRAM", minWidth: 120 },
  { field: "deliveryId", headerName: "DELIVERY_ID", minWidth: 140 },
  { field: "deliveryType", headerName: "DELIVERY_TYPE", minWidth: 140 },
  { field: "agent", headerName: "AGENT", minWidth: 90 },
  { field: "aidType", headerName: "AID_TYPE", minWidth: 100 },
  { field: "", headerName: "WIDOW_NAME", minWidth: 100 },
  { field: "", headerName: "DEAD_NAME", minWidth: 100 },
  { field: "familyCode", headerName: "FAMILY_CODE", minWidth: 140 },
  { field: "address", headerName: "ADDRESS", minWidth: 100 },
  { field: "phone", headerName: "PHONE", minWidth: 100 },
  { field: "good", headerName: "GOOD", minWidth: 100 },
  { field: "deliveryDate", headerName: "DELIVERY_DATE", minWidth: 150 },
  {
    field: "action",
    headerName: "",
    minWidth: 100,
    pinned: "left",
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["VIEW", "DELETE"] },
  },
];

export const AidDeliveryWorkListCols = [
  { field: "#", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "branchId", headerName: "BRANCH_ID", minWidth: 120 },
  { field: "program", headerName: "PROGRAM", minWidth: 110 },
  { field: "deliveryType", headerName: "DELIVERY_TYPE", minWidth: 150 },
  { field: "agentId", headerName: "AGENT", minWidth: 90 },
  { field: "aidType", headerName: "AID_TYPE", minWidth: 110 },
  { field: "familyCode", headerName: "FAMILY_CODE", minWidth: 130 },
  { field: "", headerName: "WIDOW_NAME", minWidth: 120 },
  { field: "", headerName: "DEAD_NAME", minWidth: 120 },
  { field: "address", headerName: "ADDRESS", minWidth: 100 },
  { field: "phone", headerName: "PHONE", minWidth: 90 },
  { field: "good", headerName: "GOOD", minWidth: 85 },
  {
    field: "action",
    headerName: "",
    minWidth: 140,
    pinned: "left",
    cellRenderer: ButtonRenderer,
    cellRendererParams: {
      type: "fill",
      color: "blue",
      size: "sm",
      text: "تحویل عینی",
    },
  },
];

export const ReturnGoodsToWarehouseList = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  {
    field: "goodReturnReceiptId",
    headerName: "GOOD_RETURN_RECEIPT_ID",
    minWidth: 180,
  },
  {
    field: "warehouseReturnDate",
    headerName: "WAREHOUSE_RETURN_DATE",
    minWidth: 150,
  },
  { field: "warehouseExitId", headerName: "WAREHOUSE_EXIT_ID", minWidth: 150 },
  {
    field: "warehouseExitDate",
    headerName: "WAREHOUSE_EXIT_DATE",
    minWidth: 150,
  },
  { field: "branch", headerName: "BRANCH", minWidth: 150 },
  { field: "agent", headerName: "AGENT", minWidth: 150 },
  { field: "receiver", headerName: "RECEIVER", minWidth: 150 },
  { field: "description", headerName: "DESCRIPTION", minWidth: 200 },
  {
    field: "goodReturnReceiptId",
    headerName: "GOOD_RETURN_RECEIPT_ID",
    minWidth: 100,
  },
  {
    field: "warehouseReturnDate",
    headerName: "WAREHOUSE_RETURN_DATE",
    minWidth: 150,
  },
  { field: "warehouseExitId", headerName: "WAREHOUSE_EXIT_ID", minWidth: 100 },
  {
    field: "warehouseExitDate",
    headerName: "WAREHOUSE_EXIT_DATE",
    minWidth: 150,
  },
  { field: "branch", headerName: "BRANCH", minWidth: 150 },
  { field: "agent", headerName: "AGENT", minWidth: 150 },
  { field: "receiver", headerName: "RECEIVER", minWidth: 150 },
  { field: "description", headerName: "DESCRIPTION", minWidth: 200 },
  {
    field: "action",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: {
      dropdownItems: ["VIEW", "EDIT", "HISTORY", "DELETE"],
    },
  },
];

export const SaveReturnGoodToWarehouseCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "serialNumber", headerName: "SERIAL_NUMBER", minWidth: 120 },
  { field: "warehouse", headerName: "WAREHOUSE", minWidth: 150 },
  { field: "goodGroup", headerName: "GOOD_GROUP", minWidth: 150 },
  { field: "goodCode", headerName: "GOOD_CODE", minWidth: 100 },
  { field: "good", headerName: "GOOD", minWidth: 150 },
  { field: "count", headerName: "COUNT", minWidth: 100 },
  { field: "unit", headerName: "UNIT", minWidth: 100 },
  { field: "expiryDate", headerName: "EXPIRY_DATE", minWidth: 150 },
  { field: "familyCode", headerName: "FAMILY_CODE", minWidth: 100 },
  { field: "widowName", headerName: "WIDOW_NAME", minWidth: 150 },
  { field: "deadName", headerName: "DEAD_NAME", minWidth: 150 },
  { field: "beneficiaryName", headerName: "BENEFICIARY_NAME", minWidth: 150 },
  { field: "description", headerName: "DESCRIPTION", minWidth: 200 },
  { field: "return", headerName: "RETURN", minWidth: 100 },
  { field: "returnedCount", headerName: "RETURN_COUNT", minWidth: 120 },
  { field: "returnReason", headerName: "RETURN_REASON", minWidth: 120 },
];

export const CashPaymentRequestListCols = [
  { field: "", headerName: "#", pinned: "right", minWidth: 60 },
  { field: "branch", headerName: "BRANCH", minWidth: 180 },
  { field: "code", headerName: "PAYMENT_REQUEST_CODE", minWidth: 170 },
  { field: "aidRequestCode", headerName: "AID_REQUEST_CODE", minWidth: 160 },
  {
    field: "date",
    headerName: "AID_REQUEST_DATE",
    minWidth: 160,
    cellRenderer: (params) =>
      datePipe.transform(params.data.date, "YYYY-MM-dd"),
  },
  { field: "festival", headerName: "PROGRAM", minWidth: 180 },
  {
    field: "requestDate",
    headerName: "REQUEST_DATE",
    minWidth: 140,
    cellRenderer: (params) =>
      datePipe.transform(params.data.requestDate, "YYYY-MM-dd"),
  },
  { field: "seconder", headerName: "SECONDER", minWidth: 150 },
  {
    field: "confirmDate",
    headerName: "CONFIRM_DATE",
    minWidth: 140,
    cellRenderer: (params) =>
      datePipe.transform(params.data.confirmDate, "YYYY-MM-dd"),
  },
  { field: "description", headerName: "DESCRIPTION", minWidth: 200 },
  {
    field: "",
    headerName: "STATUS",
    pinned: "left",
    minWidth: 190,
    cellRenderer: StatusRenderer,
    cellRendererParams: (params) => {
      params.data.statusName = AidRequest2Statuses[params.data?.lastStatus];
      switch (params.data?.lastStatus) {
        case 1:
          params.data.theme = "yellow";
          break;
        case 2:
          params.data.theme = "green";
          break;
        default:
          break;
      }
    },
  },
  {
    field: "",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: (params) => {
      let dropdown = [];
      if (
        params.data.status.logs[params.data.status.logs.length - 1].status === 1
      ) {
        dropdown.push("EDIT", "AUDIT", "DELETE");
      } else if (
        params.data.status.logs[params.data.status.logs.length - 1].status === 2
      ) {
        dropdown.push("VIEW");
      }
      return { dropdownItems: dropdown };
    },
  },
];

export const CashPaymentRequestCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "familyCode", headerName: "FAMILY_CODE", minWidth: 180 },
  { field: "supervisor", headerName: "SUPERVISOR_NAME", minWidth: 130 },
  {
    field: "beneficiaryName",
    headerName: "BENEFICIARY_NAME",
    minWidth: 200,
  },
  { field: "beneficiaryType", headerName: "BENEFICIARY_TYPE", minWidth: 180 },
  { field: "donationTree", headerName: "CASH_AID_ACCOUNT_TREE", minWidth: 180 },
  { field: "cashTree", headerName: "CASH_PRODUCT", minWidth: 180 },
  {
    field: "",
    headerName: "CONFIRMED_AMOUNT",
    minWidth: 140,
    cellRenderer: (params) => pricePipe.transform(params.data.approvedAmount),
  },
  // {
  //   field: "deliveryTypeTitle",
  //   headerName: "DELIVERY_TYPE",
  //   minWidth: 180,
  //   cellRenderer: (params) =>
  //     translatePipe.transform(DeliveryTypes[params.data.deliveryType]),
  // },
  // {
  //   field: "checkbox2",
  //   headerName: "DELIVERY_TYPE_IN_PERSON",
  //   minWidth: 220,
  //   pinned: "right",
  //   cellRenderer: CheckboxRenderer,
  // },
];

export const ConfirmCashPaymentRequestCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "familyCode", headerName: "FAMILY_CODE", minWidth: 140 },
  { field: "", headerName: "WIDOW_NAME", minWidth: 100 },
  { field: "", headerName: "DEAD_NAME", minWidth: 100 },
  { field: "beneficiaryName", headerName: "BENEFICIARY_NAME", minWidth: 180 },
  { field: "beneficiaryType", headerName: "BENEFICIARY_TYPE", minWidth: 180 },
  { field: "aidType", headerName: "CASH_AID_ACCOUNT_TREE", minWidth: 110 },
  {
    field: "",
    headerName: "AMOUNT_AR",
    minWidth: 100,
    cellRenderer: (params) => pricePipe.transform(params.data.amount),
  },
  {
    field: "deliveryType",
    headerName: "DELIVERY_TYPE",
    minWidth: 180,
    pinned: "right",
    headerCheckboxSelection: true,
    checkboxSelection: true,
    showDisabledCheckboxes: true,
  },
  {
    field: "checkbox",
    headerName: "CONFIRM",
    minWidth: 100,
    pinned: "right",
    cellRenderer: CheckboxRenderer,
  },
];

export const CashPaymentReceiptToAgent0Cols = [
  {
    field: "check",
    headerName: "",
    pinned: "right",
    minWidth: 100,
    cellRenderer: ButtonRenderer,
    cellRendererParams: {
      type: "fill",
      color: "blue",
      icon: "add",
      text: "PAY",
      action: "check",
      disabled: true,
    },
  },
  { field: "", headerName: "#", pinned: "right", minWidth: 60 },
  { field: "aidRequestCode", headerName: "REQUEST_CODE", minWidth: 100 },
  {
    field: "",
    headerName: "NEED_REQUEST_DATE",
    minWidth: 120,
    cellRenderer: (params) =>
      datePipe.transform(params.data.aidRequestDate, "YYYY-MM-dd"),
  },
  { field: "festival", headerName: "PROGRAM", minWidth: 150 },
  // { field: "project", headerName: "PROJECT", minWidth: 150 },
  { field: "familyCode", headerName: "FAMILY_CODE", minWidth: 100 },
  { field: "supervisor", headerName: "FAMILY", minWidth: 150 },
  { field: "familyMemberCode", headerName: "BENEFICIARY_CODE", minWidth: 100 },
  { field: "beneficiaryName", headerName: "BENEFICIARY_NAME", minWidth: 150 },
  { field: "donationTree", headerName: "CASH_AID_ACCOUNT_TREE", minWidth: 120 },
  { field: "geoName", headerName: "GEO_NAME", minWidth: 120 },
  { field: "address", headerName: "ADDRESS", minWidth: 200 },
  { field: "phone", headerName: "PHONE", minWidth: 150 },
  {
    field: "",
    headerName: "CONFIRMED_AMOUNT",
    minWidth: 100,
    cellRenderer: (params) => pricePipe.transform(params.data.approvedAmount),
  },
];

export const CashPaymentReceiptToAgent1Cols = [
  {
    field: "check",
    headerName: "",
    pinned: "right",
    minWidth: 100,
    cellRenderer: ButtonRenderer,
    cellRendererParams: {
      type: "fill",
      color: "danger",
      icon: "add",
      text: "RETURN",
      action: "check",
      // disabled: true
    },
  },
  { field: "", headerName: "#", pinned: "right", minWidth: 60 },
  { field: "requestCode", headerName: "REQUEST_CODE", minWidth: 100 },
  {
    field: "",
    headerName: "REQUEST_DATE",
    minWidth: 120,
    cellRenderer: (params) =>
      datePipe.transform(params.data.requestDate, "YYYY-MM-dd"),
  },
  { field: "festival", headerName: "PROGRAM", minWidth: 150 },
  // { field: "project", headerName: "PROJECT", minWidth: 150 },
  {
    field: "",
    headerName: "REQUEST_PAY_FUND_DATE",
    minWidth: 120,
    cellRenderer: (params) =>
      datePipe.transform(params.data.paymentRequestDate, "YYYY-MM-dd"),
  },
  { field: "familyCode", headerName: "FAMILY_CODE", minWidth: 100 },
  { field: "supervisor", headerName: "FAMILY", minWidth: 150 },
  { field: "familyMemberCode", headerName: "BENEFICIARY_CODE", minWidth: 100 },
  { field: "beneficiaryName", headerName: "BENEFICIARY_NAME", minWidth: 150 },
  { field: "donationTree", headerName: "CASH_AID_ACCOUNT_TREE", minWidth: 120 },
  { field: "geoName", headerName: "GEO_NAME", minWidth: 120 },
  { field: "address", headerName: "ADDRESS", minWidth: 200 },
  { field: "phone", headerName: "PHONE", minWidth: 150 },
  {
    field: "",
    headerName: "AMOUNT_AR",
    minWidth: 100,
    cellRenderer: (params) => pricePipe.transform(params.data.approvedAmount),
  },
];

export const CashPaymentToAgentListCols = [
  { field: "", headerName: "#", pinned: "right", minWidth: 60 },
  { field: "code", headerName: "PAYMENT_TO_AGENT_CODE", minWidth: 180 },
  {
    field: "",
    headerName: "DATE",
    minWidth: 120,
    cellRenderer: (params) =>
      datePipe.transform(params.data.date, "YYYY-MM-dd"),
  },
  { field: "branchTitle", headerName: "BRANCH", minWidth: 150 },
  {
    field: "",
    headerName: "TOTAL_AMOUNT",
    minWidth: 100,
    cellRenderer: (params) =>
      pricePipe.transform(params.data.totalApprovedAmount),
  },
  { field: "transfereeName", headerName: "ACCOUNTANT", minWidth: 150 },
  { field: "deliveryName", headerName: "AGENT", minWidth: 150 },
  { field: "description", headerName: "DESCRIPTION", minWidth: 200 },
  {
    field: "",
    headerName: "STATUS",
    pinned: "left",
    minWidth: 190,
    cellRenderer: StatusRenderer,
    cellRendererParams: (params) => {
      params.data.statusName =
        PaymentReceiptToTheAgentStatuses[params.data.status];
      switch (params.data.status) {
        case 1:
          params.data.theme = "yellow";
          break;
        case 2:
          params.data.theme = "green";
          break;
        default:
          break;
      }
    },
  },
  {
    field: "action",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: (params) => {
      let dropdownItems = [];
      switch (params.data.status) {
        case 1:
          dropdownItems = ["VIEW", "EDIT", "PRINT", "DELETE"];
          break;
        case 2:
          dropdownItems = ["VIEW", "PRINT"];
          break;
      }
      return { dropdownItems: dropdownItems }; // , "HISTORY"
    },
  },
];

export const CashPaymentReceiptToAgentInPrintCols = [
  { field: '', headerName: '#', pinned: 'right', minWidth: 60 },
  { field: 'beneficiaryType', headerName: 'BENEFICIARY_TYPE', minWidth: 100 },
  { field: 'beneficiaryName', headerName: 'BENEFICIARY_NAME', minWidth: 150 },
  { field: 'donationTree', headerName: 'CASH_AID_ACCOUNT_TREE', minWidth: 120 },
  { field: '', headerName: 'AMOUNT_AR', minWidth: 100, cellRenderer: (params: any) => pricePipe.transform(params.data.approvedAmount) }
];

export const ReturnToCashListCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "", headerName: "RETURN_TO_CASH_RECEIPT_CODE", minWidth: 200 },
  { field: "", headerName: "RETURN_TO_FUND_DATE", minWidth: 200 },
  { field: "", headerName: "PAY_TO_AGENT_CODE", minWidth: 200 },
  { field: "", headerName: "PAY_TO_AGENT_DATE", minWidth: 200 },
  { field: "branch", headerName: "BRANCH", minWidth: 100 },
  { field: "agent", headerName: "AGENT", minWidth: 90 },
  { field: "", headerName: "DELIVERY", minWidth: 220 },
  { field: "description", headerName: "DESCRIPTION", minWidth: 160 },
  {
    field: "action",
    headerName: "",
    minWidth: 85,
    pinned: "left",
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["VIEW", "HISTORY", "DELETE"] },
  },
];

export const ReturnToCashCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "familyCode", headerName: "FAMILY_CODE", minWidth: 130 },
  { field: "", headerName: "WIDOW_NAME", minWidth: 100 },
  { field: "", headerName: "DEAD_NAME", minWidth: 100 },
  { field: "", headerName: "BENEFICIARY_NAME", minWidth: 220 },
  { field: "address", headerName: "ADDRESS", minWidth: 120 },
  { field: "phone", headerName: "PHONE", minWidth: 100 },
  {
    field: "",
    headerName: "AMOUNT_AR",
    minwidth: 100,
    cellRenderer: (params) => pricePipe.transform(params.data.amount),
  },
  {
    field: "checkbox",
    headerName: "RETURN",
    minWidth: 125,
    pinned: "right",
    cellRenderer: CheckboxRenderer,
  },
  { field: "returnReason", headerName: "RETURN_REASON", minWidth: 150 },
];

export const CashDirectDeliveryToFamilyCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "familyCode", headerName: "FAMILY_CODE", minWidth: 130 },
  { field: "", headerName: "BENEFICIARY_NAME", minWidth: 200 },
  { field: "aidType", headerName: "CASH_AID_ACCOUNT_TREE", minWidth: 130 },
  {
    field: "",
    headerName: "AMOUNT_AR",
    minWidth: 100,
    cellRenderer: (params) => pricePipe.transform(params.data.amount),
  },
  {
    field: "checkbox",
    headerName: "DELIVERED",
    minWidth: 140,
    pinned: "right",
    cellRenderer: CheckboxRenderer,
  },
];

export const CashIndirectDeliveryToFamilyCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "familyCode", headerName: "FAMILY_CODE", minWidth: 150 },
  { field: "beneficiaryName", headerName: "BENEFICIARY_NAME", minWidth: 220 },
  { field: "aidType", headerName: "CASH_AID_ACCOUNT_TREE", minWidth: 140 },
  {
    field: "",
    headerName: "AMOUNT_AR",
    minWidth: 100,
    cellRenderer: (params) => pricePipe.transform(params.data.amount),
  },
  {
    field: "checkbox",
    headerName: "DELIVERED",
    pinned: "right",
    cellRenderer: CheckboxRenderer,
  },
];

export const ProgramListCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "title", headerName: "TITLE", minWidth: 200 },
  { field: "programTypeName", headerName: "PROGRAM_TYPE", minWidth: 150 },
  {
    field: "startDate",
    headerName: "START_DATE",
    cellRenderer: (params) =>
      datePipe.transform(params.data.startDate, "YYYY-MM-dd"),
    minWidth: 130,
  },
  {
    field: "endDate",
    headerName: "END_DATE",
    cellRenderer: (params) =>
      datePipe.transform(params.data.endDate, "YYYY-MM-dd"),
    minWidth: 125,
  },
  { field: "description", headerName: "DESCRIPTION", minWidth: 200 },
  {
    field: "action",
    headerName: "",
    minWidth: 85,
    pinned: "left",
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["EDIT", "DELETE"] },
  },
];

export const ConfirmServicesNeedsOfFamilyCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "familyCode", headerName: "FAMILY_CODE", minWidth: 100 },
  { field: "motherName", headerName: "MOTHER_NAME", minWidth: 150 },
  { field: "deadName", headerName: "DEAD_NAME", minWidth: 150 },
  { field: "beneficiaryName", headerName: "BENEFICIARY_NAME", minWidth: 150 },
  { field: "beneficiaryType", headerName: "BENEFICIARY_TYPE", minWidth: 150 },
  { field: "aidType", headerName: "AID_TYPE", minWidth: 200 },
  { field: "service", headerName: "SERVICE", minWidth: 150 },
  { field: "description", headerName: "DESCRIPTION", minWidth: 200 },
  {
    field: "checkbox",
    headerName: "CONFIRM",
    minWidth: 80,
    pinned: "right",
    cellRenderer: CheckboxRenderer,
  },
  { field: "confirmedCount", headerName: "CONFIRMED_COUNT", minWidth: 150 },
  {
    field: "checkbox2",
    headerName: "CONFIRM",
    minWidth: 80,
    pinned: "right",
    cellRenderer: CheckboxRenderer,
  },
  { field: "confirmedCount", headerName: "CONFIRMED_COUNT", minWidth: 100 },
  {
    field: "",
    headerName: "",
    minWidth: 250,
    cellRenderer: ButtonRenderer,
    cellRendererParams: {
      type: "fill",
      color: "blue",
      icon: "add",
      text: "SELECT_LOCATION_COST",
      action: "select-location-cash",
    },
  },
];

export const ServicesAidWorkListCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "id", headerName: "ID", minWidth: 75 },
  { field: "branch", headerName: "BRANCH", minWidth: 75 },
  { field: "program", headerName: "PROGRAM", minWidth: 85 },
  { field: "", headerName: "PRESENTATION_TYPE", minWidth: 120 },
  { field: "", headerName: "DONOR_OR_RESPONSIBLE", minWidth: 170 },
  { field: "familyCode", headerName: "FAMILY_CODE", minWidth: 100 },
  { field: "", headerName: "WIDOW_NAME", minWidth: 120 },
  { field: "", headerName: "DEAD_NAME", minWidth: 120 },
  { field: "aidType", headerName: "AID_TYPE", minWidth: 150 },
  {
    field: "",
    headerName: "SERVICE_COUNT_UNIT_BENEFICIARY_NAME_ORPHAN",
    minWidth: 310,
  },
  { field: "address", headerName: "ADDRESS", minWidth: 120 },
  { field: "mobile", headerName: "MOBILE", minWidth: 100 },
  { field: "startDate", headerName: "START_DATE", minWidth: 130 },
  { field: "endDate", headerName: "END_DATE", minWidth: 130 },
  {
    field: "",
    headerName: "STATUS",
    pinned: "left",
    minWidth: 190,
    cellRenderer: StatusRenderer,
    cellRendererParams: (params) => {
      params.data.statusName = ApprovalStatuses[params.data.status];
      switch (params.data.status) {
        case 0:
          params.data.theme = "yellow";
          break;
        case 1:
          params.data.theme = "green";
          break;
        case 2:
          params.data.theme = "red";
          break;
        default:
          break;
      }
    },
  },
  {
    field: "action",
    headerName: "",
    minWidth: 85,
    pinned: "left",
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["EDIT", "CONFIRM", "RETURN"] },
  },
];

export const ServicesAidListCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "id", headerName: "ID", minWidth: 75 },
  { field: "branch", headerName: "BRANCH", minWidth: 75 },
  { field: "program", headerName: "PROGRAM", minWidth: 85 },
  { field: "", headerName: "PRESENTATION_TYPE", minWidth: 140 },
  { field: "", headerName: "DONOR_OR_RESPONSIBLE", minWidth: 150 },
  { field: "familyCode", headerName: "FAMILY_CODE", minWidth: 100 },
  { field: "", headerName: "WIDOW_NAME", minWidth: 120 },
  { field: "", headerName: "DEAD_NAME", minWidth: 120 },
  { field: "aidType", headerName: "AID_TYPE", minWidth: 150 },
  {
    field: "",
    headerName: "SERVICE_COUNT_UNIT_BENEFICIARY_NAME_ORPHAN",
    minWidth: 310,
  },
  { field: "address", headerName: "ADDRESS", minWidth: 120 },
  { field: "mobile", headerName: "MOBILE", minWidth: 100 },
  { field: "startDate", headerName: "START_DATE", minWidth: 130 },
  { field: "endDate", headerName: "END_DATE", minWidth: 130 },
  {
    field: "",
    headerName: "STATUS",
    pinned: "left",
    minWidth: 190,
    cellRenderer: StatusRenderer,
    cellRendererParams: (params) => {
      params.data.statusName = ApprovalStatuses[params.data.status];
      switch (params.data.status) {
        case 0:
          params.data.theme = "yellow";
          break;
        case 1:
          params.data.theme = "green";
          break;
        case 2:
          params.data.theme = "red";
          break;
        default:
          break;
      }
    },
  },
  {
    field: "action",
    headerName: "",
    minWidth: 85,
    pinned: "left",
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: (params) => {
      const action = ["VIEW", "EDIT", "CONFIRM", "DELETE"];
      if (params.data.status !== 0) {
        action.splice(2, 1);
      }
      return { dropdownItems: action };
    },
  },
];

export const ExpenseSubmitCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "expenseDate", headerName: "EXPENSE_DATE", minWidth: 130 },
  { field: "accountParty", headerName: "ACCOUNT_PARTY", minWidth: 150 },
  {
    field: "",
    headerName: "TOTAL_AMOUNT_AR",
    minWidth: 140,
    cellRenderer: (params) => pricePipe.transform(params.data.totalAmount),
  },
  { field: "receiptId", headerName: "RECEIPT_ID_ACC_PARTY", minWidth: 210 },
  { field: "description", headerName: "DESCRIPTION", minWidth: 140 },
  {
    field: "action",
    headerName: "",
    minWidth: 85,
    pinned: "left",
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["DELETE"] },
  },
];

export const ExpensesCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "", headerName: "EXPENSE_ID", minWidth: 130 },
  { field: "", headerName: "EXPENSE_DATE", minWidth: 150 },
  { field: "", headerName: "BRANCH", minWidth: 140 },
  { filed: "", headerName: "SECTION", minWidth: 210 },
  { filed: "", headerName: "RESPONSIBLE_SPENDER", minWidth: 210 },
  { filed: "", headerName: "COST_GROUP", minWidth: 210 },
  { filed: "", headerName: "COST", minWidth: 210 },
  { filed: "", headerName: "TOTAL_AMOUNT_AR", minWidth: 210 },
  { filed: "", headerName: "REGISTERAR", minWidth: 210 },
  { field: "description", headerName: "DESCRIPTION", minWidth: 140 },
  {
    field: "checkbox",
    headerName: "SELECT",
    pinned: "right",
    cellRenderer: CheckboxRenderer,
    minWidth: 160,
  },
];

export const RemittanceCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "", headerName: "DATE", minWidth: 130 },
  { field: "", headerName: "NUMBER", minWidth: 150 },
  { field: "", headerName: "MANUAL_NUMBER", minWidth: 140 },
  { filed: "", headerName: "SHEET", minWidth: 210 },
  { filed: "", headerName: "BRANCH", minWidth: 210 },
  { filed: "", headerName: "SHEET_STATUS", minWidth: 210 },
  { filed: "", headerName: "COST", minWidth: 210 },
  {
    field: "action",
    headerName: "",
    minWidth: 85,
    pinned: "left",
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["DELETE"] },
  },
];

export const AidGoodListCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  {
    field: "select",
    headerName: "SELECT",
    minWidth: 120,
    pinned: "right",
    headerCheckboxSelection: true,
    checkboxSelection: true,
    showDisabledCheckboxes: true,
  },
  { field: "", headerName: "SERIAL_NUMBER", minWidth: 130 },
  { field: "", headerName: "GOOD_CODE", minWidth: 150 },
  { field: "", headerName: "GOOD_NAME", minWidth: 140 },
  { filed: "", headerName: "UNIT", minWidth: 210 },
  { filed: "", headerName: "COUNT", minWidth: 210 },
  { filed: "", headerName: "WAREHOUSE_CODE", minWidth: 210 },
  { filed: "", headerName: "WAREHOUSE_NAME", minWidth: 210 },
  { filed: "", headerName: "DESCRIPTION", minWidth: 210 },
  { filed: "", headerName: "EXPIRY_DATE", minWidth: 210 },
  { filed: "", headerName: "CONVERTIBLE_COUNT", minWidth: 210 },
  { filed: "", headerName: "SELECTED_COUNT", minWidth: 210 },
];

export const EvaluationServicesDonationCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "program", headerName: "PROGRAM", minWidth: 120 },
  { field: "donationId", headerName: "DONATION_ID", minWidth: 140 },
  {
    field: "expenseServiceAidId",
    headerName: "EXPENSE_SERVICE_AID_ID",
    minWidth: 200,
  },
  { field: "serviceCode", headerName: "SERVICE_CODE", minWidth: 150 },
  { field: "service", headerName: "SERVICE", minWidth: 100 },
  { field: "count", headerName: "COUNT", minWidth: 90 },
  { field: "unit", headerName: "UNIT", minWidth: 90 },
  { field: "beneficiaryType", headerName: "BENEFICIARY_TYPE", minWidth: 180 },
  { field: "personFamily", headerName: "PERSON_FAMILY", minWidth: 160 },
  { field: "provideDate", headerName: "PROVIDE_DATE", minWidth: 160 },
  { field: "description", headerName: "DESCRIPTION", minWidth: 160 },
];

export const ConfirmDonationRequestCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "program", headerName: "PROGRAM", minWidth: 120 },
  { field: "donationId", headerName: "DONATION_ID", minWidth: 140 },
  {
    field: "expenseServiceAidId",
    headerName: "EXPENSE_SERVICE_AID_ID",
    minWidth: 200,
  },
  { field: "serviceCode", headerName: "SERVICE_CODE", minWidth: 150 },
  { field: "service", headerName: "SERVICE", minWidth: 100 },
  { field: "count", headerName: "COUNT", minWidth: 90 },
  { field: "unit", headerName: "UNIT", minWidth: 90 },
  { field: "beneficiaryType", headerName: "BENEFICIARY_TYPE", minWidth: 180 },
  { field: "personFamily", headerName: "PERSON_FAMILY", minWidth: 160 },
  { field: "provideDate", headerName: "PROVIDE_DATE", minWidth: 160 },
  { field: "description", headerName: "DESCRIPTION", minWidth: 160 },
];

export const OtherCostsCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "section", headerName: "SECTION", minWidth: 110 },
  { field: "date", headerName: "DATE", minWidth: 90 },
  { field: "responsible", headerName: "RESPONSIBLE", minWidth: 130 },
  { field: "cost", headerName: "COST", minWidth: 90 },
  {
    field: "",
    headerName: "AMOUNT_AR",
    minWidth: 100,
    cellRenderer: (params) => pricePipe.transform(params.data.amount),
  },
  {
    field: "factorNumber",
    headerName: "ACCOUNT_PARTY_RECEIPT_ID",
    minWidth: 220,
  },
  { field: "partyAcount", headerName: "ACCOUNT_PARTY", minWidth: 130 },
  { field: "decription", headerName: "DESCRIPTION", minWidth: 130 },
];

export const ConsumerGoodsCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "date", headerName: "DATE", minWidth: 130 },
  { field: "exitId", headerName: "EXIT_WAREHOUSE_ID", minWidth: 160 },
  { field: "goodSerialNum", headerName: "GOOD_SERIAL_NUM", minWidth: 170 },
  { field: "goodCode", headerName: "GOOD_CODE", minWidth: 100 },
  { field: "count", headerName: "COUNT", minWidth: 90 },
  { field: "unit", headerName: "UNIT", minWidth: 90 },
  {
    field: "",
    headerName: "EVALUATED_AMOUNT",
    minWidth: 170,
    cellRenderer: (params) => pricePipe.transform(params.data.evaluatedAmount),
  },
  {
    field: "",
    headerName: "TOTAL_AMOUNT_AR",
    minWidth: 140,
    cellRenderer: (params) => pricePipe.transform(params.data.totalAmount),
  },
  { field: "warehouse", headerName: "WAREHOUSE", minWidth: 120 },
  { field: "description", headerName: "DESCRIPTION", minWidth: 130 },
];

export const OtherServicesCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "date", headerName: "DATE", minWidth: 130 },
  { field: "cost", headerName: "COST", minWidth: 80 },
  { field: "service", headerName: "SERVICE", minWidth: 110 },
  { field: "donor", headerName: "DONOR", minWidth: 100 },
  { field: "donationDate", headerName: "DONATION_DATE", minWidth: 130 },
  { field: "doantionId", headerName: "DONATION_ID", minWidth: 130 },
  { field: "providedCount", headerName: "PRESENTED_COUNT", minWidth: 150 },
  {
    field: "",
    headerName: "EVALUATED_AMOUNT",
    minWidth: 170,
    cellRenderer: (params) => pricePipe.transform(params.data.evaluatedAmount),
  },
  {
    field: "",
    headerName: "TOTAL_AMOUNT_AR",
    minWidth: 170,
    cellRenderer: (params) => pricePipe.transform(params.data.totalAmount),
  },
  { field: "description", headerName: "DESCRIPTION", minWidth: 130 },
];

export const AidGoodCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "", headerName: "DATE", minWidth: 120 },
  { field: "", headerName: "EXIT_GOOD_ID_FROM_WAREHOUSE", minWidth: 180 },
  { field: "", headerName: "GOOD_SERIAL_NUMBER", minWidth: 160 },
  { field: "", headerName: "GOOD_CODE", minWidth: 120 },
  { field: "", headerName: "COUNT", minWidth: 120 },
  { field: "", headerName: "UNIT", minWidth: 120 },
  { field: "", headerName: "EVALUATED_AMOUNT", minWidth: 170 },
  { field: "", headerName: "TOTAL_AMOUT", minWidth: 120 },
  { field: "", headerName: "WAREHOUSE", minWidth: 120 },
  { field: "", headerName: "DESCRIPTION", minWidth: 120 },
  {
    field: "action",
    headerName: "",
    minWidth: 85,
    pinned: "left",
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["DELETE"] },
  },
];

export const AidSideServiceCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "", headerName: "DATE", minWidth: 120 },
  { field: "", headerName: "COST", minWidth: 120 },
  { field: "", headerName: "SERVICE", minWidth: 120 },
  { field: "", headerName: "DONOR", minWidth: 120 },
  { field: "", headerName: "DONATION_DATE", minWidth: 120 },
  { field: "", headerName: "DONATION_ID", minWidth: 120 },
  { field: "", headerName: "AID_DATE", minWidth: 120 },
  { field: "", headerName: "HELP_SERVICE_ID", minWidth: 170 },
  { field: "", headerName: "PRESENTED_COUNT", minWidth: 120 },
  { field: "", headerName: "EVALUATED_AMOUNT", minWidth: 170 },
  { field: "", headerName: "TOTAL_AMOUT", minWidth: 120 },
  { field: "", headerName: "DESCRIPTION", minWidth: 120 },
  {
    field: "action",
    headerName: "",
    minWidth: 85,
    pinned: "left",
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["DELETE"] },
  },
];

export const ServiceAidList2Cols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "id", headerName: "ID", minWidth: 90 },
  { field: "branch", headerName: "BRANCH", minWidth: 80 },
  { field: "program", headerName: "PROGRAM", minWidth: 90 },
  { field: "presentationType", headerName: "PRESENTATION_TYPE", minWidth: 180 },
  { field: "donor", headerName: "DONOR_OR_RESPONSIBLE", minWidth: 210 },
  { field: "familyCode", headerName: "FAMILY_CODE", minWidth: 130 },
  { field: "", headerName: "WIDOW_NAME", minWidth: 120 },
  { field: "", headerName: "DEAD_NAME", minWidth: 120 },
  { field: "aidType", headerName: "AID_TYPE", minWidth: 120 },
  { field: "beneficiaryName", headerName: "BENEFICIARY_NAME", minWidth: 150 },
  { field: "servicie", headerName: "SERVICE_COUNT_UNIT", minWidth: 220 },
  { field: "address", headerName: "ADDRESS", minWidth: 120 },
  { field: "mobile", headerName: "MOBILE", minWidth: 90 },
  { field: "startDate", headerName: "START_DATE", minWidth: 130 },
  { field: "endDate", headerName: "END_DATE", minWidth: 130 },
  { field: "status", headerName: "STATUS", minWidth: 110 },
  {
    field: "checkbox",
    headerName: "SELECT",
    minWidth: 90,
    pinned: "right",
    cellRenderer: CheckboxRenderer,
  },
];

export const FamilyInquiryListCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  {
    field: "",
    headerName: "REQUEST_DATE",
    minWidth: 150,
    cellRenderer: (params) =>
      datePipe.transform(params.data.requestDate, "YYYY-MM-dd"),
  },
  { field: "type", headerName: "TYPE", minWidth: 200, cellRenderer: (params) => translatePipe.transform(FamilyRegisterRequestTypes[params.data.type]) },
  { field: "title", headerName: "TITLE", minWidth: 150 },
  { field: "discoveryTeam", headerName: "DISCOVERY_TEAM_TITLE", minWidth: 150 },
  { field: "mediatorName", headerName: "MEDIATOR_NAME", minWidth: 150 },
  { field: "mediatorPhoneNumber", headerName: "MOBILE", minWidth: 120 },
  { field: "branchTitle", headerName: "BRANCH", minWidth: 150 },
  {
    field: "familyBeneficiaryTypeTitle",
    headerName: "BENEFICIARY_TYPE",
    minWidth: 150,
  },
  { field: "familyCode", headerName: "FAMILY_CODE", minWidth: 120 },
  { field: "address", headerName: "ADDRESS", minWidth: 200 },
  { field: "description", headerName: "DESCRIPTION", minWidth: 200 },
  {
    field: "",
    headerName: "REQUEST_STATUS",
    pinned: "left",
    minWidth: 170,
    cellRenderer: StatusRenderer,
    cellRendererParams: (params) => {
      let dropdownItems = [];
      params.data.statusName = FamilyRegisterRequestSteps[params.data.step];
      switch (params.data.step) {
        case 0:
          dropdownItems = ["EDIT", "DELETE"];
          params.data.theme = "yellow";
          break;
        case 1:
          dropdownItems = ["EDIT", "REVIEW_MANAGER_DISCLOSURE", "DELETE"];
          params.data.theme = "yellow";
          break;
        case 2:
          params.data.theme = "orange";
          dropdownItems = ["VIEW_DISCOVERY_TEAM", "DISCLOSURE"];
          break;
        case 3:
          dropdownItems = ["VIEW_DISCOVERY_TEAM", "AUDIT_DISCLOSURE"];
          params.data.theme = "brown";
          break;
        case 4:
          dropdownItems = ["VIEW_DISCOVERY_TEAM", "FINAL_APPROVAL_2"];
          params.data.theme = "primary";
          break;
        case 5:
          dropdownItems = ["VIEW", "VIEW_DISCOVERY_TEAM"];
          params.data.theme = "green";
          break;
      }
      params.data.dropdownItems = dropdownItems;
      return params;
    },
  },
  {
    field: "action",
    headerName: "",
    minWidth: 85,
    pinned: "left",
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: (params) => {
      return { dropdownItems: params.data.dropdownItems };
    },
  },
];

export const FamilyListCols = [
  { field: "#", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "branchTitle", headerName: "BRANCH", minWidth: 150 },
  {
    field: "beneficiaryTypeTitle",
    headerName: "BENEFICIARY_TYPE",
    minWidth: 150,
  },
  {
    field: "",
    headerName: "CREATION_DATE",
    minWidth: 150,
    cellRenderer: (params) =>
      datePipe.transform(params.data.firstFinalConfirm, "YYYY-MM-dd"),
  },
  { field: "code", headerName: "FAMILY_CODE", minWidth: 120 },
  { field: "statisticalNo", headerName: "STATISTICAL_NUMBER", minWidth: 120 },
  { field: "rationCardNumber", headerName: "STOCK_CARD_NUMBER", minWidth: 150 },
  { field: "supervisorName", headerName: "SUPERVISOR_NAME", minWidth: 150 },
  {
    field: "familyMembersCount",
    headerName: "FAMILY_MEMBERS_COUNT",
    minWidth: 160,
  },
  {
    field: "",
    headerName: "FAMILY_TYPE",
    minWidth: 160,
    cellRenderer: (params) => translatePipe.transform(FamilyTypes[params.data.familyType])
  },
  {
    field: "economicStatusTitle",
    headerName: "ECONOMIC_STATUS",
    minWidth: 180,
    type: "template",
    cellRenderer: (params) => {
      let theme = "";
      switch (params.data.economicStatus) {
        case 0:
          theme = "yellow";
          break;
        case 1:
          theme = "red";
          break;
        case 2:
          theme = "orange";
          break;
        case 3:
          theme = "blue";
          break;
        case 4:
          theme = "green";
          break;
      }
      return `<div class="status status-${theme}">${translatePipe.transform(
        EconomicStatuses[params.data.economicStatus]
      )}</div>`;
    },
  },
  {
    field: "",
    headerName: "FOOD_IN_TAKE_STATUS",
    minWidth: 180,
    type: "template",
    cellRenderer: (params) => {
      let theme = "";
      switch (params.data.foodIntakeStatus) {
        case 0:
          theme = "yellow";
          break;
        case 1:
          theme = "orange";
          break;
        case 2:
          theme = "blue";
          break;
      }
      return `<div class="status status-${theme}">${translatePipe.transform(
        FoodIntakeStatuses[params.data.foodIntakeStatus]
      )}</div>`;
    },
  },
  {
    field: "",
    headerName: "IS_INCLUDED",
    minWidth: 250,
    type: "template",
    cellRenderer: (params) =>
      params.data.isIncluded === true
        ? '<div class="text-center"><i class="bx bx-check fw-bolder"> </i></div>'
        : (params.data.isIncluded === false ? '<div class="text-center"><i class="bx bx-x fw-bolder"> </i></div>' : ''),
  },
  {
    field: "",
    headerName: "LAST_UPDATE_DATE",
    minWidth: 150,
    cellRenderer: (params) =>
      datePipe.transform(params.data.lastFinalConfirm, "YYYY-MM-dd"),
  },
  { field: "mukhtar", headerName: "MUKHTAR", minWidth: 150 },
  { field: "supervisorPhone", headerName: "SUPERVISOR_PHONE", minWidth: 120 },
  { field: "address", headerName: "ADDRESS", minWidth: 200 },
  {
    field: "action",
    headerName: "",
    minWidth: 85,
    pinned: "left",
    type: "dropdown",
    cellStyle: { overflow: "unset" },
    cellRendererParams: {
      dropdownItems: ["VIEW_", "EDIT", "REVIEW_REQUEST", "DELETE", "PRINT"], // + "REQUESTS"
    },
  },
];

export const RequestsListCols = [
  { field: "", headerName: "#", minWidth: 60, editable: true, pinned: "right" },
  { field: "branch", headerName: "branch", minWidth: 100, editable: true },
  {
    field: "beneficiaryType",
    headerName: "BENEFICIARY_TYPE",
    minWidth: 100,
    editable: true,
  },
  {
    field: "requestDate",
    headerName: "REQUEST_DATE",
    minWidth: 120,
    editable: true,
  },
  { field: "mobile", headerName: "MOBILE", minWidth: 100, editable: true },
  { field: "address", headerName: "ADDRESS", minWidth: 110, editable: true },
  {
    field: "description",
    headerName: "DESCRIPTION",
    minWidth: 130,
    editable: true,
  },
  { field: "urgent", headerName: "URGENT", minWidth: 110, editable: true },
  {
    field: "requestStatus",
    headerName: "REQUEST_STATUS",
    pinned: "left",
    minWidth: 200,
    editable: true,
    cellRenderer: (params) => {
      const index = params.data.requestStatus;
      return `<div class="status status-${
        REQUEST_STATUS[index].color
      }">${translatePipe.transform(REQUEST_STATUS[index].title)}</div>`;
    },
  },
];

export const RelationSettingsCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "title", headerName: "TITLE", minWidth: 90 },
  {
    field: "action",
    headerName: "",
    minWidth: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["EDIT", "DELETE"] },
  },
];

export const DeathReasonCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "title", headerName: "TITLE", minWidth: 80 },
  {
    field: "action",
    headerName: "",
    minWidth: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["EDIT", "DELETE"] },
  },
];

export const TypeOfOwnershipCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "title", headerName: "TITLE", minWidth: 80 },
  {
    field: "action",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["EDIT", "DELETE"] },
  },
];

export const IneligibilityForAidListCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "title", headerName: "TITLE", minWidth: 80 },
  {
    field: "action",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["EDIT", "DELETE"] },
  },
];

export const StructureTypeCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "title", headerName: "TITLE", minWidth: 80 },
  {
    field: "action",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["EDIT", "DELETE"] },
  },
];

export const InquiryHistoryCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  {
    field: "paperReceiptNumber",
    headerName: "PAPER_RECEIPT_NUMBER",
    minWidth: 100,
  },
  { field: "receiptCode", headerName: "RECEIPT_CODE", minWidth: 160 },
  {
    field: "receiptType",
    headerName: "RECEIVE_TYPE",
    minWidth: 130,
    cellRenderer: (params) =>
      translatePipe.transform(ReceiptAndPaymentTypes[params.data.receiptType]),
  },
  {
    field: "receiptDate",
    headerName: "RECEIPT_PAPER_DATE",
    minWidth: 140,
    cellRenderer: (params) =>
      datePipe.transform(params.data.receiptDate, "YYYY-MM-dd"),
  },
  {
    field: "amount",
    headerName: "AMOUNT_AR",
    minWidth: 100,
    cellRenderer: (params) => pricePipe.transform(params.data.amount),
  },
  // {
  //   field: "",
  //   headerName: "TATVIRI_AMOUNT_AR",
  //   minWidth: 140,
  //   cellRenderer: (params) => pricePipe.transform(params.data.tatviriAmount),
  // },
  // {
  //   field: "",
  //   headerName: "TOTAL_AMOUNT_AR",
  //   minWidth: 110,
  //   cellRenderer: (params) => pricePipe.transform(params.data.totalAmount),
  // },
  { field: "fullName", headerName: "BRANCH_AGENT", minWidth: 120 },
  // { field: "user", headerName: "REGISTERING_USER", minWidth: 130 },
  {
    field: "createDate",
    headerName: "SUBMIT_DATE",
    minWidth: 140,
    cellRenderer: (params) =>
      datePipe.transform(params.data.createDate, "YYYY-MM-dd"),
  },
  { field: "description", headerName: "DESCRIPTION", minWidth: 160 },
  {
    field: "action",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: (params) => {
      const dropdownItems = ["ATTACH", "PRINT", "DELETE"];
      if (
        params.data.cashDonationStatus === 1 &&
        params.data.receiptAmount !== params.data.amountIQD
      ) {
        dropdownItems.splice(2, 1);
      }
      return { dropdownItems };
      // if (params.data.cashDonationStatus !== 1 && params.data.cashDonationStatus !== 2) {
      //   return { dropdownItems: ["DELETE", "ATTACH", "PRINT"] };
      // } else {
      //   return { dropdownItems: ["ATTACH", "PRINT"] };
      // }
    },
  },
];

export const ReferralsCols = [
  { field: "", headerName: "#", minWidth: 60 },
  {
    field: "fullName",
    headerName: "NAME",
    minWidth: 180
  },
  { field: "job", headerName: "JOB", minWidth: 180 },
  { field: "phone", headerName: "PHONE", minWidth: 150 },
  {
    field: "action",
    headerName: "",
    minWidth: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["DELETE"] },
  },
];

export const FamilyMemberListCols = [
  { field: "", headerName: "#", pinned: "right", minWidth: 60 },
  {
    field: "",
    headerName: "IMAGE",
    minWidth: 100,
    cellRenderer: (params) => {
      return params.data.imageUrl
        ? '<img src="' +
            env +
            "/blob/" +
            params.data.imageUrl +
            '" alt="" width="90" height="80" class="rounded-circle p-2" />'
        : null;
    },
  },
  {
    field: "familyCode",
    headerName: "FAMILY_CODE",
    minWidth: 200,
    type: "link",
    cellRenderer: LinkRenderer,
    cellRendererParams: (params) => {
      return {
        url: siteUrl + "/family/family-information/" + params.data.familyIdentifier,
      };
    },
  },
  {
    field: "code",
    headerName: "MEMBER_CODE",
    minWidth: 200,
  },
  { field: "fullName", headerName: "MEMBER_NAME", minWidth: 150 },
  {
    field: "",
    headerName: "BIRTH_DATE",
    minWidth: 150,
    cellRenderer: (params) =>
      datePipe.transform(params.data.birthDate, "YYYY-MM-dd"),
  },
  { field: "age", headerName: "AGE", minWidth: 120 },
  {
    field: "",
    headerName: "GENDER",
    minWidth: 120,
    cellRenderer: (params) =>
      translatePipe.transform(Gender[params.data.gender]),
  },
  {
    field: "",
    headerName: "MARITAL_STATUS",
    minWidth: 120,
    cellRenderer: (params) =>
      translatePipe.transform(MaritalStatus[params.data.maritalStatus]),
  },
  {
    field: "",
    headerName: "RELIGIOUS_COMMITMENT",
    minWidth: 120,
    type: 'template',
    cellRenderer: (params) => {
      switch (params.data.isReligiousCommitment) {
        case true:
          return '<i class="bx bx-check fw-bolder"> </i>';
        case false:
          return '<i class="bx bx-x fw-bolder"> </i>';
        default:
          break;
      }
    },
  },
  {
    field: "educationDegree",
    headerName: "LAST_EDUCATIONAL_CERTIFICATE",
    minWidth: 150,
  },
  {
    field: "",
    headerName: "HEALTH_STATUS",
    minWidth: 120,
    cellRenderer: (params) =>
      translatePipe.transform(params.data.healthStatus?.toUpperCase()),
  },
  { field: "illness", headerName: "SICKNESS", minWidth: 150 },
  { field: "jobTitle", headerName: "JOB", minWidth: 150 },
  {
    field: "action",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["VIEW", "EDIT", "PRINT"] },
  },
];

export const FamilyMemberInLevel2Cols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60 },
  { field: 'fullName', headerName: 'MEMBER_NAME', minWidth: 120 },
  { field: '', headerName: 'BIRTH_DATE', minWidth: 120, cellRenderer: (params: any) => datePipe.transform(params.data.birthDate, 'YYYY-MM-dd') },
  { field: 'age', headerName: 'AGE', minWidth: 80 },
  { field: '', headerName: 'GENDER', minWidth: 80, cellRenderer: (params: any) => translatePipe.transform(Gender[params.data.gender]) },
  { field: '', headerName: 'MARITAL_STATUS', minWidth: 80, cellRenderer: (params: any) => translatePipe.transform(MaritalStatus[params.data.maritalStatus]) },
  { field: '', headerName: 'RELIGIOUS_COMMITMENT', minWidth: 80, type: 'template', cellRenderer: (params: any) => {
    switch (params.data.isReligiousCommitment) {
      case true: return '<i class="bx bx-check fw-bolder"> </i>';
      case false: return '<i class="bx bx-x fw-bolder"> </i>';
      default: break;
    }
  }},
  { field: 'educationDegree', headerName: 'DEGREE', minWidth: 80 },
  { field: '', headerName: 'HEALTH_STATUS', minWidth: 80, cellRenderer: (params: any) => translatePipe.transform(params.data.healthStatus?.toUpperCase()) },
  { field: 'illness', headerName: 'SICKNESS', minWidth: 120 },
  { field: 'jobTitle', headerName: 'JOB', minWidth: 120 }
];

export const FamilyMemberInfoCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  // { field: "image", headerName: "IMAGE", minWidth: 100 },
  { field: "fullName", headerName: "PERSON_NAME", minWidth: 150,
    cellRenderer: (params: any) => params.data.firstName + ' ' + params.data.lastName
  },
  {
    field: "birthDate",
    headerName: "BIRTH_DATE",
    minWidth: 150,
    cellRenderer: (params) =>
      datePipe.transform(params.data.birthDate, "YYYY-MM-dd"),
  },
  { field: "age", headerName: "AGE", minWidth: 120 },
  {
    field: "gender",
    headerName: "GENDER",
    minWidth: 100,
    cellRenderer: (params) =>
      translatePipe.transform(Gender[params.data.gender]),
  },
  {
    field: "maritalStatus",
    headerName: "MARITAL_STATUS",
    minWidth: 150,
    cellRenderer: (params) =>
      translatePipe.transform(MaritalStatus[params.data.maritalStatus]),
  },
  {
    field: "isSupervisor",
    headerName: "IS_GARDIAN",
    minWidth: 100,
    cellRenderer: CheckboxRenderer,
    cellRendererParams: (params) => {
      return { disabled: params.data.isSupervisor };
    },
  },
  {
    field: "calculateInFamilyMemberCount",
    headerName: "IS_MEMBER_OF_FAMILY",
    minWidth: 200,
    cellRenderer: CheckboxRenderer,
    cellRendererParams: () => {
      return { disabled: true };
    },
  },
  {
    field: "entitledReceiveSponsorship",
    headerName: "ENTITLED_RECEIVE_SPONSORSHIP",
    minWidth: 200,
    cellRenderer: CheckboxRenderer,
    cellRendererParams: () => {
      return { disabled: true };
    },
  },
  { field: "sponsorship_ReasonNotIncluded", headerName: "REASON_NOT_INCLUDEDS_FOR_SPONSORSHIP", minWidth: 150 },
  {
    field: "familyRelationType",
    headerName: "RELATIVE_WITH_FATHER",
    minWidth: 200,
  },
  /* {
    field: "incomeAmountUsed",
    headerName: "INCOME_SPEND_FOR_FAMILY",
    cellRenderer: (params) => pricePipe.transform(params.data.incomeAmountUsed),
    minWidth: 200,
  }, */
  {
    field: "action",
    headerName: "",
    minWidth: 85,
    pinned: "left",
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: {
      dropdownItems: ["EDIT", "PERSON_INFO_EDIT", "DELETE"],
    },
  },
];

export const CashReceiptDonationViewCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  {
    field: "donationRequestCode",
    headerName: "DONATION_RECEIPT_CODE",
    minWidth: 160,
  },
  {
    field: "donationRequestDate",
    headerName: "DONATION_DATE",
    minWidth: 165,
    cellRenderer: (params) =>
      datePipe.transform(params.data.donationRequestDate, "YYYY-MM-dd"),
  },
  { field: "donorFullName", headerName: "DONOR", minWidth: 160 }, // khali
  { field: "campaign", headerName: "CAMPAIGN", minWidth: 160 }, // khali
  {
    field: "receiptDate",
    headerName: "RECEIVE_DATE",
    minWidth: 160,
    cellRenderer: (params) =>
      datePipe.transform(params.data.receiptDate, "YYYY-MM-dd"),
  },
  {
    field: "receiptCode",
    headerName: "RECEIPT_CODE",
    minWidth: 165,
  },
  {
    field: "receiptType",
    headerName: "RECEIVE_TYPE",
    minWidth: 160,
    cellRenderer: (params) => {
      return translatePipe.transform(
        ReceiptAndPaymentTypes[params.data.receiptType]
      );
    },
  },
  {
    field: "",
    headerName: "AMOUNT",
    minWidth: 160,
    cellRenderer: (params) => pricePipe.transform(params.data.amount),
  },
  {
    field: "",
    headerName: "TATVIRI_AMOUNT",
    minWidth: 160,
    cellRenderer: (params) => pricePipe.transform(params.data.tatviriAmount),
  },
  {
    field: "",
    headerName: "TOTAL_AMOUNT",
    minWidth: 160,
    cellRenderer: (params) => pricePipe.transform(params.data.totalAmount),
  },
  { field: "description", headerName: "RECEIVE_DESCRIPTION", minWidth: 160 },
];

export const CallNumbersCols = [
  { field: "", headerName: "#", minWidth: 60, suppressSizeToFit: true },
  { field: "callNumberType", headerName: "CALL_NUMBER_TYPE", minWidth: 150 },
  { field: "callNumber", headerName: "CALL_NUMBER", minWidth: 150 },
  {
    field: "action",
    headerName: "",
    minWidth: 85,
    suppressSizeToFit: true,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["EDIT", "DELETE"] },
  },
];

export const SocialNetworksCols = [
  { field: "", headerName: "#", minWidth: 60, suppressSizeToFit: true },
  {
    field: "socialNetworkType",
    headerName: "SOCIAL_NETWORK_TYPE",
    minWidth: 150,
  },
  { field: "networkId", headerName: "NETWORK", minWidth: 150 },
  {
    field: "action",
    headerName: "",
    minWidth: 85,
    suppressSizeToFit: true,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["EDIT", "DELETE"] },
  },
];

export const HealthInfoCols = [
  { field: '', headerName: '', minWidth: 60, pinned: 'right' },
  { field: '', headerName: 'HEALTH_STATUS', minWidth: 120, cellRenderer: (params: any) => translatePipe.transform(HealthStatuses[params.data.healthStatus]) },
  { field: '', headerName: 'HEIGHT', minWidth: 120, cellRenderer: (params: any) => params.data.height ? params.data.height + ' ' + translatePipe.transform(LENGTH_UNIT[0].name) : null },
  { field: '', headerName: 'WHEIGHT', minWidth: 120, cellRenderer: (params: any) => params.data.weight ? params.data.weight + ' ' + translatePipe.transform(WEIGHT_UNIT[0].name) : null },
  { field: 'shoeSize', headerName: 'SHOE_SIZE', minWidth: 120 },
  { field: '', headerName: 'WAIST', minWidth: 120, cellRenderer: (params: any) => params.data.waistSize ? params.data.waistSize + ' ' + translatePipe.transform(LENGTH_UNIT[0].name) : null },
  { field: 'tagsAll', headerName: 'HEALTH_TAGS', minWidth: 150 },
  { field: '', headerName: 'VISUAL_LEVEL', minWidth: 120, cellRenderer: (params: any) => translatePipe.transform(HealthStatuses[params.data.visualLevel]) },
  { field: '', headerName: 'HEARING_LEVEL', minWidth: 120, cellRenderer: (params: any) => translatePipe.transform(HealthStatuses[params.data.hearingLevel]) },
  { field: '', headerName: 'PRONUNCIATION_LEVEL', minWidth: 120, cellRenderer: (params: any) => translatePipe.transform(HealthStatuses[params.data.pronunciationLevel]) },
  { field: 'createDate', headerName: 'CREATE_DATE', minWidth: 130, cellRenderer: DateRenderer },
  { field: 'description', headerName: 'DESCRIPTION', minWidth: 200 },
  { field: 'action', headerName: '', minWidth: 85, pinned: 'left', cellRenderer: DropdownButtonRenderer, cellRendererParams: { dropdownItems: ['EDIT', 'DELETE'] }}
];

export const IllnessInfoCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "illness", headerName: "ILLNESS_TITLE", minWidth: 150 },
  { field: "englishIllness", headerName: "ILLNESS_ENG_TITLE", minWidth: 150 },
  {
    field: "",
    headerName: "ILLNESS_STATUS",
    minWidth: 150,
    cellRenderer: (params) =>
      translatePipe.transform(CureStatuses[params.data.isCured ? 0 : 1]),
  },
  { field: "", headerComponent: CustomHeader, headerComponentParams: { data: ['COST'], symbol: 'IQD' }, minWidth: 120, cellRenderer: (params) => pricePipe.transform(params.data.illnessCost) },
  { field: "description", headerName: "DESCRIPTION", minWidth: 200 },
  {
    field: "action",
    headerName: "",
    minWidth: 85,
    pinned: "left",
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["EDIT", "DELETE"] },
  },
];

export const HospitalizationInfoCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "hospital", headerName: "HOSPITAL_NAME", minWidth: 150 },
  { field: "doctor", headerName: "DOCTOR_NAME", minWidth: 150 },
  {
    field: "",
    headerName: "HOSPITALIZATION_DATE",
    minWidth: 120,
    cellRenderer: (params) =>
      datePipe.transform(params.data.hospitalizationStartDate, "YYYY-MM-dd"),
  },
  {
    field: "hospitalizationDays",
    headerName: "HOSPITALIZATION_DAY_COUNT",
    minWidth: 120,
  },
  {
    field: "hospitalizationReason",
    headerName: "HOSPITALIZATION_REASON",
    minWidth: 150,
  },
  {
    field: "",
    headerName: "SURGERIED",
    minWidth: 120,
    cellRenderer: (params) =>
      params.data.hasSurgery
        ? '<i class="bx bx-check fw-bolder"> </i>'
        : '<i class="bx bx-x fw-bolder"> </i>',
  },
  { field: "surgeryReason", headerName: "SURGERY_REASON", minWidth: 150 },
  { field: "description", headerName: "DESCRIPTION", minWidth: 200 },
  {
    field: "action",
    headerName: "",
    minWidth: 85,
    pinned: "left",
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["EDIT", "DELETE"] },
  },
];

export const EducationHistoryCols = [
  { field: '', headerName: '#', pinned: 'right', minWidth: 60 },
  { field: 'educationDegree', headerName: 'DEGREE', minWidth: 150 },
  { field: 'educationGrade', headerName: 'GRADE', minWidth: 150 },
  { field: 'educationStudyField', headerName: 'FIELD_OF_STUDY', minWidth: 150 },
  { field: 'educationPlace', headerName: 'EDUCATION_PLACE_NAME', minWidth: 150 },
  { field: '', headerName: 'STUDY_STATUS', minWidth: 200, cellRenderer: StatusRenderer, cellRendererParams: (params: any) => {
    params.data.statusName = QualityStatuses[params.data.studyStatus];
    switch (params.data.studyStatus) {
      case 0: params.data.theme = 'yellow'; break;
      case 1: params.data.theme = 'red'; break;
      case 2: params.data.theme = 'yellow'; break;
      case 3: params.data.theme = 'blue'; break;
      case 4: params.data.theme = 'green-600'; break;
      case 5: params.data.theme = 'green'; break;
    }}
  },
  { field: '', headerName: 'EDUCATION_START_DATE', minWidth: 150, cellRenderer: (params: any) => datePipe.transform(params.data.startDate, 'YYYY-MM-dd') },
  { field: '', headerName: 'EDUCATION_END_DATE', minWidth: 150, cellRenderer: (params: any) => datePipe.transform(params.data.endDate, 'YYYY-MM-dd') },
  { field: '', headerName: 'EDUCATIONAL_STATUS', minWidth: 150, cellRenderer: (params: any) => translatePipe.transform(EducationStatuses[params.data.studyingStatus]) },
  { field: 'dropoutReasons', headerName: 'DROPOUT_REASON', minWidth: 200 },
  { field: 'average', headerName: 'AVERAGE', minWidth: 120 },
  { field: '', headerName: 'EXEMPT_FROM_EXAM', minWidth: 150,
    cellRenderer: (params: any) => params.data.exemptFromTheExam ? '<i class="bx bx-check fw-bolder text-center d-block"> </i>' : '<i class="bx bx-x fw-bolder text-center d-block"> </i>'
  },
  { field: 'description', headerName: 'DESCRIPTION', minWidth: 200 },
  { field: 'action', headerName: '', pinned: 'left', minWidth: 85, cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ['EDIT', 'SUBMIT_LESSON', 'FISCAL_INFO', 'ATTACH', 'DELETE'] }
  }
];

export const LessonInEducationHistoryCols = [
  { field: '', headerName: '#', pinned: 'right', minWidth: 60 },
  { field: 'lesson', headerName: 'LESSON', minWidth: 150 },
  { field: '', headerName: 'STUDY_STATUS', minWidth: 200, cellRenderer: StatusRenderer, cellRendererParams: (params: any) => {
    params.data.statusName = QualityStatuses[params.data.status];
    switch (params.data.status) {
      case 0: params.data.theme = 'yellow'; break;
      case 1: params.data.theme = 'red'; break;
      case 2: params.data.theme = 'yellow'; break;
      case 3: params.data.theme = 'blue'; break;
      case 4: params.data.theme = 'green-600'; break;
      case 5: params.data.theme = 'green'; break;
    }}
  },
  { field: 'score', headerName: 'AVERAGE', minWidth: 120 },
  { field: '', headerName: 'REINFORCEMENT_CLASS_REQUIRED', minWidth: 150,
    cellRenderer: (params: any) => params.data.needsReinforcementClass ? '<i class="bx bx-check fw-bolder text-center d-block"> </i>' : '<i class="bx bx-x fw-bolder text-center d-block"> </i>'
  },
  { field: '', headerName: 'EXEMPT_FROM_EXAM', minWidth: 150,
    cellRenderer: (params: any) => params.data.exemptFromTheExam ? '<i class="bx bx-check fw-bolder text-center d-block"> </i>' : '<i class="bx bx-x fw-bolder text-center d-block"> </i>'
  },
  { field: 'description', headerName: 'DESCRIPTION', minWidth: 200 },
  { field: 'action', headerName: '', pinned: 'left', minWidth: 85, cellRenderer: DropdownButtonRenderer, cellRendererParams: { dropdownItems: ['EDIT', 'DELETE'] }}
];

export const JobHistoryInfoCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "jobTitle", headerName: "JOB_TITLE", minWidth: 150 },
  {
    field: "",
    headerName: "JOB_START_DATE",
    minWidth: 120,
    cellRenderer: (params) =>
      datePipe.transform(params.data.startDate, "YYYY-MM-dd"),
  },
  {
    field: "",
    headerName: "JOB_END_DATE",
    minWidth: 120,
    cellRenderer: (params) =>
      datePipe.transform(params.data.endDate, "YYYY-MM-dd"),
  },
  {
    field: "",
    headerName: "MONTHLY_INCOME",
    minWidth: 120,
    cellRenderer: (params) =>
      pricePipe.transform(params.data.monthlyIncome),
  },
  { field: "description", headerName: "DESCRIPTION", minWidth: 200 },
  {
    field: "action",
    headerName: "",
    minWidth: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["EDIT", "DELETE"] },
  },
];

export const SkillInfoCols = [
  { field: '', headerName: '#', pinned: 'right', suppressSizeToFit: true, minWidth: 60 },
  { field: 'skill', headerName: 'SKILL', minWidth: 150 },
  { field: '', headerName: 'SKILL_LEVEL', minWidth: 200, cellRenderer: StatusRenderer, cellRendererParams: (params: any) => {
    params.data.statusName = QualityStatuses[params.data.level];
    switch (params.data.level) {
      case 0: params.data.theme = 'yellow'; break;
      case 1: params.data.theme = 'red'; break;
      case 2: params.data.theme = 'yellow'; break;
      case 3: params.data.theme = 'blue'; break;
      case 4: params.data.theme = 'green-600'; break;
      case 5: params.data.theme = 'green'; break;
    }}
  },
  { field: 'skillDate', headerName: 'DATE', minWidth: 130, cellRenderer: DateRenderer },
  { field: 'description', headerName: 'DESCRIPTION', minWidth: 200 },
  { field: 'action', headerName: '', pinned: 'left', suppressSizeToFit: true, minWidth: 85, cellRenderer: DropdownButtonRenderer, cellRendererParams: { dropdownItems: ['EDIT', 'DELETE'] }}
];

export const FamilyAssetCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "assetType", headerName: "ASSET", minWidth: 120 },
  {
    field: "quantity",
    headerName: "COUNT",
    minWidth: 100,
    cellRenderer: (params) =>
      pricePipe.transform(params.data.quantity) + ` (${params.data.unitName})`,
  },
  {
    field: "",
    headerName: "MONTHLY_INCOME_AMOUNT",
    minWidth: 100,
    cellRenderer: (params) => pricePipe.transform(params.data.incomeAmount),
  },
  { field: "date", headerName: "ASSET_DATE", minWidth: 130, cellRenderer: DateRenderer },
  {
    field: "",
    headerName: "ESTIMATED_AMOUNT",
    minWidth: 100,
    cellRenderer: (params) => pricePipe.transform(params.data.approximatePrice),
  },
  { field: "lastUpdate", headerName: "LAST_EDIT_DATE", minWidth: 130, cellRenderer: DateRenderer },
  { field: "description", headerName: "DESCRIPTION", minWidth: 200 },
  {
    field: "",
    headerName: "QUALITY_STATUS",
    pinned: "left",
    minWidth: 200,
    cellRenderer: StatusRenderer,
    cellRendererParams: (params) => {
      params.data.statusName = QualityStatuses2[params.data.qualityStatus];
      switch (params.data.qualityStatus) {
        case 0:
          params.data.theme = "yellow";
          break;
        case 1:
          params.data.theme = "red";
          break;
        case 2:
          params.data.theme = "yellow";
          break;
        case 3:
          params.data.theme = "blue";
          break;
        case 4:
          params.data.theme = "green-600";
          break;
        case 5:
          params.data.theme = "green";
          break;
        case 6:
          params.data.theme = "orange";
          break;
        case 7:
          params.data.theme = "brown";
          break;
      }
    },
  },
  {
    field: "action",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["EDIT", "DELETE"] },
  },
];

export const FamilyIncomeCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  {
    field: "",
    headerName: "DATE",
    minWidth: 120,
    cellRenderer: (params) =>
      datePipe.transform(params.data.incomeDate, "YYYY-MM-dd"),
  },
  {
    field: "",
    headerName: "DONOR_TYPE",
    minWidth: 120,
    cellRenderer: (params) =>
      translatePipe.transform(DonorTypes[params.data.donorType]),
  },
  {
    field: "",
    headerName: "AID_TYPE",
    minWidth: 120,
    cellRenderer: (params) =>
      translatePipe.transform(AidTypes[params.data.aidType]),
  },
  { field: "institute", headerName: "DONATOR", minWidth: 120, cellRenderer: (params) => params.data.institute || params.data.relative },
  {
    field: "",
    headerName: "AMOUNT",
    minWidth: 100,
    cellRenderer: (params) => pricePipe.transform(params.data.incomeAmount),
  },
  { field: "description", headerName: "DESCRIPTION", minWidth: 200 },
  {
    field: "action",
    headerName: "",
    minWidth: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["EDIT", "DELETE"] },
  },
];

export const FamilyCostsCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "costType", headerName: "COST_TYPE", minWidth: 120 },
  { field: "familyMember", headerName: "FAMILY_MEMBER", minWidth: 150 },
  {
    field: "incomeAmount",
    headerName: "AMOUNT",
    minWidth: 100,
    cellRenderer: (params) => pricePipe.transform(params.data.incomeAmount),
  },
  { field: "payer", headerName: "PAYER", minWidth: 150, cellRenderer: (params) =>
      params.data.payer === Payers.ALL_MEMBER ? translatePipe.transform(Payers[params.data.payer]) : params.data.payer_FamilyMember, },
  { field: "description", headerName: "DESCRIPTION", minWidth: 200 },
  {
    field: "action",
    headerName: "",
    minWidth: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["EDIT", "DELETE"] },
  },
];

export const CashNeedRequestCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  {
    field: "costDonationTree",
    headerName: "CASH_AID_ACCOUNT_TREE",
    minWidth: 120,
  },
  {
    field: "cashTree",
    headerName: "CASH_PRODUCT",
    minWidth: 120,
  },
  {
    field: "",
    headerName: "AMOUNT",
    minWidth: 100,
    cellRenderer: (params) => pricePipe.transform(params.data.amount),
  },
  {
    field: "requestDate",
    headerName: "AID_REQUEST_DATE",
    minWidth: 200,
    cellRenderer: (params) =>
      datePipe.transform(params.data.requestDate, "YYYY-MM-dd"),
  },
  { field: "familyMember", headerName: "PERSON", minWidth: 200 },

  { field: "deliveryReportCode", headerName: "DELIVER_CODE", minWidth: 150, type: "link", cellRenderer: LinkRenderer, cellRendererParams: (params) => {
    return {
      url: siteUrl + "/aid/cash/delivery-report-list?deliveryReportCode=" + params.data.deliveryReportCode
    }},
  },
  { field: "", headerName: "DELIVERED_AMOUNT", minWidth: 150, cellRenderer: (params: any) => pricePipe.transform(params.data.deliveryReportItemAmount) },

  { field: "description", headerName: "DESCRIPTION", minWidth: 200 },
  { field: '', headerName: 'STATUS', pinned: 'left', minWidth: 190, cellRenderer: StatusRenderer, cellRendererParams: (params: any) => {
    params.data.statusName = ReceiveStatuses[params.data.deliveryReportItemIdentifier ? 1 : 0];
    params.data.theme = params.data.deliveryReportItemIdentifier ? 'green' : 'red';
  }},
  {
    field: "action",
    headerName: "",
    minWidth: 85,
    pinned: "left",
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["EDIT", "DELETE"] },
  },
];

export const GoodNeedRequestCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  {
    field: "costDonationTree",
    headerName: "OBJECTIVE_AID_ACCOUNT_TREE",
    minWidth: 120,
  },
  { field: "good", headerName: "OBJECTIVE_PRODUCT_AID", minWidth: 120 },
  { field: "quantity", headerName: "QUANTITY", minWidth: 100 },
  {
    field: "requestDate",
    headerName: "AID_REQUEST_DATE",
    minWidth: 200,
    cellRenderer: (params) =>
      datePipe.transform(params.data.requestDate, "YYYY-MM-dd"),
  },
  { field: "familyMember", headerName: "PERSON", minWidth: 200 },

  { field: "deliveryReportCode", headerName: "DELIVER_CODE", minWidth: 150, type: "link", cellRenderer: LinkRenderer, cellRendererParams: (params) => {
    return {
      url: siteUrl + "/aid/goods/delivery-list?deliveryReportCode=" + params.data.deliveryReportCode
    }},
  },
  { field: "deliveryReportItemCount", headerName: "DELIVERED_COUNT", minWidth: 150 },

  { field: "description", headerName: "DESCRIPTION", minWidth: 200 },
  { field: '', headerName: 'STATUS', pinned: 'left', minWidth: 190, cellRenderer: StatusRenderer, cellRendererParams: (params: any) => {
    params.data.statusName = ReceiveStatuses[params.data.deliveryReportItemIdentifier ? 1 : 0];
    params.data.theme = params.data.deliveryReportItemIdentifier ? 'green' : 'red';
  }},
  {
    field: "action",
    headerName: "",
    minWidth: 85,
    pinned: "left",
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["EDIT", "DELETE"] },
  },
];

export const ServiceNeedRequestCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "costDonationTree", headerName: "SERVICE_AID_NAME", minWidth: 120 },
  { field: "service", headerName: "SERVICE", minWidth: 120 },
  { field: "quantity", headerName: "COUNT", minWidth: 100 },
  {
    field: "requestDate",
    headerName: "AID_REQUEST_DATE",
    minWidth: 200,
    cellRenderer: (params) =>
      datePipe.transform(params.data.requestDate, "YYYY-MM-dd"),
  },
  { field: "familyMember", headerName: "PERSON", minWidth: 200 },
  { field: "description", headerName: "DESCRIPTION", minWidth: 200 },
  {
    field: "action",
    headerName: "",
    minWidth: 85,
    pinned: "left",
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["EDIT", "DELETE"] },
  },
];

export const familyMemberAttachCols = [
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  { field: "code", headerName: "MEMBER_CODE", minWidth: 200 },
  { field: "fullName", headerName: "PERSON_NAME", minWidth: 150,
    cellRenderer: (params: any) => params.data.fullName || (params.data.firstName + ' ' + params.data.lastName)
  },
  { field: "birthDate", headerName: "BIRTH_DATE", minWidth: 180, cellRenderer: DateRenderer },
  { field: "", headerName: "", pinned: "left", minWidth: 85, suppressSizeToFit: true, cellRenderer: ButtonRenderer, cellRendererParams: {
    type: "fill", color: "blue", text: "ATTACH", action: "attach" }
  }
]

export const CashReceiptAuditCols = [
  {
    field: "action",
    headerName: "",
    minWidth: 100,
    pinned: "right",
    cellRenderer: ButtonRenderer,
    cellRendererParams: {
      type: "fill",
      color: "danger",
      icon: "add",
      text: "RETURN",
      action: "delete",
    },
    // cellRenderer: CheckboxRenderer,
    // cellRendererParams: (params) => {
    //   if (params.data.length) {
    //     return { disabled: true };
    //   }
    // },
  },
  { field: "", headerName: "#", minWidth: 60, pinned: "right" },
  {
    field: "paperReceiptNumber",
    headerName: "RECEIPT_PAPER_NUMBER",
    minWidth: 140,
    pinned: "right",
  },
  {
    field: "receiptDate",
    headerName: "RECEIPT_PAPER_DATE",
    minWidth: 130,
    cellRenderer: (params) =>
      datePipe.transform(params.data.receiptDate, "YYYY-MM-dd"),
  },
  { field: "receiptCode", headerName: "RECEIPT_CODE", minWidth: 130 },
  {
    field: "receiptType",
    headerName: "RECEIVE_TYPE",
    minWidth: 120,
    cellRenderer: (params) =>
      translatePipe.transform(ReceiptAndPaymentTypes[params.data.receiptType]),
  },
  {
    field: "amount",
    headerName: "AMOUNT",
    minWidth: 100,
    cellRenderer: (params) => pricePipe.transform(params.data.amount),
  },
  /* {
    field: "tatviriAmount",
    headerName: "TATVIRI_AMOUNT",
    minWidth: 120,
    cellRenderer: (params) => pricePipe.transform(params.data.tatviriAmount),
  },
  {
    field: "totalAmount",
    headerName: "TOTAL_AMOUNT",
    minWidth: 120,
    cellRenderer: (params) => pricePipe.transform(params.data.totalAmount),
  }, */
  { field: "description", headerName: "RECEIVE_DESCRIPTION", minWidth: 150 },
  {
    field: "donationRequestCode",
    headerName: "CASH_DONATION_RECEIPT_CODE",
    minWidth: 150,
  },
  {
    field: "donationTree",
    headerName: "INCOME",
    minWidth: 120,
    editable: true,
  },
  { field: "cashTree", headerName: "CASH_TREE_FIELD", minWidth: 150, cellRenderer: (params) => params.data.cashTree || params.data.product || params.data.service },
  { field: "beneficiaryType", headerName: "BENEFICIARY_TYPE", minWidth: 150 },
  {
    field: "donationRequestDate",
    headerName: "DONATION_DATE",
    minWidth: 130,
    cellRenderer: (params) =>
      datePipe.transform(params.data.donationRequestDate, "YYYY-MM-dd"),
  },
  { field: "donor", headerName: "DONOR", minWidth: 120 },
  { field: "donorCode", headerName: "DONOR_CODE", minWidth: 150 },
  { field: "campaign", headerName: "CAMPAIGN", minWidth: 110 },
  {
    field: "createDate",
    headerName: "SUBMIT_RECEIVE_DATE",
    minWidth: 130,
    cellRenderer: (params) =>
      datePipe.transform(params.data.createDate, "YYYY-MM-dd"),
  },
  {
    field: "accountTree",
    headerName: "INCOME_ACCOUNT",
    minWidth: 250,
    cellRenderer: DropdownTreeRenderer,
    pinned: "left",
  },
];

export const BoxListCols = [
  { field: "#", headerName: "#", pinned: "right", minWidth: 60 },
  {
    field: "check",
    headerName: "SELECT",
    pinned: "right",
    minWidth: 80,
    cellRenderer: CheckboxRenderer,
  },
  { field: "code", headerName: "CODE", minWidth: 100 },
  { field: "commodity", headerName: "COMMODITY", minWidth: 150 },
  { field: "size", headerName: "SIZE", minWidth: 100 },
  { field: "branch", headerName: "BRANCH", minWidth: 150 },
  {
    field: "",
    headerName: "CREATE_DATE",
    minWidth: 120,
    cellRenderer: (params) =>
      datePipe.transform(params.data.createDate, "YYYY-MM-dd"),
  },
  { field: "agent", headerName: "AGENT", minWidth: 150 },
  { field: "locationName", headerName: "LOCATION_NAME", minWidth: 150 },
  { field: "locationType", headerName: "LOCATION_TYPE", minWidth: 150 },
  { field: "receiver", headerName: "RECEIVER", minWidth: 150 },
  { field: "phone", headerName: "PHONE", minWidth: 120 },
  { field: "geoName", headerName: "GEO_NAME", minWidth: 150 },
  {
    field: "",
    headerName: "LAST_COLLECTION_DATE",
    minWidth: 120,
    cellRenderer: (params) =>
      datePipe.transform(params.data.lastCollectionDate, "YYYY-MM-dd"),
  },
  {
    field: "status",
    headerName: "STATUS",
    pinned: "left",
    minWidth: 180,
    cellRenderer: StatusRenderer,
    cellRendererParams: (params) => {
      params.data.statusName = BoxStatuses[params.data.status];
      switch (params.data.status) {
        case 0:
          params.data.theme = "yellow";
          break;
        case 1:
          params.data.theme = "orange";
          break;
        case 2:
          params.data.theme = "green";
          break;
        case 3:
          params.data.theme = "red";
          break;
        case 4:
          params.data.theme = "pink";
          break;
        default:
          break;
      }
    },
  },
  {
    field: "action",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["DELETE"] },
  },
];

export const BoxDeliveryReceiptCols = [
  { field: "#", headerName: "#", pinned: "right", minWidth: 60 },
  { field: "", headerName: "BOX_DELIVERY_RECEIPT_CODE", minWidth: 150 },
  { field: "", headerName: "DATE", minWidth: 150 },
  { field: "", headerName: "BRANCH", minWidth: 150 },
  { field: "", headerName: "AGENT", minWidth: 150 },
  { field: "", headerName: "RECEIVER", minWidth: 150 },
  { field: "", headerName: "TOTAL_AMOUNT_AR", minWidth: 150 },
  {
    field: "action",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["EDIT", "DELETE"] },
  },
];

export const BoxDeliveryReceiptRegisterCols = [
  { field: "#", headerName: "#", pinned: "right", minWidth: 60 },
  { field: "", headerName: "RECEIPT_PAPER_NUMBER", minWidth: 150 },
  { field: "", headerName: "COLLECTION_DATE", minWidth: 150 },
  { field: "", headerName: "PERIOD_CODE", minWidth: 150 },
  { field: "", headerName: "COLLECTION_RECEIPT_CODE", minWidth: 150 },
  { field: "", headerName: "PERIOD_TITLE", minWidth: 150 },
  { field: "", headerName: "LOCATION_TITLE", minWidth: 150 },
  { field: "", headerName: "RECEIVER", minWidth: 150 },
  { field: "", headerName: "BOX_CODE", minWidth: 150 },
  { field: "", headerName: "USE_CASE", minWidth: 150 },
  { field: "", headerName: "BENEFICIARY_TYPE", minWidth: 150 },
  { field: "", headerName: "TOTAL_AMOUNT_AR", minWidth: 150 },
  { field: "", headerName: "DESCRIPTION", minWidth: 150 },
  {
    field: "action",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["VIEW"] },
  },
];

export const BoxDeliveryReceiptConfirmCols = [
  { field: "#", headerName: "#", pinned: "right", minWidth: 60 },
  { field: "", headerName: "RECEIPT_PAPER_NUMBER", minWidth: 150 },
  { field: "", headerName: "COLLECTION_DATE", minWidth: 150 },
  { field: "", headerName: "PERIOD_CODE", minWidth: 150 },
  { field: "", headerName: "COLLECTION_RECEIPT_CODE", minWidth: 150 },
  { field: "", headerName: "PERIOD_TITLE", minWidth: 150 },
  { field: "", headerName: "LOCATION_TITLE", minWidth: 150 },
  { field: "", headerName: "RECEIVER", minWidth: 150 },
  { field: "", headerName: "BOX_CODE", minWidth: 150 },
  { field: "", headerName: "USE_CASE", minWidth: 150 },
  { field: "", headerName: "BENEFICIARY_TYPE", minWidth: 150 },
  { field: "", headerName: "FAMILY", minWidth: 150 },
  { field: "", headerName: "FOR", minWidth: 150 },
  { field: "", headerName: "TOTAL_AMOUNT_AR", minWidth: 150 },
  { field: "", headerName: "DESCRIPTION", minWidth: 150 },
  { field: "", headerName: "INCOME_ACCOUNT", minWidth: 150 },
  // {
  //   field: "action",
  //   headerName: "",
  //   pinned: "left",
  //   minWidth: 85,
  //   cellRenderer: DropdownButtonRenderer,
  //   cellRendererParams: { dropdownItems: ["VIEW"] },
  // },
];

export const BoxDeliveryReceiptInfoCols = [
  { field: "#", headerName: "#", pinned: "right", minWidth: 60 },
  { field: "", headerName: "CURRENCY_TYPE", minWidth: 200 },
  { field: "", headerName: "BANKNOTE", minWidth: 200 },
  { field: "", headerName: "COUNT", minWidth: 150 },
  { field: "", headerName: "AMOUNT_AR", minWidth: 150 },
];

export const LocationListCols = [
  { field: "#", headerName: "#", pinned: "right", minWidth: 60 },
  { field: "locationName", headerName: "LOCATION_NAME", minWidth: 150 },
  { field: "locationCode", headerName: "LOCATION_CODE", minWidth: 100 },
  { field: "locationType", headerName: "LOCATION_TYPE", minWidth: 150 },
  { field: "exchangeLocation", headerName: "EXCHANGE_LOCATION", minWidth: 150 },
  { field: "receiver", headerName: "RECEIVER", minWidth: 150 },
  { field: "phone", headerName: "PHONE", minWidth: 120 },
  { field: "activeBoxQuantity", headerName: "ACTIVE_BOX_COUNT", minWidth: 100 },
  {
    field: "",
    headerName: "LAST_COLLECTION_DATE",
    minWidth: 120,
    cellRenderer: (params) =>
      datePipe.transform(params.data.lastCollectionDate, "YYYY-MM-dd"),
  },
  { field: "space", headerName: "SPACE", minWidth: 100 },
  {
    field: "",
    headerName: "HAS_OTHER_BOX",
    minWidth: 120,
    cellRenderer: (params) =>
      params.data.hasOtherBox
        ? '<i class="bx bx-check fw-bolder"> </i>'
        : '<i class="bx bx-x fw-bolder"> </i>',
  },
  { field: "institution", headerName: "INSTITUTION", minWidth: 150 },
  { field: "geoName", headerName: "GEO_NAME", minWidth: 150 },
  {
    field: "status",
    headerName: "STATUS",
    pinned: "left",
    minWidth: 180,
    cellRenderer: StatusRenderer,
    cellRendererParams: (params) => {
      params.data.statusName = ActiveStatuses[params.data.status];
      switch (params.data.status) {
        case 0:
          params.data.theme = "red";
          break;
        case 1:
          params.data.theme = "green";
          break;
        default:
          break;
      }
    },
  },
  {
    field: "action",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["EDIT", "DELETE"] },
  },
];

export const BoxesCols = [
  { field: "#", headerName: "#", pinned: "right", minWidth: 60 },
  {
    field: "check",
    headerName: "SELECT",
    pinned: "right",
    minWidth: 80,
    cellRenderer: CheckboxRenderer,
  },
  { field: "code", headerName: "CODE", minWidth: 100 },
  { field: "commodity", headerName: "COMMODITY", minWidth: 150 },
  { field: "size", headerName: "SIZE", minWidth: 100 },
  {
    field: "",
    headerName: "CREATE_DATE",
    minWidth: 120,
    cellRenderer: (params) =>
      datePipe.transform(params.data.createDate, "YYYY-MM-dd"),
  },
  { field: "", headerName: "EXCHANGE_LOCATION", minWidth: 150 },
  { field: "agent", headerName: "AGENT", minWidth: 150 },
  { field: "locationName", headerName: "LOCATION_NAME", minWidth: 150 },
  { field: "", headerName: "USE_CASE", minWidth: 150 },
  { field: "", headerName: "BEBFICIARY_TYPE", minWidth: 150 },
  { field: "", headerName: "FAMILY_CODE", minWidth: 120 },
  { field: "", headerName: "SUPERVISOR", minWidth: 150 },
  { field: "", headerName: "FAMILY_MEMBER", minWidth: 120 },
  {
    field: "",
    headerName: "LAST_COLLECTION_DATE",
    minWidth: 120,
    cellRenderer: (params) =>
      datePipe.transform(params.data.lastCollectionDate, "YYYY-MM-dd"),
  },
  {
    field: "status",
    headerName: "STATUS",
    pinned: "left",
    minWidth: 180,
    cellRenderer: StatusRenderer,
    cellRendererParams: (params) => {
      params.data.statusName = BoxStatuses[params.data.status];
      switch (params.data.status) {
        case 0:
          params.data.theme = "yellow";
          break;
        case 1:
          params.data.theme = "orange";
          break;
        case 2:
          params.data.theme = "green";
          break;
        case 3:
          params.data.theme = "red";
          break;
        case 4:
          params.data.theme = "pink";
          break;
        default:
          break;
      }
    },
  },
  {
    field: "action",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["COLLECT", "HISTORY"] },
  },
];

export const HistoryCols = [
  { field: "#", headerName: "#", pinned: "right", minWidth: 60 },
  { field: "", headerName: "COLLECTOR", minWidth: 100 },
  { field: "", headerName: "COLLECTION_DATE", minWidth: 150 },
  { field: "", headerName: "AMOUNT", minWidth: 100 },
  { field: "", headerName: "BOX_STATUS", minWidth: 100 },
  { field: "", headerName: "COLLECTION_STATUS", minWidth: 100 },
  { field: "", headerName: "EXCHANGE_LOCATION", minWidth: 100 },
];

export const CollectionPeriodsListCols = [
  { field: "#", headerName: "#", pinned: "right", minWidth: 60 },
  { field: "", headerName: "BRANCH", minWidth: 150 },
  { field: "", headerName: "PERIOD_TITLE", minWidth: 150 },
  { field: "", headerName: "PERIOD_CODE", minWidth: 120 },
  { field: "", headerName: "AGENT", minWidth: 150 },
  {
    field: "",
    headerName: "START_DATE",
    minWidth: 120,
    cellRenderer: (params) => datePipe.transform(params.data.x, "YYYY-MM-dd"),
  },
  {
    field: "",
    headerName: "END_DATE",
    minWidth: 120,
    cellRenderer: (params) => datePipe.transform(params.data.x, "YYYY-MM-dd"),
  },
  { field: "", headerName: "LOCATION_COUNT", minWidth: 100 },
  { field: "", headerName: "BOX_COUNT", minWidth: 100 },
  { field: "", headerName: "COLLECTED_BOXES_COUNT", minWidth: 100 },
  { field: "", headerName: "EMPTY_BOXES_COUNT", minWidth: 100 },
  { field: "", headerName: "RETURNED_BOXES_COUNT", minWidth: 100 },
  { field: "", headerName: "REMAINING_BOXES_COUNT", minWidth: 100 },
  {
    field: "",
    headerName: "TOTAL_AMOUNT",
    minWidth: 100,
    cellRenderer: (params) => pricePipe.transform(params.data.x),
  },
  {
    field: "",
    headerName: "DELIVERED_AMOUNT",
    minWidth: 100,
    cellRenderer: (params) => pricePipe.transform(params.data.x),
  },
  {
    field: "status",
    headerName: "STATUS",
    pinned: "left",
    minWidth: 180,
    cellRenderer: StatusRenderer,
    cellRendererParams: (params) => {
      params.data.statusName = CollectionPeriodsStatuses[params.data.status];
      switch (params.data.status) {
        case 0:
          params.data.theme = "yellow";
          break;
        case 1:
          params.data.theme = "orange";
          break;
        case 2:
          params.data.theme = "green";
          break;
        case 3:
          params.data.theme = "pink";
          break;
        case 4:
          params.data.theme = "blue";
          break;
        default:
          break;
      }
    },
  },
  {
    field: "action",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: {
      dropdownItems: ["VIEW", "EDIT", "COMPLETION_OF_COLLECTION", "DELETE"],
    },
  },
];

export const CollectionLocationListCols = [
  { field: "#", headerName: "#", pinned: "right", minWidth: 60 },
  { field: "", headerName: "PERIOD_CODE", minWidth: 120 },
  { field: "", headerName: "PERIOD_TITLE", minWidth: 150 },
  { field: "", headerName: "AGENT", minWidth: 150 },
  {
    field: "",
    headerName: "PERIOD_START_DATE",
    minWidth: 120,
    cellRenderer: (params) => datePipe.transform(params.data.x, "YYYY-MM-dd"),
  },
  {
    field: "",
    headerName: "PERIOD_END_DATE",
    minWidth: 120,
    cellRenderer: (params) => datePipe.transform(params.data.x, "YYYY-MM-dd"),
  },
  { field: "", headerName: "LOCATION_NAME", minWidth: 100 },
  { field: "", headerName: "GEO_NAME", minWidth: 100 },
  { field: "", headerName: "ADDRESS", minWidth: 100 },
  { field: "", headerName: "RECEIVER", minWidth: 100 },
  { field: "", headerName: "PHONE", minWidth: 100 },
  { field: "", headerName: "BOX_COUNT", minWidth: 100 },
  { field: "", headerName: "COLLECTED_BOXES_COUNT", minWidth: 100 },
  { field: "", headerName: "EMPTY_BOXES_COUNT", minWidth: 100 },
  { field: "", headerName: "RETURNED_BOXES_COUNT", minWidth: 100 },
  { field: "", headerName: "REMAINING_BOXES_COUNT", minWidth: 100 },
  {
    field: "status",
    headerName: "STATUS",
    pinned: "left",
    minWidth: 180,
    cellRenderer: StatusRenderer,
    cellRendererParams: (params) => {
      params.data.statusName = CollectionLocationsStatuses[params.data.status];
      switch (params.data.status) {
        case 0:
          params.data.theme = "yellow";
          break;
        case 1:
          params.data.theme = "orange";
          break;
        case 2:
          params.data.theme = "green";
          break;
        case 3:
          params.data.theme = "pink";
          break;
        case 4:
          params.data.theme = "blue";
          break;
        default:
          break;
      }
    },
  },
  {
    field: "action",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: { dropdownItems: ["COLLECT_BOXES", "RETURN"] },
  },
];

export const CollectBoxesInPlaceCols = [
  { field: "#", headerName: "#", pinned: "right", minWidth: 60 },
  { field: "code", headerName: "CODE", minWidth: 120 },
  { field: "commodity", headerName: "COMMODITY", minWidth: 150 },
  { field: "size", headerName: "SIZE", minWidth: 150 },
  { field: "", headerName: "CREATE_DATE", minWidth: 150 },
  { field: "exchangeLocation", headerName: "EXCHANGE_LOCATION", minWidth: 150 },
  { field: "transporter", headerName: "TRANSPORTER", minWidth: 150 },
  { field: "location", headerName: "LOCATION", minWidth: 150 },
  { field: "useCase", headerName: "USE_CASE", minWidth: 150 },
  { field: "beneficiaryType", headerName: "BENEFICIARY_TYPE", minWidth: 150 },
  { field: "", headerName: "LAST_COLLECTION_DATE", minWidth: 150 },
  {
    field: "",
    headerName: "TOTAL_AMOUNT_AR",
    minWidth: 150,
    cellRenderer: (params) => pricePipe.transform(params.data.totalAmount),
  },
  { field: "", headerName: "STATUS", minWidth: 150 },
];

export const CollectionPeriod0Cols = [
  {
    field: "check",
    headerName: "SELECT",
    pinned: "right",
    minWidth: 100,
    cellRenderer: CheckboxRenderer,
    cellRendererParams: { disabled: true },
  },
  { field: "", headerName: "#", pinned: "right", minWidth: 60 },
  { field: "", headerName: "LOCATION_NAME", minWidth: 150 },
  { field: "", headerName: "RECEIVER", minWidth: 150 },
  { field: "", headerName: "PHONE", minWidth: 120 },
  {
    field: "",
    headerName: "LAST_COLLECTION_DATE",
    minWidth: 120,
    cellRenderer: (params) => datePipe.transform(params.data.x, "YYYY-MM-dd"),
  },
  { field: "", headerName: "BOX_COUNT", minWidth: 100 },
  { field: "", headerName: "GEO_NAME", minWidth: 150 },
  { field: "", headerName: "ADDRESS", minWidth: 200 },
];

export const CollectionPeriod1Cols = [
  {
    field: "check",
    headerName: "RETURN",
    pinned: "right",
    minWidth: 100,
    cellRenderer: CheckboxRenderer,
  },
  { field: "", headerName: "#", pinned: "right", minWidth: 60 },
  { field: "", headerName: "LOCATION_NAME", minWidth: 150 },
  { field: "", headerName: "RECEIVER", minWidth: 150 },
  { field: "", headerName: "PHONE", minWidth: 120 },
  {
    field: "",
    headerName: "LAST_COLLECTION_DATE",
    minWidth: 120,
    cellRenderer: (params) => datePipe.transform(params.data.x, "YYYY-MM-dd"),
  },
  { field: "", headerName: "BOX_COUNT", minWidth: 100 },
  { field: "", headerName: "GEO_NAME", minWidth: 150 },
  { field: "", headerName: "ADDRESS", minWidth: 200 },
];

export const TransferListCols = [
  { field: "", headerName: "#", pinned: "right", minWidth: 60 },
  { field: "code", headerName: "TRANSFER_CODE", minWidth: 180 },
  { field: "originBranch", headerName: "ORIGIN_BRANCH", minWidth: 180 },
  {
    field: "destinationBranch",
    headerName: "DESTINATION_BRANCH",
    minWidth: 180,
  },
  {
    field: "",
    headerName: "DATE",
    minWidth: 120,
    cellRenderer: (params) =>
      datePipe.transform(params.data.requestDate, "YYYY-MM-dd"),
  },
  { field: "transfereeUser", headerName: "TRANSPORTER", minWidth: 150 },
  { field: "deliveryUser", headerName: "RECEIVER_CASHIER", minWidth: 150 },
  {
    field: "",
    headerName: "STATUS",
    pinned: "left",
    minWidth: 190,
    cellRenderer: StatusRenderer,
    cellRendererParams: (params) => {
      params.data.statusName = TransferStatus[params.data.lastStatus];
      switch (params.data.lastStatus) {
        case 1:
          params.data.theme = "yellow";
          break;
        case 2:
          params.data.theme = "green";
          break;
        default:
          break;
      }
    },
  },
  {
    field: "action",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: (params) => {
      let dropdownItems = [];
      switch (params.data.lastStatus) {
        case 1:
          dropdownItems = ["VIEW", "EDIT", "DELETE"];
          break;
        case 2:
          dropdownItems = ["VIEW"];
          break;
        default:
          break;
      }
      return { dropdownItems: dropdownItems };
    },
  },
];

export const BuyOrderListCols = [
  { field: "", headerName: "#", pinned: "right", minWidth: 60 },
  { field: "code", headerName: "ORDER_CODE", minWidth: 150 },
  { field: "branch", headerName: "BRANCH", minWidth: 150 },
  {
    field: "",
    headerName: "ORDER_OWNER",
    minWidth: 120,
    cellRenderer: (params) => params.data.status?.logs[0]?.user,
  },
  {
    field: "",
    headerName: "DATE",
    minWidth: 120,
    cellRenderer: (params) =>
      datePipe.transform(params.data.requestDate, "YYYY-MM-dd"),
  },
  {
    field: "purchasingOfficer",
    headerName: "SUGGESTED_ORDER_AGENT",
    minWidth: 100,
  },
  {
    field: "",
    headerName: "BUY_DATE",
    minWidth: 120,
    cellRenderer: (params) =>
      datePipe.transform(params.data?.factorDate, "YYYY-MM-dd"),
  },
  {
    field: "factorManualSheetNumber",
    headerName: "BUY_PAPER_NUMBER",
    minWidth: 100,
  },
  {
    field: "currentPurchasingOfficer",
    headerName: "ORDER_AGENT",
    minWidth: 100,
  },
  {
    field: "",
    headerName: "WAREHOUSE_ENTER_DATE",
    minWidth: 100,
    cellRenderer: (params) =>
      datePipe.transform(params.data?.warehouseEnterDate, "YYYY-MM-dd"),
  },
  { field: "transfereeAgent", headerName: "DELIVERY", minWidth: 100 },
  {
    field: "",
    headerName: "NECESSITY",
    pinned: "left",
    minWidth: 100,
    cellRenderer: (params) =>
      translatePipe.transform(NecessityTypes[params.data.necessityType]),
  },
  {
    field: "",
    headerName: "STATUS",
    pinned: "left",
    minWidth: 190,
    cellRenderer: StatusRenderer,
    cellRendererParams: (params) => {
      params.data.statusName = GoodsBuyingRequestStatus[params.data.lastStatus];
      switch (params.data.lastStatus) {
        case 1:
          params.data.theme = "yellow";
          break;
        case 2:
          params.data.theme = "yellow-pink";
          break;
        case 3:
          params.data.theme = "red";
          break;
        case 4:
          params.data.theme = "orange";
          break;
        case 5:
          params.data.theme = "green";
          break;
      }
    },
  },
  {
    field: "action",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: (params) => {
      const dropdownItems = ["VIEW"];
      switch (params.data.lastStatus) {
        case 1:
          dropdownItems.push("EDIT", "BUY", "DELETE");
          break;

        case 2:
          dropdownItems.push("BUY", "DELETE");
          break;

        case 4:
          dropdownItems.push("WAREHOUSE_RECEIPT");
          break;

        default:
          break;
      }
      return { dropdownItems };
    },
  },
];

export const BuyOrderCols = [
  { field: "", headerName: "#", pinned: "right", minWidth: 60 },
  { field: "product", headerName: "GOOD", minWidth: 150 },
  { field: "productCode", headerName: "GOOD_CODE", minWidth: 150 },
  { field: "count", headerName: "REQUESTED_COUNT", minWidth: 120 },
  { field: "unit", headerName: "UNIT", minWidth: 120 },
  { field: "donationTree", headerName: "PRODUCTION_PROJECT", minWidth: 120 },
  { field: "beneficiaryType", headerName: "BENEFICIARY_TYPE", minWidth: 120 },
  { field: "description", headerName: "DESCRIPTION", minWidth: 120 },
  {
    field: "action",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    type: "button",
    cellRenderer: ButtonRenderer,
    cellRendererParams: {
      text: "DELETE",
      color: "danger",
      type: "fill",
      action: "delete",
    },
  },
];

export const BuyFactorCols = [
  { field: "#", headerName: "#", pinned: "right", minWidth: 60 },
  { field: "donationTree", headerName: "PRODUCTION_PROJECT", minWidth: 150 },
  { field: "beneficiaryType", headerName: "BENEFICIARY_TYPE", minWidth: 120 },
  { field: "product", headerName: "GOOD", minWidth: 150 },
  { field: "description", headerName: "DESCRIPTION", minWidth: 120 },
  {
    field: "count",
    headerName: "REQUESTED_COUNT",
    minWidth: 120,
    cellRenderer: (params) =>
      params.data.count ? params.data.count + " " + params.data.unit : null,
  },
  {
    field: "",
    headerName: "BOUGHT_COUNT",
    minWidth: 120,
    cellRenderer: (params) =>
      params.data.quantityPurchased
        ? params.data.quantityPurchased + " " + params.data.unit
        : null,
  },
  { field: "reasonTitle", headerName: "REJECT_BUY_REASON", minWidth: 150 },
  {
    field: "reasonDescription",
    headerName: "REJECT_BUY_DESCRIPTION",
    minWidth: 200,
  },
  {
    field: "action",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    type: "dropdown",
    cellRendererParams: {
      dropdownItems: ["NEW_GOOD", "INCOMPLETE_BUY_REASON"],
    },
  },
];

export const BuyFactorSubCols = [
  { field: "#", headerName: "#", pinned: "right", minWidth: 60 },
  { field: "productCode", headerName: "GOOD_CODE", minWidth: 150 },
  { field: "product", headerName: "GOOD", minWidth: 180 },
  { field: "count", headerName: "COUNT", minWidth: 100 },
  { field: "unit", headerName: "UNIT", minWidth: 100 },
  { field: "", headerName: "AMOUNT", minWidth: 100, cellRenderer: (params) => pricePipe.transform(params.data.amount) },
  {
    field: "",
    headerName: "TOTAL_AMOUNT",
    minWidth: 100,
    cellRenderer: (params) =>
      pricePipe.transform(params.data.amount * params.data.count),
  },
  {
    field: "action",
    headerName: "",
    pinned: "left",
    type: "dropdown",
    minWidth: 85,
    cellRendererParams: { dropdownItems: ["EDIT", "DELETE"] },
  },
];

export const WarehousingTransferGoodCols = [
  { field: "", headerName: "#", pinned: "right", minWidth: 60 },
  { field: "serialNumber", headerName: "SOURCE_SERIAL_NUMBER", minWidth: 180 },
  {
    field: "destinationSerialNumber",
    headerName: "DESTINATION_SERIAL_NUMBER",
    minWidth: 180,
  },
  { field: "productCode", headerName: "GOOD_CODE", minWidth: 180 },
  { field: "product", headerName: "GOOD", minWidth: 150 },
  { field: "donationTree", headerName: "INCOME", minWidth: 150 },
  { field: "beneficiaryType", headerName: "BENEFICIARY_TYPE", minWidth: 120 },
  { field: "qty", headerName: "COUNT", minWidth: 120 },
  {
    field: "action",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    type: "button",
    cellRenderer: ButtonRenderer,
    cellRendererParams: {
      type: "fill",
      color: "danger",
      text: "DELETE",
      action: "delete",
    },
  },
];

export const SelectGoodCols = [
  { field: "", headerName: "#", pinned: "right", minWidth: 60 },
  { field: "serialNumber", headerName: "GOOD_SERIAL_NUMBER", minWidth: 180 },
  { field: "donationTree", headerName: "PROJECT_TREE", minWidth: 150 },
  { field: "product", headerName: "GOOD", minWidth: 100 },
  { field: "beneficiaryType", headerName: "BENEFICIARY_TYPE", minWidth: 150 },
  {
    field: "",
    headerName: "INVENTORY",
    minWidth: 100,
    cellRenderer: (params) => params.data.qty + " " + params.data.unitTitle,
  },
  {
    field: "entriesIsBlocked",
    headerName: "ENTRIES_IS_BLOCKED",
    minWidth: 120
  },
  {
    field: "exitsIsBlocked",
    headerName: "EXITS_IS_BLOCKED",
    minWidth: 120
  },
  {
    field: "",
    headerName: "AMOUNT",
    minWidth: 120,
    cellRenderer: (params) => pricePipe.transform(params.data.amount),
  },
  {
    field: "count",
    headerName: "COUNT",
    pinned: "left",
    minWidth: 85,
    editable: (params) => params.data.qty,
    valueParser: (params) => {
      return params.newValue ? +params.newValue : params.newValue;
    },
    cellClass: (params) => {
      return params.data.qty ? "input-grid" : "input-grid disabled";
    },
  },
  {
    field: "action",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    type: "button",
    cellRenderer: ButtonRenderer,
    cellRendererParams: (params) => {
      return {
        type: "fill",
        color: "blue",
        text: "SELECT",
        action: "select",
        disabled: !params.data.qty,
      };
    },
  },
];

export const UserListCols = [
  { field: "", headerName: "#", pinned: "right", minWidth: 60 },
  { field: "personnelCode", headerName: "CODE", minWidth: 150 },
  { field: "fullName", headerName: "FULL_NAME", minWidth: 150 },
  {
    field: "organizationalPosition",
    headerName: "ORGANIZATIONAL_POSTS",
    minWidth: 200,
  },
  { field: "branch", headerName: "BRANCH", minWidth: 150 },
  { field: "department", headerName: "DEPARTMENT", minWidth: 150 },
  {
    field: "",
    headerName: "CREATE_DATE",
    minWidth: 120,
    cellRenderer: (params) =>
      datePipe.transform(params.data.createDate, "YYYY-MM-dd"),
  },
  {
    field: "",
    headerName: "GENDER",
    minWidth: 120,
    cellRenderer: (params) =>
      translatePipe.transform(Gender[params.data.gender]),
  },
  {
    field: "",
    headerName: "ANCESTRY",
    minWidth: 120,
    cellRenderer: (params) =>
      translatePipe.transform(Ancestry[params.data.ancestry]),
  },
  // { field: "job", headerName: "JOB", minWidth: 150 },
  { field: "phone", headerName: "PHONE", minWidth: 150 },
  {
    field: "",
    headerName: "STATUS",
    pinned: "left",
    minWidth: 100,
    cellRenderer: StatusRenderer,
    cellRendererParams: (params) => {
      params.data.statusName = ActiveStatuses[params.data.isActive ? 1 : 0];
      params.data.theme = params.data.isActive ? "green" : "red";
    },
  },
  {
    field: "action",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    type: "button",
    cellRenderer: ButtonRenderer,
    cellRendererParams: {
      type: "fill",
      color: "blue",
      icon: "pencil",
      text: "EDIT",
      action: "edit",
    },
  },
];

export const ReturnCashReceiptAuditConfirmCols = [
  {
    field: "action",
    headerName: "",
    pinned: "right",
    minWidth: 100,
    cellRenderer: ButtonRenderer,
    cellRendererParams: {
      type: "fill",
      color: "danger",
      icon: "add",
      text: "RETURN",
      action: "delete",
    },
  },
  { field: "", headerName: "#", pinned: "right", minWidth: 60 },
  {
    field: "deliveryToCashierCode",
    headerName: "CASH_DELIVERY_CODE",
    minWidth: 130,
    type: 'link',
    cellRenderer: LinkRenderer,
    cellRendererParams: (params: any) => { return { url: siteUrl + '/donation/cash/cash-receipt-view/' + params.data.deliveryToCashierCode }}
  },
  {
    field: "",
    headerName: "RECEIVE_DATE",
    minWidth: 130,
    cellRenderer: (params: any) =>
      datePipe.transform(params.data.receiptDate, "YYYY-MM-dd"),
  },
  { field: "receiptCode", headerName: "RECEIPT_CODE", minWidth: 130 },
  {
    field: "",
    headerName: "AMOUNT",
    minWidth: 100,
    cellRenderer: (params: any) => pricePipe.transform(params.data.amountIQD),
  },
  { field: "accountTree", headerName: "INCOME_ACCOUNT", minWidth: 300 },
  { field: "reason", headerName: "RETURN_REASON", minWidth: 200 },
];

export const ConfirmGoodsNeedsOfFamily2DetailCols = [
  {
    field: "",
    headerName: "#",
    pinned: "right",
    minWidth: 60,
    suppressSizeToFit: true,
  },
  { field: "accountTree", headerName: "CASH_BALANCE", minWidth: 200 },
  {
    field: "",
    headerName: "INVENTORY",
    minWidth: 100,
    cellRenderer: (params: any) => pricePipe.transform(params.data.balance),
  },
  {
    field: "",
    headerName: "CONFIRMED_AMOUNT",
    minWidth: 100,
    cellRenderer: (params: any) => pricePipe.transform(params.data.totalAmount),
  },
  {
    field: "",
    headerName: "REMAINING",
    minWidth: 100,
    cellRenderer: (params: any) => pricePipe.transform(params.data.remaining),
    cellClass: (params: any) =>
      params.data.remaining < 0 ? "text-danger direction-ltr" : "",
  },
];

export const AuditAidDetailCols = [
  {
    field: "",
    headerName: "#",
    pinned: "right",
    minWidth: 60,
    suppressSizeToFit: true,
  },
  { field: "accountTree", headerName: "CASH_BALANCE", minWidth: 200 },
  {
    field: "",
    headerName: "INVENTORY",
    minWidth: 100,
    cellRenderer: (params: any) => pricePipe.transform(params.data.balance),
  },
  {
    field: "",
    headerName: "CONFIRMED_AMOUNT",
    minWidth: 100,
    cellRenderer: (params: any) => pricePipe.transform(params.data.totalAmount),
  },
  {
    field: "",
    headerName: "REMAINING",
    minWidth: 100,
    cellRenderer: (params: any) => pricePipe.transform(params.data.remaining),
    cellClass: (params: any) =>
      params.data.remaining < 0 ? "text-danger direction-ltr" : "",
  },
];

export const TransferCashCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'donationTree', headerName: 'PROJECT_TREE_REQUEST', minWidth: 150 },
  { field: '', headerName: 'CASH_TREE_FIELD', minWidth: 150, cellRenderer: (params: any) => params.data.cashTree || params.data.product || params.data.service },
  { field: '', headerName: 'TOTAL_AMOUNT', minWidth: 120, cellRenderer: (params: any) => pricePipe.transform(params.data.totalAmount) },
  { field: 'action', headerName: '', pinned: 'left', type: 'dropdown', minWidth: 85, cellRendererParams: (params: any) => {
    const dropdownItems = ['ADD_DETAIL'];
    if (!params.data.totalAmount) {
      dropdownItems.push('EDIT', 'DELETE');
    }
    return dropdownItems;
  }}
];

export const TransferCashSubCols = [
  { field: '', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'beneficiaryType', headerName: 'BENEFICIARY_TYPE_REQUEST', minWidth: 150 },
  { field: 'festival', headerName: 'PROGRAM_REQUEST', minWidth: 150 },
  { field: '', headerName: 'REQUESTED_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.amount) },
  { field: 'action', headerName: '', pinned: 'left', type: 'dropdown', minWidth: 85, cellRendererParams: { dropdownItems: ['EDIT', 'DELETE'] }}
];

export const TransferCashListCols = [
  { field: '', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'requestCode', headerName: 'REQUEST_CODE', minWidth: 150 },
  { field: 'auditCode', headerName: 'AUDIT_CODE', minWidth: 150 },
  { field: 'reviewCode', headerName: 'REVIEW_CODE', minWidth: 150 },
  { field: 'sourceBranchTitle', headerName: 'ORIGIN_BRANCH', minWidth: 150 },
  { field: 'destinationBranchTitle', headerName: 'DESTINATION_BRANCH', minWidth: 150 },
  { field: 'registerer', headerName: 'REGISTERAR', minWidth: 150 },
  { field: '', headerName: 'REQUEST_DATE', minWidth: 130, cellRenderer: (params: any) => datePipe.transform(params.data.requestDate, 'YYYY-MM-dd') },
  { field: 'auditor', headerName: 'AUDITOR', minWidth: 150 },
  { field: '', headerName: 'AUDIT_DATE', minWidth: 130, cellRenderer: (params: any) => datePipe.transform(params.data.auditDate, 'YYYY-MM-dd') },
  { field: 'reviewer', headerName: 'REVIEWER', minWidth: 150 },
  { field: '', headerName: 'REVIEW_DATE', minWidth: 130, cellRenderer: (params: any) => datePipe.transform(params.data.reviewDate, 'YYYY-MM-dd') },
  { field: '', headerName: 'STATUS', pinned: 'left', minWidth: 190, cellRenderer: StatusRenderer, cellRendererParams: (params: any) => {
    params.data.statusName = TransferCashStatuses[params.data.status];
    switch (params.data.status) {
      case 1: params.data.theme = 'yellow'; break;
      case 2: params.data.theme = 'brown'; break;
      case 3: params.data.theme = 'orange'; break;
      case 4: params.data.theme = 'green-600'; break;
      case 5: params.data.theme = 'green'; break;
      case 6: params.data.theme = 'pink-600'; break;
      case 7: params.data.theme = 'pink'; break;
      case 8: params.data.theme = 'red'; break;
      default: break;
    }
  }}
];

export const CashTransferDeliveryList = [
  { field: '', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'requestCode', headerName: 'REQUEST_CODE', minWidth: 150 },
  { field: 'deliveryCode', headerName: 'DELIVER_CODE', minWidth: 150 },
  { field: 'receiveCode', headerName: 'RECEIVE_CODE', minWidth: 150 },
  { field: 'sourceBranchTitle', headerName: 'ORIGIN_BRANCH', minWidth: 150 },
  { field: 'destinationBranchTitle', headerName: 'DESTINATION_BRANCH', minWidth: 150 },
  { field: '', headerName: 'DELIVERY_DATE', minWidth: 130, cellRenderer: (params: any) => datePipe.transform(params.data.date, 'YYYY-MM-dd') },
  { field: 'deliveryUserFullName', headerName: 'TRANSPORTER', minWidth: 150 },
  { field: 'receiverUserFullName', headerName: 'DELIVERY', minWidth: 150 },
  { field: '', headerName: 'RECEIVE_DATE', minWidth: 130, cellRenderer: (params: any) => datePipe.transform(params.data.receiveDate, 'YYYY-MM-dd') },
  { field: 'receiver', headerName: 'RECIPIENT', minWidth: 150 },
  { field: 'acceptor', headerName: 'CORROBORANT', minWidth: 150 },
  { field: '', headerName: 'CONFIRM_RECEIVE_DATE', minWidth: 130, cellRenderer: (params: any) => datePipe.transform(params.data.acceptReceiveDate, 'YYYY-MM-dd') },
  { field: '', headerName: 'STATUS', pinned: 'left', minWidth: 190, cellRenderer: StatusRenderer, cellRendererParams: (params: any) => {
    params.data.statusName = CashTransferDeliveryStatus[params.data.lastStatus];
    switch (params.data.lastStatus) {
      case 1: params.data.theme = 'orange'; break;
      case 2: params.data.theme = 'pink'; break;
      case 3: params.data.theme = 'green-600'; break;
      case 4: params.data.theme = 'green'; break;
      default: break;
    }
  }}
];

export const AuditConfirmTransferCashCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'donationTree', headerName: 'PROJECT_TREE_REQUEST', minWidth: 150 },
  { field: '', headerName: 'CASH_TREE_FIELD', minWidth: 150, cellRenderer: (params: any) => params.data.cashTree || params.data.product || params.data.service },
  { field: 'beneficiaryType', headerName: 'BENEFICIARY_TYPE_REQUEST', minWidth: 150 },
  { field: 'festival', headerName: 'PROGRAM_REQUEST', minWidth: 150 },
  { field: '', headerName: 'REQUESTED_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.amount) },
  { field: '', headerName: 'ASSIGNED_TOTAL_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.allocateAmount) },
  { field: 'status', headerName: 'STATUS', pinned: 'left', minWidth: 150, type: 'template', style: { left: '78px' }, cellRenderer: (params: any) => {
    let theme = null;
    switch (params.data.allocateStatus) {
      case 0: theme = 'yellow'; break;
      case 1: theme = 'green'; break;
      case 2: theme = 'red'; break;
      default: break;
    }
    return `<div class="status status-${ theme }"> ${ translatePipe.transform(ApprovalStatuses[params.data.allocateStatus]) } </div>`;
  }},
  { field: 'action', headerName: '', pinned: 'left', type: 'dropdown', minWidth: 85, cellRenderer: DropdownButtonRenderer, cellRendererParams: { dropdownItems: ['SUPPLY_2', 'CANCEL'] }}
];

export const AuditConfirmTransferCashSubCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'donationTree', headerName: 'PROJECT_TREE_ASSIGN', minWidth: 150 },
  { field: '', headerName: 'CASH_TREE_FIELD', minWidth: 150, cellRenderer: (params: any) => params.data.cashTree || params.data.product || params.data.service },
  { field: 'beneficiaryType', headerName: 'BENEFICIARY_TYPE', minWidth: 150 },
  { field: '', headerName: 'ASSIGNED_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.amount) },
  { field: 'action', headerName: '', pinned: 'left', minWidth: 85, type: 'button', cellRenderer: { type: 'fill', color: 'danger', text: 'DELETE', action: 'delete' }}
];

export const ViewTransferCashCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'donationTree', headerName: 'PROJECT_TREE_REQUEST', minWidth: 150 },
  { field: '', headerName: 'CASH_TREE_FIELD', minWidth: 150, cellRenderer: (params: any) => params.data.cashTree || params.data.product || params.data.service },
  { field: '', headerName: 'REQUESTED_AMOUNT', minWidth: 120, cellRenderer: (params: any) => pricePipe.transform(params.data.amount) },
  { field: '', headerName: 'ASSIGNED_AMOUNT', minWidth: 120, cellRenderer: (params: any) => pricePipe.transform(params.data.allocateAmount) },
  { field: '', headerName: 'DELIVERED_AMOUNT', minWidth: 120, cellRenderer: (params: any) => pricePipe.transform(params.data.deliverytAmount) },
  { field: '', headerName: 'REMAINING_AMOUNT', minWidth: 120, cellRenderer: (params: any) => pricePipe.transform(params.data.remainingAmount) }
];

export const ViewTransferCashSubCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'beneficiaryType', headerName: 'BENEFICIARY_TYPE_REQUEST', minWidth: 150 },
  { field: 'festival', headerName: 'PROGRAM_REQUEST', minWidth: 150 },
  { field: '', headerName: 'REQUESTED_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.amount) },
  { field: '', headerName: 'ASSIGNED_SUM_AMOUNT', minWidth: 120, cellRenderer: (params: any) => pricePipe.transform(params.data.allocateAmount) },
  { field: '', headerName: 'DELIVERED_SUM_AMOUNT', minWidth: 120, cellRenderer: (params: any) => pricePipe.transform(params.data.deliverytAmount) },
  { field: '', headerName: 'REMAINING_SUM_AMOUNT', minWidth: 120, cellRenderer: (params: any) => pricePipe.transform(params.data.remainingAmount) },
  { field: 'status', headerName: 'STATUS', pinned: 'left', minWidth: 190, type: 'template', cellRenderer: (params: any) => {
    let theme = null;
    switch (params.data.allocateStatus) {
      case 0: theme = 'yellow'; break;
      case 1: theme = 'green'; break;
      case 2: theme = 'red'; break;
      default: break;
    }
    return `<div class="status status-${ theme }"> ${ translatePipe.transform(ApprovalStatuses[params.data.allocateStatus]) } </div>`;
  }}
];

export const PrintTransferCashSubCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'beneficiaryType', headerName: 'BENEFICIARY_TYPE_REQUEST', minWidth: 150 },
  { field: 'festival', headerName: 'PROGRAM_REQUEST', minWidth: 150 },
  { field: '', headerName: 'REQUESTED_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.amount) },
  { field: '', headerName: 'ASSIGNED_SUM_AMOUNT', minWidth: 120, cellRenderer: (params: any) => pricePipe.transform(params.data.allocateAmount) },
  { field: '', headerName: 'DELIVERED_SUM_AMOUNT', minWidth: 120, cellRenderer: (params: any) => pricePipe.transform(params.data.deliverytAmount) },
  { field: '', headerName: 'REMAINING_SUM_AMOUNT', minWidth: 120, cellRenderer: (params: any) => pricePipe.transform(params.data.remainingAmount) },
  { field: 'status', headerName: 'STATUS', pinned: 'left', minWidth: 190, cellRenderer: StatusRenderer, cellRendererParams: (params: any) => {
    params.data.statusName = ApprovalStatuses[params.data.allocateStatus];
    switch (params.data.allocateStatus) {
      case 0: params.data.statusTheme = 'yellow'; break;
      case 1: params.data.statusTheme = 'green'; break;
      case 2: params.data.statusTheme = 'red'; break;
      default: break;
    }
    return params;
  }}
];

export const DetailTransferCashInConfirmCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'donationTree', headerName: 'PROJECT_TREE', minWidth: 200 },
  { field: '', headerName: 'CASH_TREE_FIELD', minWidth: 150, cellRenderer: (params: any) => params.data.cashTree || params.data.product || params.data.service },
  { field: 'beneficiaryType', headerName: 'BENEFICIARY_TYPE', minWidth: 150 },
  { field: '', headerComponent: CustomHeader, headerComponentParams: { data: ['INVENTORY'], symbol: 'IQD' }, minWidth: 100,
    cellRenderer: (params: any) => pricePipe.transform(params.data.cashTreeBalance || params.data.productBalance || params.data.serviceBalance || 0)
  },
  { field: '', headerName: 'CONFIRMED_TOTAL_AMOUNT', minWidth: 100, cellRenderer: (params: any) => pricePipe.transform(params.data.amount) },
  { field: '', headerComponent: CustomHeader, headerComponentParams: { data: ['REMAINING'], symbol: 'IQD' }, minWidth: 100, cellRenderer: (params: any) => pricePipe.transform(params.data.remainingBalance) }
];

export const ReceiveTransferCashCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'requestDonationTree', headerName: 'PROJECT_TREE_REQUEST', minWidth: 150, suppressSizeToFit: true },
  { field: '', headerName: 'CASH_TREE_FIELD', minWidth: 150, cellRenderer: (params: any) => params.data.cashTree || params.data.product || params.data.service },
  { field: 'requestBeneficiaryType', headerName: 'BENEFICIARY_TYPE_REQUEST', minWidth: 150 },
  { field: 'requestFestival', headerName: 'PROGRAM_REQUEST', minWidth: 150 },
  { field: '', headerName: 'RECEIVED_AMOUNT', minWidth: 130, suppressSizeToFit: true, cellRenderer: (params: any) => pricePipe.transform(params.data.amount) }
];

export const ReportTransferCashCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'requestDonationTree', headerName: 'PROJECT_TREE_REQUEST', minWidth: 150, suppressSizeToFit: true },
  { field: '', headerName: 'CASH_TREE_FIELD', minWidth: 150, cellRenderer: (params: any) => params.data.cashTree || params.data.product || params.data.service },
  { field: 'requestBeneficiaryType', headerName: 'BENEFICIARY_TYPE_REQUEST', minWidth: 150 },
  { field: 'requestFestival', headerName: 'PROGRAM_REQUEST', minWidth: 150 }
];

export const TransferGoodListCols = [
  { field: '', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'requestCode', headerName: 'REQUEST_CODE', minWidth: 150 },
  { field: 'sourceBranchTitle', headerName: 'ORIGIN_BRANCH', minWidth: 150 },
  { field: 'destinationBranchTitle', headerName: 'DESTINATION_BRANCH', minWidth: 150 },
  { field: 'registerer', headerName: 'REGISTERAR', minWidth: 150 },
  { field: '', headerName: 'REQUEST_DATE', minWidth: 130, cellRenderer: (params: any) => datePipe.transform(params.data.requestDate, 'YYYY-MM-dd') },
  { field: 'warehouseKeeper', headerName: 'WAREHOUSE_KEEPER', minWidth: 150 },
  { field: '', headerName: 'EXIT_REQUEST_DATE', minWidth: 130, cellRenderer: (params: any) => datePipe.transform(params.data.exitRequestDate, 'YYYY-MM-dd') },
  { field: 'reviewer', headerName: 'REVIEWER', minWidth: 150 },
  { field: '', headerName: 'REVIEW_DATE', minWidth: 130, cellRenderer: (params: any) => datePipe.transform(params.data.reviewDate, 'YYYY-MM-dd') },
  { field: '', headerName: 'STATUS', pinned: 'left', minWidth: 190, cellRenderer: StatusRenderer, cellRendererParams: (params: any) => {
    params.data.statusName = TransferGoodStatuses[params.data.lastStatus];
    switch (params.data.lastStatus) {
      case 1: params.data.theme = 'yellow-pink'; break;
      case 2: params.data.theme = 'pink-600'; break;
      case 3: params.data.theme = 'yellow'; break;
      case 4: params.data.theme = 'primary'; break;
      case 5: params.data.theme = 'green'; break;
      case 6: params.data.theme = 'orange'; break;
      case 7: params.data.theme = 'pink'; break;
      case 8: params.data.theme = 'red'; break;
      default: break;
    }
  }}
];

export const TransferGoodDeliverCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'product', headerName: 'GOOD', minWidth: 180 },
  { field: 'productSerialNumber', headerName: 'SERIAL_NUMBER', minWidth: 150 },
  { field: '', headerName: 'COUNT', minWidth: 100, cellRenderer: (params: any) => (params.data.count || '') + ' ' + (params.data.unit || '') }
];

export const TransferGoodDeliveryListCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'requestCode', headerName: 'REQUEST_CODE', minWidth: 150 },
  { field: 'deliveryCode', headerName: 'DELIVER_CODE', minWidth: 150 },
  { field: 'receiveCode', headerName: 'RECEIVE_CODE', minWidth: 150 },
  { field: 'sourceBranchTitle', headerName: 'RECEIVER_BRANCH', minWidth: 150 },
  { field: 'destinationBranchTitle', headerName: 'DELIVER_BRANCH', minWidth: 150 },
  { field: '', headerName: 'DELIVERY_DATE', minWidth: 130, cellRenderer: (params: any) => datePipe.transform(params.data.deliveryDate, 'YYYY-MM-dd') },
  { field: 'transfereeUser', headerName: 'TRANSPORTER', minWidth: 150 },
  { field: 'deliveryUser', headerName: 'DELIVERY', minWidth: 150 },
  { field: '', headerName: 'RECEIVE_DATE', minWidth: 130, cellRenderer: (params: any) => datePipe.transform(params.data.receiveDate, 'YYYY-MM-dd') },
  { field: 'receiver', headerName: 'RECIPIENT', minWidth: 150 },
  { field: 'acceptor', headerName: 'CORROBORANT', minWidth: 150 },
  { field: '', headerName: 'CONFIRM_RECEIVE_DATE', minWidth: 130, cellRenderer: (params: any) => datePipe.transform(params.data.acceptReceiveDate, 'YYYY-MM-dd') },
  { field: '', headerName: 'STATUS', pinned: 'left', minWidth: 190, cellRenderer: StatusRenderer, cellRendererParams: (params: any) => {
    params.data.statusName = TransferGoodDeliveryStatuses[params.data.lastStatus];
    switch (params.data.lastStatus) {
      case 1: params.data.theme = 'orange'; break;
      case 2: params.data.theme = 'pink'; break;
      case 3: params.data.theme = 'green-600'; break;
      case 4: params.data.theme = 'green'; break;
      default: break;
    }
  }}
];

export const TransferGoodReportMainCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'donationTree', headerName: 'DONATION_TREE', minWidth: 150 },
  { field: 'productGroup', headerName: 'PRODUCT_GROUP', minWidth: 150 },
  { field: 'product', headerName: 'GOOD', minWidth: 150 },
  { field: 'count', headerName: 'REQUESTED_TOTAL_COUNT', minWidth: 120 },
  { field: 'countSupplies', headerName: 'ASSIGNED_TOTAL_COUNT', minWidth: 120 },
  { field: 'countSuppliesDelivered', headerName: 'DELIVERED_TOTAL_COUNT', minWidth: 120 },
  { field: 'countRemaining', headerName: 'REMAINING_TOTAL_COUNT', minWidth: 120 }
];

export const TransferGoodReportSubCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'department', headerName: 'DEPARTMENT', minWidth: 150 },
  { field: 'festival', headerName: 'PROGRAM', minWidth: 150 },
  { field: 'beneficiaryType', headerName: 'BENEFICIARY_TYPE', minWidth: 150 },
  { field: 'count', headerName: 'REQUESTED_COUNT', minWidth: 120 },
  { field: 'countSupplies', headerName: 'ASSIGNED_COUNT', minWidth: 120 },
  { field: 'countSuppliesDelivered', headerName: 'DELIVERED_COUNT', minWidth: 120 },
  { field: 'countRemaining', headerName: 'REMAINING_COUNT', minWidth: 120 },
  { field: 'status', headerName: 'STATUS', pinned: 'left', minWidth: 190, type: 'template', cellRenderer: (params: any) => {
    let theme = null;
    params.data.status = params.data.status || 0;
    switch (params.data.status) {
      case 0: theme = 'yellow'; break;
      case 1: theme = 'green'; break;
      case 2: theme = 'red'; break;
      default: break;
    }
    return `<div class="status status-${ theme }"> ${ translatePipe.transform(ApprovalStatuses[params.data.status]) } </div>`;
  }}
];

export const TransferGoodDeliveryReportInDeliverCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'product', headerName: 'GOOD', minWidth: 180 },
  { field: 'productSerialNumber', headerName: 'SERIAL_NUMBER', minWidth: 150 },
  { field: '', headerName: 'COUNT', minWidth: 100, cellRenderer: (params: any) => (params.data.count || '') + ' ' + (params.data.unit || '') }
];

export const TransferGoodDeliveryReportInReceiveCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'product', headerName: 'GOOD', minWidth: 180 },
  { field: 'productSerialNumber', headerName: 'SOURCE_SERIAL_NUMBER', minWidth: 150 },
  { field: 'destinationProductSerialNumber', headerName: 'DESTINATION_SERIAL_NUMBER', minWidth: 150 },
  { field: '', headerName: 'COUNT', minWidth: 100, cellRenderer: (params: any) => (params.data.count || '') + ' ' + (params.data.unit || '') }
];

export const LogListCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: '', headerName: 'GROUP', minWidth: 200, cellRenderer: (params: any) => translatePipe.transform(params.data.group) },
  { field: '', headerName: 'DATE', minWidth: 130, cellRenderer: (params: any) => datePipe.transform(params.data.createDate, 'YYYY-MM-dd HH:MM:SS') },
  { field: 'user', headerName: 'USER', minWidth: 150 },
  { field: '', headerName: 'ACTION_TYPE', minWidth: 85, cellRenderer: (params: any) => translatePipe.transform(ActionStatuses[params.data.actionType]) },
  { field: '', headerName: '', pinned: 'left', minWidth: 85, cellRenderer: ButtonRenderer, cellRendererParams: {
    type: 'fill', color: 'blue', text: 'LOG_INFO', action: 'log_info' }
  }
];

export const HrEmploymentRequestCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'name', headerName: 'NAME', minWidth: 150 },
  { field: 'jobTitle', headerName: 'JOB', minWidth: 150 },
  { field: 'cellPhoneNumber', headerName: 'MOBILE', minWidth: 150 },
  { field: 'address', headerName: 'ADDRESS', minWidth: 200 },
  { field: 'action', headerName: '', pinned: 'left', type: 'dropdown', minWidth: 85, suppressSizeToFit: true,
    cellRenderer: DropdownButtonRenderer, cellRendererParams: { dropdownItems: ['EDIT', 'DELETE'] }
  }
];

export const HrOrganizationalPositionListCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'title', headerName: 'ORGANIZATIONAL_POSITION', minWidth: 150 },
  { field: 'jobGroup', headerName: 'JOB_GROUP', minWidth: 150 },
  { field: '', headerName: 'JOB_TITLE_BY_LEVEL', minWidth: 180, cellRenderer: (params: any) => (params.data.jobTitle || '') + (params.data.jobTitleRank ? (' - ' + params.data.jobTitleRank) : '') },
  { field: 'fullCode', headerName: 'CODE_FOR_ORGANIZATIONAL_POSITION', minWidth: 150 },
  { field: 'action', headerName: '', pinned: 'left', type: 'dropdown', minWidth: 85, suppressSizeToFit: true, cellRenderer: DropdownButtonRenderer, cellRendererParams: { dropdownItems: ['JOB_DESCRIPTION', 'EDIT', 'DELETE'] }}
];

export const HrRequestListCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'requestCode', headerName: 'REQUEST_CODE', minWidth: 150 },
  { field: 'employmentType', headerName: 'COLLABORATION_TYPE', minWidth: 150 },
  { field: 'fullName', headerName: 'REQUESTER', minWidth: 150 },
  { field: 'branch', headerName: 'BRANCH', minWidth: 150 },
  { field: 'department', headerName: 'ORGANIZATIONAL_UNIT', minWidth: 150 },
  { field: 'organizationalPosition', headerName: 'ORGANIZATIONAL_POSITION', minWidth: 150 },
  { field: '', headerName: 'REQUEST_DATE', minWidth: 130, cellRenderer: (params: any) => datePipe.transform(params.data.requestDate, 'YYYY-MM-dd') },
  { field: '', headerComponent: CustomHeader, headerComponentParams: { data: ['REVIEWER', 'REVIEW_DATE'] }, minWidth: 150,
    cellRenderer: (params: any) => `<div> <div> ${ params.data.reviewer || '' } </div> <div class='mt-n3'> ${ params.data.reviewDate ? datePipe.transform(params.data.reviewDate, 'YYYY-MM-dd') : '' } </div> </div>`
  },
  { field: '', headerComponent: CustomHeader, headerComponentParams: { data: ['INTERVIEWER', 'INTERVIEW_DATE'] }, minWidth: 150,
    cellRenderer: (params: any) => `<div> <div> ${ params.data.interviewer || '' } </div> <div class='mt-n3'> ${ params.data.interviewDate ? datePipe.transform(params.data.interviewDate, 'YYYY-MM-dd') : '' } </div> </div>`
  },
  { field: '', headerComponent: CustomHeader, headerComponentParams: { data: ['INTERVIEW_REVIEWER', 'INTERVIEW_REVIEW_DATE'] }, minWidth: 150,
    cellRenderer: (params: any) => `<div> <div> ${ params.data.interviewReviewer || '' } </div> <div class='mt-n3'> ${ params.data.interviewReviewDate ? datePipe.transform(params.data.interviewReviewDate, 'YYYY-MM-dd') : '' } </div> </div>`
  },
  { field: '', headerName: 'STATUS', pinned: 'left', minWidth: 190, cellRenderer: StatusRenderer, cellRendererParams: (params: any) => {
    params.data.statusName = EmploymentRequestStatuses[params.data.lastStatus.status];
    switch (params.data.lastStatus.status) {
      case 1: params.data.theme = 'yellow'; break;
      case 2: params.data.theme = 'green-600'; break;
      case 3: params.data.theme = 'pink'; break;
      case 4: params.data.theme = 'green'; break;
      case 5: params.data.theme = 'red'; break;
      default: break;
    }
  }},
  { field: 'action', headerName: '', pinned: 'left', minWidth: 85, suppressSizeToFit: true, cellRenderer: DropdownButtonRenderer, cellRendererParams: (params: any) => {
    let dropdownItems = [];
    switch (params.data.lastStatus.status) {
      case 1: dropdownItems = ['VIEW', 'EDIT', 'REVIEW', 'DELETE']; break;
      case 2: dropdownItems = ['VIEW', 'INTERVIEW']; break;
      case 3: dropdownItems = ['VIEW', 'INTERVIEW_REVIEW', 'PROFILE', 'ATTACH']; break;
      case 4: dropdownItems = ['VIEW', 'PROFILE', 'ATTACH']; break;
      case 5: dropdownItems = ['VIEW', 'ATTACH']; break;
      default: break;
    }
    return { dropdownItems: dropdownItems };
  }}
];

export const HrPersonnelListCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'branch', headerName: 'BRANCH', minWidth: 150 },
  { field: 'personnelCode', headerName: 'CODE', minWidth: 150 },
  { field: 'fullName', headerName: 'FULL_NAME', minWidth: 150 },
  { field: 'department', headerName: 'ORGANIZATIONAL_UNIT', minWidth: 150 },
  { field: 'organizationalPosition', headerName: 'ORGANIZATIONAL_POSITION', minWidth: 150 },
  { field: 'degree', headerName: 'RATE', minWidth: 80 },
  { field: 'age', headerName: 'AGE', minWidth: 80 },
  { field: 'maritalStatus', headerName: 'MARITAL_STATUS', minWidth: 80 },
  { field: 'gender', headerName: 'GENDER', minWidth: 80 },
  { field: 'ancestry', headerName: 'ANCESTRY', minWidth: 150 },
  { field: '', headerName: 'RELIGIOUS_COMMITMENT', minWidth: 80, type: 'template', cellRenderer: (params: any) => {
    switch (params.data.isReligiousCommitment) {
      case true: return '<i class="bx bx-check fw-bolder"> </i>';
      case false: return '<i class="bx bx-x fw-bolder"> </i>';
      default: break;
    }
  }},
  { field: 'phone', headerName: 'MOBILE', minWidth: 150 },
  { field: '', headerName: 'STATUS', pinned: 'left', minWidth: 190, cellRenderer: StatusRenderer, cellRendererParams: (params: any) => {
    params.data.statusName = CooperationStatuses[params.data.cooperationStatus];
    switch (params.data.cooperationStatus) {
      case 1: params.data.theme = 'green'; break;
      case 2: params.data.theme = 'red'; break;
      default: break;
    }
  }},
  { field: 'action', headerName: '', pinned: 'left', minWidth: 85, suppressSizeToFit: true, cellRenderer: DropdownButtonRenderer, cellRendererParams: (params: any) => {
    let dropdownItems = [];
    switch (params.data.cooperationStatus) {
      case 1: dropdownItems = ['EDIT', 'CREATE_RULE', 'CREATE_CONTRACT', 'CREATE_TERMINATE_EMPLOYMENT_REQUEST', 'PRINT']; break;
      case 2: dropdownItems = ['EDIT', 'CREATE_CONTRACT', 'PRINT']; break;
      default: break;
    }
    return { dropdownItems: dropdownItems };
  }}
];

export const HrContractListCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'branch', headerName: 'BRANCH', minWidth: 150 },
  { field: 'code', headerName: 'CONTRACT_CODE', minWidth: 150 },
  { field: 'personnelCode', headerName: 'PERSONNEL_CODE', minWidth: 150 },
  { field: 'fullName', headerName: 'FULL_NAME', minWidth: 150 },
  { field: 'contractDate', headerName: 'CREATE_CONTRACT_DATE', minWidth: 130, cellRenderer: DateRenderer },
  { field: 'contractType', headerName: 'CONTRACT_TYPE', minWidth: 150 },
  { field: 'employmentType', headerName: 'COLLABORATION_TYPE', minWidth: 150 },
  { field: 'startDate', headerName: 'START_DATE', minWidth: 130, cellRenderer: DateRenderer },
  { field: 'endDate', headerName: 'END_DATE', minWidth: 130, cellRenderer: DateRenderer },
  { field: 'fromSalaryPeriod', headerName: 'START_SALARY_PERIOD', minWidth: 150 },
  { field: '', headerName: 'STATUS', pinned: 'left', minWidth: 190, cellRenderer: StatusRenderer, cellRendererParams: (params: any) => {
    params.data.statusName = EmployeeContractStatuses[params.data.status];
    switch (params.data.status) {
      case 1: params.data.theme = 'green'; break;
      case 2: params.data.theme = 'red'; break;
      default: break;
    }
  }},
  { field: 'action', headerName: '', pinned: 'left', type: 'dropdown', minWidth: 85, cellRenderer: DropdownButtonRenderer, cellRendererParams: { dropdownItems: ['EDIT', 'DELETE'] }}
];

export const HrEmployeeRuleCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'salaryFactor', headerName: 'LEGAL_AGENT', minWidth: 150 },
  { field: '', headerName: 'AMOUNT_AR', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.amount) },
  /* { field: '', headerName: 'SHOW_IN_LEGAL_RECEIPT', minWidth: 80, type: 'template', cellRenderer: (params: any) => {
    switch (params.data.showInPrint) {
      case true: return '<i class="bx bx-check fw-bolder"> </i>';
      case false: return '<i class="bx bx-x fw-bolder"> </i>';
      default: break;
    }
  }},
  { field: '', headerName: 'ONLY_FOR_CALCULATION', minWidth: 80, type: 'template', cellRenderer: (params: any) => {
    switch (params.data.justForCalculate) {
      case true: return '<i class="bx bx-check fw-bolder"> </i>';
      case false: return '<i class="bx bx-x fw-bolder"> </i>';
      default: break;
    }
  }}, */
  { field: 'action', headerName: '', pinned: 'left', type: 'dropdown', minWidth: 85, suppressSizeToFit: true,
    cellRenderer: DropdownButtonRenderer, cellRendererParams: { dropdownItems: ['EDIT', 'DELETE'] }
  }
];

export const HrImprestCols = [
  {
    field: "#",
    headerName: "#",
    pinned: "right",
    minWidth: 60,
    suppressSizeToFit: true,
  },
  { field: "branch", headerName: "BRANCH", minWidth: 150 },
  { field: "imprestDate", headerName: "DATE", minWidth: 150, cellRenderer: DateRenderer },
  { field: "employee", headerName: "PERSONNEL", minWidth: 150 },
  { field: "personnelCode", headerName: "PERSONNEL_CODE", minWidth: 150 },
  { field: "amount", headerName: "IMPREST_AMOUNT_2", minWidth: 150, cellRenderer: params => pricePipe.transform(params.data.amount) },
  { field: "applySalaryPeriod", headerName: "REPAY_SALARY_PERIOD", minWidth: 150 },
  { field: "accountTree", headerName: "PAY_LOCATION", minWidth: 150 },
  {
    field: "",
    headerName: "STATUS",
    pinned: "left",
    minWidth: 190,
    cellRenderer: StatusRenderer,
    cellRendererParams: (params) => {
      params.data.statusName = ImprestStatus[params.data.lastStatus.status];
      switch (params.data.lastStatus.status) {
        case 1:
          params.data.theme = "yellow";
          break;
        case 2:
          params.data.theme = "pink";
          break;
        case 3:
          params.data.theme = "red";
          break;
        case 4:
          params.data.theme = "green";
          break;
      }
    },
  },
  {
    field: 'action',
    headerName: '',
    pinned: 'left',
    type: 'dropdown',
    minWidth: 85,
    suppressSizeToFit: true,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: (params) => {
      let dropdownItems = ['VIEW'];
      params.data.lastStatus.status === ImprestStatus.SUBMIT_REQUEST ? dropdownItems.push('EDIT','PAY', 'DELETE'):'';
      return {dropdownItems};
    }
  }
];

export const HrRuleListCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'branch', headerName: 'BRANCH', minWidth: 150 },
  { field: 'employee', headerName: 'EMPLOYEE', minWidth: 150 },
  { field: 'ruleType', headerName: 'RULE', minWidth: 150 },
  { field: 'startDate', headerName: 'CREATE_DATE', minWidth: 130, cellRenderer: DateRenderer },
  { field: 'fromSalaryPeriod', headerName: 'START_RULE_PERIOD', minWidth: 150 },
  { field: 'endDate', headerName: 'END_DATE', minWidth: 130, cellRenderer: DateRenderer },
  { field: 'department', headerName: 'ORGANIZATIONAL_UNIT', minWidth: 150 },
  { field: 'organizationalPosition', headerName: 'ORGANIZATIONAL_POSITION', minWidth: 150 },
  { field: 'degree', headerName: 'RATE', minWidth: 80 },
  { field: 'includesBaseSalary', headerName: 'INCLUDES_BASE_SALARY', minWidth: 150, cellRenderer: CheckboxRenderer, cellRendererParams: () => {
    return { disabled: true };
  }},
  { field: 'isInsuranceProvided', headerName: 'IS_INSURANCE', minWidth: 150, cellRenderer: CheckboxRenderer, cellRendererParams: () => {
    return { disabled: true };
  }},
  { field: '', headerName: 'STATUS', pinned: 'left', minWidth: 190, cellRenderer: StatusRenderer, cellRendererParams: (params: any) => {
    params.data.statusName = ActiveStatuses[params.data.isActive ? 1 : 0];
    params.data.theme = params.data.isActive ? 'green' : 'red';
  }},
  { field: 'action', headerName: '', pinned: 'left', type: 'dropdown', minWidth: 85, suppressSizeToFit: true, cellRenderer: DropdownButtonRenderer, cellRendererParams: (params: any) => {
    let dropdownItems = params.data.ruleTypeId !== 1 ? ['EDIT', 'DELETE'] : [];
    return { dropdownItems: dropdownItems };
  }}
];

export const HrLoanListCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'branch', headerName: 'BRANCH', minWidth: 150 },
  { field: 'loanDate', headerName: 'DATE', minWidth: 130, cellRenderer: DateRenderer },
  { field: 'employee', headerName: 'PERSONNEL_NAME', minWidth: 150 },
  { field: 'personnelCode', headerName: 'PERSONNEL_CODE', minWidth: 150 },
  { field: 'loanType', headerName: 'LOAN_TYPE', minWidth: 150 },
  { field: '', headerName: 'REQUESTED_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.requestedAmount) },
  { field: '', headerName: 'LOAN_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.approvedAmount) },
  { field: '', headerName: 'PROFIT_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.profit) },
  { field: 'applySalaryPeriod', headerName: 'START_PAY_PERIOD', minWidth: 150 },
  { field: '', headerName: 'TOTAL_PAY_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.isPaid) },
  { field: '', headerName: 'REMAINING', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.unPaid) },
  { field: '', headerName: 'STATUS', pinned: 'left', minWidth: 190, cellRenderer: StatusRenderer, cellRendererParams: (params: any) => {
    params.data.statusName = EmployeeLoanStatuses[params.data.lastStatus.status];
    switch (params.data.lastStatus.status) {
      case 1: params.data.theme = 'yellow'; break;
      case 2: params.data.theme = 'green-600'; break;
      case 3: params.data.theme = 'pink'; break;
      case 4: params.data.theme = 'green'; break;
      case 5: params.data.theme = 'red'; break;
      default: break;
    }
  }},
  { field: 'action', headerName: '', pinned: 'left', minWidth: 85, suppressSizeToFit: true, cellRenderer: DropdownButtonRenderer, cellRendererParams: (params: any) => {
    let dropdownItems = [];
    switch (params.data.lastStatus.status) {
      case 1: dropdownItems = ['VIEW', 'EDIT', 'REVIEW', 'DELETE']; break;
      case 2: dropdownItems = ['VIEW', 'PAY']; break;
      case 3: dropdownItems = ['VIEW']; break;
      case 4: dropdownItems = ['VIEW']; break;
      case 5: dropdownItems = ['VIEW']; break;
      default: break;
    }
    return { dropdownItems: dropdownItems };
  }}
];

export const HrInstallmentListCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'installmentDate', headerName: 'DUE_DATE', minWidth: 130, cellRenderer: DateRenderer },
  { field: '', headerName: 'AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.amount) },
  { field: '', headerName: 'STATUS', pinned: 'left', minWidth: 190, cellRenderer: StatusRenderer, cellRendererParams: (params: any) => {
    params.data.statusName = PAY_STATUS_2[params.data.isApplied ? 1 : 0].title;
    params.data.theme = PAY_STATUS_2[params.data.isApplied ? 1 : 0].color;
  }}
];

export const WorkAndPaymentCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'employee', headerName: 'PERSONNEL_NAME', minWidth: 150 },
  { field: 'personnelCode', headerName: 'CODE', minWidth: 150 },
  { field: 'dailyFunction', headerName: 'DAILY_OPERATION', minWidth: 130, cellRenderer: TotalTimeRenderer },
  { field: 'absence', headerName: 'ABSENCE', minWidth: 130, cellRenderer: TotalTimeRenderer },
  { field: 'vacationWithoutSalary', headerName: 'UNPAID_LEAVE', minWidth: 130, cellRenderer: TotalTimeRenderer },
  { field: 'mission', headerName: 'MISSION', minWidth: 130, cellRenderer: TotalTimeRenderer },
  { field: 'overtime', headerName: 'OVERTIME', minWidth: 130, cellRenderer: TotalTimeRenderer },
  { field: 'vacationWithSalary', headerName: 'PAID_LEAVE', minWidth: 130, cellRenderer: TotalTimeRenderer },
  { field: 'totalReward', headerName: 'REWARD', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.totalReward) },
  { field: 'totalFines', headerName: 'PENALTY', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.totalFines) },
  { field: 'totalImprest', headerName: 'IMPREST', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.imprestDebt.totalAmount) },
  { field: 'totalLoan', headerName: 'TOTAL_LOAN_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.loanDebt.totalAmount) },
  { field: 'isInsuranceProvided', headerName: 'IS_INSURANCE', minWidth: 150, cellRenderer: CheckboxRenderer, cellRendererParams: { disabled: true }},
  /* { field: 'insuranceAmount', headerName: 'INSURANCE_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.insuranceAmount) },
  { field: 'organizationInsuranceShare', headerComponent: CustomHeader, headerComponentParams: { data: ['ORGANIZATION_INSURANCE_SHARE'], symbol: 'IQD' }, minWidth: 130,
    cellRenderer: (params: any) => pricePipe.transform(params.data.organizationInsuranceShare)
  },
  { field: 'employeeInsuranceShare', headerComponent: CustomHeader, headerComponentParams: { data: ['EMPLOYEE_INSURANCE_SHARE'], symbol: 'IQD' }, minWidth: 130,
    cellRenderer: (params: any) => pricePipe.transform(params.data.employeeInsuranceShare)
  }, */
  { field: 'taxAmount', headerComponent: CustomHeader, headerComponentParams: { data: ['TAX'], symbol: 'IQD' }, minWidth: 130,
    cellRenderer: (params: any) => pricePipe.transform(params.data.taxAmount)
  }
];

export const RewardOrPenaltyCols = [
  {
    field: "#",
    headerName: "#",
    pinned: "right",
    minWidth: 60,
    suppressSizeToFit: true,
  },
  { field: "date", headerName: "DATE", minWidth: 150, cellRenderer: DateRenderer },
  { field: "fineOrReward", headerName: "REASON_OF_REWARD_OR_PENALTY", minWidth: 150 },
  { field: "amount", headerName: "AMOUNT_AR", minWidth: 150, cellRenderer: params => pricePipe.transform(params.data.amount) },
];

export const SalaryListCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'branch', headerName: 'BRANCH', minWidth: 150 },
  { field: 'fiscalYear', headerName: 'FISCAL_YEAR', minWidth: 150 },
  { field: 'salaryPeriod', headerName: 'SALARY_PERIODS', minWidth: 150 },
  { field: 'totalEmployees', headerName: 'PERSONNEL_COUNT', minWidth: 130 },
  { field: '', headerName: 'WORK_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.operatingAmount) },
  { field: '', headerName: 'ADDITIONS', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.additions) },
  { field: '', headerName: 'DEDUCTIONS', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.deductions) },
  { field: '', headerName: 'NET_PAID', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.salary) }
];

export const ConfirmSalaryListCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'employee', headerName: 'PERSONNEL_NAME', minWidth: 150 },
  { field: 'personnelCode', headerName: 'PERSONNEL_CODE', minWidth: 150 },
  { field: '', headerName: 'WORK_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.operatingAmount) },
  { field: '', headerName: 'ADDITIONS', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.additions) },
  { field: '', headerName: 'DEDUCTIONS', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.deductions) },
  { field: '', headerName: 'NET_PAID', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.salary) }
];

export const HrTerminateEmploymentRequestListCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'branch', headerName: 'BRANCH', minWidth: 150 },
  { field: 'code', headerName: 'REQUEST_CODE', minWidth: 150 },
  { field: 'employee', headerName: 'PERSONNEL_FULL_NAME', minWidth: 150 },
  { field: 'reason', headerName: 'TERMINATE_EMPLOYMENT_REASON', minWidth: 150 },
  // { field: 'department', headerName: 'ORGANIZATIONAL_UNIT', minWidth: 150 },
  // { field: 'organizationalPosition', headerName: 'ORGANIZATIONAL_POSITION', minWidth: 150 },
  { field: '', headerName: 'REQUEST_DATE', minWidth: 130, cellRenderer: (params: any) => datePipe.transform(params.data.date, 'YYYY-MM-dd') },
  { field: '', headerName: 'REVIEWER', minWidth: 150, cellRenderer: (params: any) => params.data.reviewInfo?.userFullName },
  { field: '', headerName: 'REVIEW_DATE', minWidth: 130, cellRenderer: (params: any) => datePipe.transform(params.data.reviewInfo?.date, 'YYYY-MM-dd') },
  { field: '', headerName: 'FINAL_REVIEWER', minWidth: 150, cellRenderer: (params: any) => params.data.finalReviewInfo?.userFullName },
  { field: '', headerName: 'FINAL_REVIEW_DATE', minWidth: 130, cellRenderer: (params: any) => datePipe.transform(params.data.finalReviewInfo?.date, 'YYYY-MM-dd') },
  { field: 'dischargeProgress', headerName: "DISCHARGE_PROGRESS", pinned: 'left', minWidth: 200, cellRenderer: ProgressRenderer },
  { field: '', headerName: 'STATUS', pinned: 'left', minWidth: 190, cellRenderer: StatusRenderer, cellRendererParams: (params: any) => {
    params.data.statusName = EmployeeEndOfCooperationRequestStatuses[params.data.lastStatus.status];
    switch (params.data.lastStatus.status) {
      case 1: params.data.theme = 'yellow'; break;
      case 2: params.data.theme = 'pink'; break;
      case 3: params.data.theme = 'green-600'; break;
      case 4: params.data.theme = 'green'; break;
      case 5: params.data.theme = 'red'; break;
      default: break;
    }
  }},
  { field: 'action', headerName: '', pinned: 'left', minWidth: 85, suppressSizeToFit: true, cellRenderer: DropdownButtonRenderer, cellRendererParams: (params: any) => {
    let dropdownItems = [];
    switch (params.data.lastStatus.status) {
      case 1: dropdownItems = ['VIEW', 'EDIT', 'REVIEW', 'DELETE']; break;
      case 2: dropdownItems = params.data.isDischarge ? ['VIEW', 'DISCHARGE'] : ['VIEW']; break;
      case 3: dropdownItems = ['VIEW', 'FINAL_REVIEW']; break;
      case 4: dropdownItems = ['VIEW']; break;
      case 5: dropdownItems = ['VIEW']; break;
      default: break;
    }
    return { dropdownItems: dropdownItems };
  }}
];

export const HrDischargeListCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: '', headerName: 'ORGANIZATIONAL_POSITION', minWidth: 150, cellRenderer: (params: any) => params.data.payload?.organizationalPosition },
  { field: 'userFullName', headerName: 'FULL_NAME', minWidth: 150 },
  { field: '', headerName: 'DATE', minWidth: 130, cellRenderer: (params: any) => datePipe.transform(params.data.date, 'YYYY-MM-dd') },
  { field: 'description', headerName: 'DESCRIPTION', minWidth: 200, tooltipField: 'description' }
];

export const SettingTreeCols = [
  {
    field: "#",
    headerName: "#",
    pinned: "right",
    minWidth: 60,
    suppressSizeToFit: true,
  },
  {
    field: "title",
    headerName: "TITLE",
    minWidth: 600,
    cellStyle: (params) => ({
      "padding-right": `${
        params.data.level > 1
          ? (params.data.level - 1) * 6
          : params.data.level
      }rem`,
    }),
  },
  {
    field: "action",
    headerName: "",
    pinned: "left",
    minWidth: 85,
    suppressSizeToFit: true,
    cellRenderer: DropdownButtonRenderer,
    cellRendererParams: (params) => {
      let dropdownItems = [];
      switch (params.data.level) {
        case 1:
          dropdownItems.push("ADD_GROUP");
          break;
        case 2:
          dropdownItems.push("ADD_PRODUCT");
          break;
      }
      dropdownItems.push("EDIT", "DELETE");
      return { dropdownItems: dropdownItems };
    },
  },
];

export const SponsorshipRequestListCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'branch', headerName: 'BRANCH', minWidth: 150 },
  { field: 'requestCode', headerName: 'CODE_SPONSORSHIP_REQUEST', minWidth: 130 },
  { field: 'sponsorFullName', headerName: 'SPONSOR', minWidth: 150 },
  { field: 'branchPersonelFullName', headerName: 'BRANCH_AGENT', minWidth: 150 },
  { field: 'ancestry', headerName: 'ANCESTRY', minWidth: 130 },
  { field: 'countOfSponsorship', headerName: 'COUNT', minWidth: 130 },
  { field: '', headerName: 'REQUESTED_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.amount) },
  { field: 'startDate', headerName: 'START_DATE', minWidth: 130, cellRenderer: DateRenderer },
  { field: 'numberOfMonths', headerComponent: CustomHeader, headerComponentParams: { data: ['TIME_SPONSORSHIP'], symbol: 'MONTH' }, minWidth: 130 },
  { field: 'collectionPeriod', headerName: 'COLLECTION_PERIOD', minWidth: 130 },
  { field: 'collectionPlace', headerName: 'COLLECTION_LOCATION', minWidth: 150 },
  { field: '', headerName: 'OFFERED_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.suggestedAmount) },
  { field: '', headerName: 'RECEIVED_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.paidAmount) },
  { field: '', headerComponent: CustomHeader, headerComponentParams: { data: ['ARREARS'], symbol: 'IQD' }, minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.delayedAmount) },
  { field: '', headerName: 'REMAINING_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.remainingAmount) },
  { field: '', headerName: 'STATUS', pinned: 'left', minWidth: 190, cellRenderer: StatusRenderer, cellRendererParams: (params: any) => {
    params.data.statusName = SponsorshipStatuses[params.data.lastStatus];
    switch (params.data.lastStatus) {
      case 1: params.data.theme = 'yellow'; break;
      case 2: params.data.theme = 'orange'; break;
      case 3: params.data.theme = 'green-600'; break;
      case 4: params.data.theme = 'green'; break;
      case 5: params.data.theme = 'red'; break;
      default: break;
    }
  }},
  { field: 'action', headerName: '', pinned: 'left', minWidth: 85, suppressSizeToFit: true, cellRenderer: DropdownButtonRenderer, cellRendererParams: (params: any) => {
    let dropdownItems = [];
    switch (params.data.lastStatus) {
      case 1: dropdownItems = ['VIEW', 'EDIT', 'OFFER_ORPHAN', 'DELETE']; break;
      case 2: dropdownItems = ['VIEW', 'RECEIVE', 'REVIEW_VIA_ADMIN_BRANCH']; break;
      case 3: dropdownItems = ['VIEW', 'RECEIVE', 'REVIEW_VIA_ADMIN_BASE']; break;
      case 4: dropdownItems = ['VIEW', 'RECEIVE']; break;
      case 5: dropdownItems = ['VIEW']; break;
      default: break;
    }
    return { dropdownItems: dropdownItems };
  }}
];

export const SponsorshipOrphanListCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: '', headerName: 'FAMILY_CODE', minWidth: 130, cellRenderer: (params: any) => params.data.familyInfo.code },
  { field: '', headerName: 'SUPERVISOR_NAME', minWidth: 150, cellRenderer: (params: any) => params.data.familyInfo.supervisor },
  { field: '', headerName: 'CANDIDATE_ORPHAN_CODE', minWidth: 130, cellRenderer: (params: any) => params.data.familyInfo.familyMemberCode },
  { field: '', headerName: 'CANDIDATE_ORPHAN_NAME', minWidth: 150, cellRenderer: (params: any) => params.data.orphanInfo.fullName },
  { field: '', headerName: 'GENDER', minWidth: 130, cellRenderer: (params: any) => translatePipe.transform(Gender[params.data.orphanInfo.gender]) },
  { field: '', headerName: 'ANCESTRY', minWidth: 130, cellRenderer: (params: any) => translatePipe.transform(Ancestry[params.data.orphanInfo.ancestry]) },
  { field: '', headerName: 'START_DATE', minWidth: 130, cellRenderer: (params: any) => params.data.contractInfo ? datePipe.transform(params.data.contractInfo.fromDate, 'YYYY-MM-dd') : null },
  { field: 'contractInfoTotalNumberOfMonths', headerComponent: CustomHeader, headerComponentParams: { data: ['TIME'], symbol: 'MONTH' }, minWidth: 130 },
  // { field: '', headerName: 'AMOUNT_AR', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.contractInfo ? params.data.contractInfo.amount : 0) },
  { field: '', headerName: 'TOTAL_AMOUNT_AR', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.contractInfoTotalAmount) }
];

export const OrphanListCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'requestCode', headerName: 'REQUEST_CODE', minWidth: 130 },
  { field: 'sponsorCode', headerName: 'SPONSOR_CODE', minWidth: 130 },
  { field: 'sponsor', headerName: 'SPONSOR_NAME', minWidth: 150 },
  { field: 'familyCode', headerName: 'FAMILY_CODE', minWidth: 130 },
  { field: 'orphanCode', headerName: 'CANDIDATE_ORPHAN_CODE', minWidth: 130 },
  { field: 'familySupervisor', headerName: 'SUPERVISOR_NAME', minWidth: 150 },
  { field: 'orphan', headerName: 'CANDIDATE_ORPHAN_NAME', minWidth: 150 },
  { field: '', headerName: 'TOTAL_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.totalAmount) },
  { field: '', headerName: 'RECEIVED_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.totalReciveAmount) },
  { field: '', headerName: 'REMAINING_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.totalRemainingAmount) },
  { field: '', headerComponent: CustomHeader, headerComponentParams: { data: ['ARREARS'], symbol: 'IQD' }, minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.arrears) },
  { field: 'isSupportAmount', headerName: 'FIRST_RECEIVE', minWidth: 130, cellRenderer: CheckboxRenderer, cellRendererParams: (params: any) => {
    return { label: pricePipe.transform(params.data.supportAmountPayment), disabled: true }
  }},
  { field: 'action', headerName: '', pinned: 'left', type: 'dropdown', minWidth: 85, cellRenderer: DropdownButtonRenderer, cellRendererParams: { dropdownItems: ['EDIT_REQUEST', 'STOP_REQUEST_2'] }}
];

export const SponsorshipStopRequestCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'requestCode', headerName: 'REQUEST_CODE', minWidth: 130 },
  { field: 'sponsorshipRequestCode', headerName: 'CODE_SPONSORSHIP_REQUEST', minWidth: 130 },
  { field: 'requestDate', headerName: 'STOP_REQUEST_DATE', minWidth: 130, cellRenderer: DateRenderer },
  { field: '', headerName: 'FAMILY_CODE', minWidth: 130, cellRenderer: (params: any) => params.data.orphanFamilyInfo.code },
  { field: '', headerName: 'SUPERVISOR_NAME', minWidth: 150, cellRenderer: (params: any) => params.data.orphanFamilyInfo.supervisor },
  { field: '', headerName: 'CANDIDATE_ORPHAN_CODE', minWidth: 130, cellRenderer: (params: any) => params.data.orphanFamilyInfo.familyMemberCode },
  { field: 'orphan', headerName: 'CANDIDATE_ORPHAN_NAME', minWidth: 150 },
  { field: 'sponsorCode', headerName: 'SPONSOR_CODE', minWidth: 130 },
  { field: 'sponsor', headerName: 'SPONSOR_NAME', minWidth: 150 },
  { field: 'reason', headerName: 'CANCEL_REASON', minWidth: 130 },
  { field: '', headerName: 'STATUS', pinned: 'left', minWidth: 190, cellRenderer: StatusRenderer, cellRendererParams: (params: any) => {
    params.data.statusName = SponsorshipPaySalaryRequestStatuses2[params.data.lastStatus];
    switch (params.data.lastStatus) {
      case 1: params.data.theme = 'yellow'; break;
      case 2: params.data.theme = 'green'; break;
      case 4: params.data.theme = 'red'; break;
      default: break;
    }
  }},
  { field: 'action', headerName: '', pinned: 'left', minWidth: 85, suppressSizeToFit: true, cellRenderer: DropdownButtonRenderer, cellRendererParams: (params: any) => {
    let dropdownItems = ['VIEW'];
    switch (params.data.lastStatus) {
      case 1: dropdownItems.push('EDIT', 'REVIEW', 'DELETE'); break;
      default: break;
    }
    return { dropdownItems: dropdownItems };
  }}
];

export const SponsorshipEditRequestAmountCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'requestCode', headerName: 'REQUEST_CODE', minWidth: 130 },
  { field: 'sponsorshipRequestCode', headerName: 'CODE_SPONSORSHIP_REQUEST', minWidth: 130 },
  { field: 'requestDate', headerName: 'EDIT_REQUEST_DATE', minWidth: 130, cellRenderer: DateRenderer },
  { field: '', headerName: 'FAMILY_CODE', minWidth: 130, cellRenderer: (params: any) => params.data.orphanFamilyMemberInfo.code },
  { field: '', headerName: 'SUPERVISOR_NAME', minWidth: 150, cellRenderer: (params: any) => params.data.orphanFamilyMemberInfo.supervisor },
  { field: '', headerName: 'CANDIDATE_ORPHAN_CODE', minWidth: 130, cellRenderer: (params: any) => params.data.orphanFamilyMemberInfo.familyMemberCode },
  { field: '', headerName: 'CANDIDATE_ORPHAN_NAME', minWidth: 150, cellRenderer: (params: any) => params.data.sponsorshipData.orphanFullName },
  { field: '', headerName: 'SPONSOR_CODE', minWidth: 130, cellRenderer: (params: any) => params.data.sponsorshipData.sponsorCode },
  { field: '', headerName: 'SPONSOR_NAME', minWidth: 150, cellRenderer: (params: any) => params.data.sponsorshipData.sponsorFullName },
  { field: '', headerName: 'PREVIOUS_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.lastContractInfo.amount) },
  { field: '', headerName: 'NEW_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.amount) },
  { field: 'reason', headerName: 'EDIT_REASON', minWidth: 130 },
  { field: '', headerName: 'STATUS', pinned: 'left', minWidth: 190, cellRenderer: StatusRenderer, cellRendererParams: (params: any) => {
    params.data.statusName = SponsorshipPaySalaryRequestStatuses[params.data.lastStatus];
    switch (params.data.lastStatus) {
      case 1: params.data.theme = 'yellow'; break;
      case 2: params.data.theme = 'green'; break;
      case 3: params.data.theme = 'red'; break;
      default: break;
    }
  }},
  { field: 'action', headerName: '', pinned: 'left', minWidth: 85, suppressSizeToFit: true, cellRenderer: DropdownButtonRenderer, cellRendererParams: (params: any) => {
    let dropdownItems = ['VIEW'];
    switch (params.data.lastStatus) {
      case 1: dropdownItems.push('EDIT', 'REVIEW', 'DELETE'); break;
      default: break;
    }
    return { dropdownItems: dropdownItems };
  }}
];

export const SponsorshipReceiveReceiptListCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'branch', headerName: 'BRANCH', minWidth: 150 },
  { field: 'receiptCode', headerName: 'RECEIVE_RECEIPT_CODE', minWidth: 180 },
  { field: 'sponsorCode', headerName: 'SPONSOR_CODE', minWidth: 180 },
  { field: 'sponsor', headerName: 'SPONSOR_NAME', minWidth: 180 },
  { field: 'receiptDate', headerName: 'RECEIVE_DATE', minWidth: 130, cellRenderer: DateRenderer },
  { field: 'agentFullName', headerName: 'BRANCH_AGENT', minWidth: 180 },
  { field: '', headerName: 'AMOUNT_AR', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.totalAmount) },
  { field: 'action', headerName: '', pinned: 'left', type: 'dropdown', minWidth: 85, cellRenderer: DropdownButtonRenderer, cellRendererParams: { dropdownItems: ['VIEW', 'PRINT', 'DELETE'] }}
];

export const SponsorshipCreateReceiveReceiptCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'requestCode', headerName: 'REQUEST_CODE', minWidth: 130 },
  { field: 'familyCode', headerName: 'FAMILY_CODE', minWidth: 130 },
  { field: 'familySupervisor', headerName: 'SUPERVISOR_NAME', minWidth: 150 },
  { field: 'orphanCode', headerName: 'CANDIDATE_ORPHAN_CODE', minWidth: 130 },
  { field: 'orphan', headerName: 'CANDIDATE_ORPHAN_NAME', minWidth: 150 },
  { field: '', headerName: 'TOTAL_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.totalAmount || 0) },
  { field: '', headerName: 'RECEIVED_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.totalReciveAmount || 0) },
  { field: '', headerName: 'REMAINING_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.totalRemainingAmount || 0) },
  { field: '', headerComponent: CustomHeader, headerComponentParams: { data: ['ARREARS'], symbol: 'IQD' }, minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.arrears || 0) },
  { field: 'isSupportAmount', headerName: 'FIRST_RECEIVE', pinned: 'left', minWidth: 130, cellRenderer: CheckboxRenderer, cellRendererParams: (params: any) => {
    return { label: pricePipe.transform(params.data.supportAmountPayment || 0), disabled: true }
  }},
  { field: 'receiveAmount', headerName: 'RECEIVE_AMOUNT', pinned: 'left', minWidth: 130, editable: true,
    valueParser: (params: any) => params.newValue ? pricePipe.transform(typeof params.newValue === 'string' ? +params.newValue.replaceAll(',', '') : params.newValue) : 0,
    cellClass: (params: any) => 'input-grid w-fix-0' + ((params.data.isSupportAmount && !params.data.totalRemainingAmount) || (!params.data.isSupportAmount && !params.data.supportAmountPayment) ? ' disabled' : '')
  },
  // { field: 'action', headerName: '', pinned: 'left', minWidth: 150, cellRenderer: ButtonRenderer, cellRendererParams: { type: 'fill', color: 'blue', text: 'RECEIVE_DETAIL', action: 'receive_detail' }}
];

export const SponsorshipViewReceiveReceiptCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'requestCode', headerName: 'REQUEST_CODE', width: 130, suppressSizeToFit: true },
  { field: '', headerName: 'FAMILY_CODE', width: 130, suppressSizeToFit: true, cellRenderer: (params: any) => params.data.orphanFamilyInfo.code },
  { field: '', headerName: 'SUPERVISOR_NAME', minWidth: 180, cellRenderer: (params: any) => params.data.orphanFamilyInfo.supervisor },
  { field: '', headerName: 'CANDIDATE_ORPHAN_CODE', width: 130, suppressSizeToFit: true, cellRenderer: (params: any) => params.data.orphanFamilyInfo.familyMemberCode },
  { field: 'orphan', headerName: 'CANDIDATE_ORPHAN_NAME', minWidth: 180 },
  // { field: '', headerName: 'TOTAL_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.x) },
  // { field: '', headerName: 'RECEIVED_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.x) },
  // { field: '', headerName: 'REMAINING_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.x) },
  // { field: '', headerComponent: CustomHeader, headerComponentParams: { data: ['ARREARS'], symbol: 'IQD' }, minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.x) },
  { field: 'isSupportAmount', headerName: 'RECEIVE_AMOUNT', minWidth: 130, cellRenderer: CheckboxRenderer, cellRendererParams: (params: any) => {
    return { label: pricePipe.transform(params.data.amount || 0), disabled: true }
  }},
];

export const SponsorshipPrintReceiveReceiptCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'requestCode', headerName: 'REQUEST_CODE', minWidth: 130 },
  { field: '', headerName: 'FAMILY_CODE', minWidth: 130, cellRenderer: (params: any) => params.data.orphanFamilyInfo.code },
  { field: '', headerName: 'SUPERVISOR_NAME', minWidth: 150, cellRenderer: (params: any) => params.data.orphanFamilyInfo.supervisor },
  { field: '', headerName: 'CANDIDATE_ORPHAN_CODE', minWidth: 130, cellRenderer: (params: any) => params.data.orphanFamilyInfo.familyMemberCode },
  { field: 'orphan', headerName: 'CANDIDATE_ORPHAN_NAME', minWidth: 150 },
  // { field: '', headerName: 'TOTAL_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.x) },
  // { field: '', headerName: 'RECEIVED_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.x) },
  // { field: '', headerName: 'REMAINING_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.x) },
  // { field: '', headerComponent: CustomHeader, headerComponentParams: { data: ['ARREARS'], symbol: 'IQD' }, minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.x) },
  { field: 'isSupportAmount', headerName: 'RECEIVE_AMOUNT', minWidth: 130, template: 'checkbox', type: 'number', label: 'amount' },
];

export const SponsorshipSalaryWaitingList = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  // { field: 'check', headerName: 'SELECT', pinned: 'right', minWidth: 80, suppressSizeToFit: true, cellRenderer: CheckboxRenderer, cellRendererParams: { disabled: true }},
  { field: 'branch', headerName: 'BRANCH', minWidth: 150 },
  { field: 'familyCode', headerName: 'FAMILY_CODE', minWidth: 130 },
  { field: 'supervisor', headerName: 'SUPERVISOR_NAME', minWidth: 150 },
  { field: 'supervisorPhone', headerName: 'SUPERVISOR_PHONE', minWidth: 130 },
  { field: 'action', headerName: '', pinned: 'left', minWidth: 85, cellRenderer: ButtonRenderer, cellRendererParams: { type: 'fill', color: 'blue', size: 'sm', text: 'PAY', action: 'pay' }}
];

export const SponsorshipPaySalaryRequestListCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'branch', headerName: 'BRANCH', minWidth: 150 },
  { field: 'requestCode', headerName: 'REQUEST_CODE', minWidth: 130 },
  { field: 'fiscalYear', headerName: 'FISCAL_YEAR', minWidth: 150 },
  { field: 'salaryPeriod', headerName: 'FISCAL_PERIOD', minWidth: 150 },
  { field: 'createDate', headerName: 'REQUEST_DATE', minWidth: 130, cellRenderer: DateRenderer },
  { field: 'registerer', headerName: 'REGISTERAR', minWidth: 150 },
  { field: '', headerName: 'TOTAL_AMOUNT_AR', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.totalRemainingSalary) },
  { field: '', headerName: 'STATUS', pinned: 'left', minWidth: 190, cellRenderer: StatusRenderer, cellRendererParams: (params: any) => {
    params.data.statusName = SponsorshipPaySalaryRequestStatuses[params.data.lastStatus.status];
    switch (params.data.lastStatus.status) {
      case 1: params.data.theme = 'yellow'; break;
      case 2: params.data.theme = 'green'; break;
      case 3: params.data.theme = 'red'; break;
      default: break;
    }
  }},
  { field: 'action', headerName: '', pinned: 'left', minWidth: 85, suppressSizeToFit: true, cellRenderer: DropdownButtonRenderer, cellRendererParams: (params: any) => {
    let dropdownItems = [];
    switch (params.data.lastStatus.status) {
      case 1: dropdownItems = ['VIEW', 'EDIT', 'REVIEW', 'DELETE']; break;
      case 2: dropdownItems = ['VIEW']; break;
      case 3: dropdownItems = ['VIEW']; break;
      default: break;
    }
    return { dropdownItems: dropdownItems };
  }}
];

export const SponsorshipSalaryPayReportCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'code', headerName: 'PAY_CODE', minWidth: 130 },
  { field: 'branch', headerName: 'BRANCH', minWidth: 150 },
  { field: 'date', headerName: 'PAY_DATE_AND_TIME', minWidth: 130, cellRenderer: DateRenderer },
  { field: '', headerName: 'FAMILY_CODE', minWidth: 130, cellRenderer: (params: any) => params.data.orphanFamily.code },
  { field: '', headerName: 'SUPERVISOR_NAME', minWidth: 150, cellRenderer: (params: any) => params.data.orphanFamily.supervisor },
  { field: '', headerName: 'SUPERVISOR_PHONE', minWidth: 130, cellRenderer: (params: any) => params.data.orphanFamily.supervisorPhone },
  /* { field: '', headerName: 'ACCOUNTING_DOCUMENT_NUMBER', minWidth: 100, type: 'link', cellRenderer: LinkRenderer, cellRendererParams: (params: any) => {
    return { url: accountingSiteUrl + '/#/app/newdoc2/' + params.data.x };
  }}, */
  { field: 'employee', headerName: 'PAYER', minWidth: 150 },
  { field: 'action', headerName: '', pinned: 'left', type: 'dropdown', minWidth: 85, cellRenderer: DropdownButtonRenderer, cellRendererParams: { dropdownItems: ['VIEW', 'DELETE'] }}
];

export const SponsorCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60 },
  { field: 'sponsorCode', headerName: 'SPONSOR_CODE', minWidth: 130 },
  { field: 'sponsorFullName', headerName: 'FULL_NAME', minWidth: 150 },
  { field: 'createDate', headerName: 'CREATE_DATE', minWidth: 130, cellRenderer: DateRenderer },
  { field: '', headerName: 'ANCESTRY', minWidth: 130, cellRenderer: (params: any) => translatePipe.transform(Ancestry[params.data.ancestry]) },
  { field: 'job', headerName: 'JOB', minWidth: 150 },
  { field: 'phone', headerName: 'PHONE', minWidth: 150 },
  { field: 'email', headerName: 'EMAIL', minWidth: 150 },
  { field: 'totalActiveOrphan', headerName: 'ORPHANS_WITH_SPONSOR_COUNT', minWidth: 130 },
  { field: '', headerName: 'TOTAL_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.totalRequestAmount) },
  { field: '', headerName: 'RECEIVED_TOTAL_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.totalReciveAmount) },
  { field: '', headerName: 'REMAINING_TOTAL_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.totalRemainingAmount) },
  { field: '', headerName: 'ARREARS_TOTAL_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.totalDelayedAmount) },
  { field: '', headerName: 'STATUS', pinned: 'left', minWidth: 190, cellRenderer: StatusRenderer, cellRendererParams: (params: any) => {
    params.data.statusName = ActiveStatuses[params.data.isActive ? 1 : 0];
    params.data.theme = params.data.isActive ? 'green' : 'red';
  }},
  { field: 'action', headerName: '', pinned: 'left', minWidth: 85, cellRenderer: DropdownButtonRenderer, cellRendererParams: { dropdownItems: ['EDIT', 'ADD_NEW_SPONSORSHIP_REQUEST', 'RECEIVE'] }}
];

export const SponsorshipOrphanSalaryPayCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'check', headerName: 'SELECT', pinned: 'right', minWidth: 80, suppressSizeToFit: true, cellRenderer: CheckboxRenderer, cellRendererParams: { disabled: false }},
  { field: 'orphan', headerName: 'CANDIDATE_ORPHAN_NAME', minWidth: 150 },
  { field: 'sponsor', headerName: 'SPONSOR_NAME', minWidth: 150 },
  // { field: '', headerName: 'DUE_DATE', minWidth: 130, cellRenderer: DateRenderer },
  { field: '', headerName: 'ARREARS_TOTAL_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.amount) }
]

export const SponsorshipOrphansPayRequestCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  // { field: '', headerName: 'BRANCH', minWidth: 130 },
  { field: '', headerName: 'FAMILY_CODE', minWidth: 130, cellRenderer: (params: any) => params.data.familyInfo.code },
  { field: '', headerName: 'SUPERVISOR_NAME', minWidth: 150, cellRenderer: (params: any) => params.data.familyInfo.supervisor },
  { field: '', headerName: 'CANDIDATE_ORPHAN_CODE', minWidth: 130, cellRenderer: (params: any) => params.data.familyInfo.familyMemberCode },
  { field: '', headerName: 'CANDIDATE_ORPHAN_NAME', minWidth: 150, cellRenderer: (params: any) => params.data.orphanInfo.fullName },
  { field: '', headerName: 'BENEFICIARY_TYPE', minWidth: 130 },
  { field: '', headerName: 'ANCESTRY', minWidth: 130, cellRenderer: (params: any) => translatePipe.transform(Ancestry[params.data.orphanInfo.ancestry]) },
  { field: '', headerName: 'SALARY_AMOUNT', minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.remainingSalary) },
  { field: '', headerComponent: CustomHeader, headerComponentParams: { data: ['SPONSOR_PAY'], symbol: 'IQD' }, minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.x) },
  { field: '', headerComponent: CustomHeader, headerComponentParams: { data: ['PAY_FROM_PROVIDE'], symbol: 'IQD' }, minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.x) },
  { field: '', headerComponent: CustomHeader, headerComponentParams: { data: ['PAY_FROM_SELL'], symbol: 'IQD' }, minWidth: 130, cellRenderer: (params: any) => pricePipe.transform(params.data.x) }
];

export const TransferInBranchesCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'donationTree', headerName: 'PROJECT_TREE', minWidth: 180 },
  { field: '', headerComponent: CustomHeader, headerComponentParams: { data: ['INVENTORY'], symbol: 'IQD' }, minWidth: 130, suppressSizeToFit: true, cellClass: 'direction-ltr d-block',
    cellRenderer: (params: any) => pricePipe.transform(params.data.balance || 0)
  },
  { field: '', headerName: '', pinned: 'left', minWidth: 85, suppressSizeToFit: true , cellRenderer: ButtonRenderer, cellRendererParams: { type: 'fill', color: 'blue', text: 'TRANSFER', action: 'transfer' }}
];

export const VoidReceiptListCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'branch', headerName: 'BRANCH', minWidth: 150, suppressSizeToFit: true },
  { field: 'receiptNumber', headerName: 'RECEIPT_PAPER_NUMBER', minWidth: 150, suppressSizeToFit: true },
  { field: 'receiptType', headerName: 'TYPE', minWidth: 100, suppressSizeToFit: true, cellRenderer: (params: any) => translatePipe.transform(AidTypes[params.data.receiptType]) },
  { field: 'receiptDate', headerName: 'DATE', minWidth: 130, suppressSizeToFit: true, cellRenderer: DateRenderer },
  { field: 'agentUser', headerName: 'AGENT', minWidth: 180 },
  { field: 'description', headerName: 'DESCRIPTION', minWidth: 200 },
  { field: 'action', headerName: '', pinned: 'left', type: 'dropdown', minWidth: 85, suppressSizeToFit: true, cellRenderer: DropdownButtonRenderer, cellRendererParams: { dropdownItems: ['EDIT', 'DELETE'] }}
];

export const ReceivesModalCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'donationTree', headerName: 'INCOME', minWidth: 200, suppressSizeToFit: true },
  { field: 'titleTree', headerName: 'CASH_TREE_FIELD', minWidth: 180, suppressSizeToFit: true, cellRenderer: (params: any) => params.data.cashTree || params.data.product || params.data.service },
  { field: 'amount', headerName: 'TOTAL_AMOUNT', minWidth: 100, suppressSizeToFit: true, cellRenderer: (params: any) => pricePipe.transform(params.data.amount) },
  { field: 'remainingAmount', headerName: 'REMAINING_AMOUNT', minWidth: 100, suppressSizeToFit: true, cellRenderer: (params: any) => pricePipe.transform(params.data.remainingAmount) },
  { field: 'receivedAmount', headerName: 'RECEIVED_AMOUNT', minWidth: 100, suppressSizeToFit: true, cellRenderer: (params: any) => pricePipe.transform(params.data.receivedAmount) }
  // { field: 'receivedAmount', headerName: 'RECEIVED_AMOUNT', minWidth: 100, suppressSizeToFit: true, editable: true, cellClass: 'input-grid w-fix-0',
  //   valueParser: (params: any) => params.newValue ? pricePipe.transform(typeof params.newValue === 'string' ? +params.newValue.replaceAll(',', '') : params.newValue) : 0,
  //   cellRenderer: (params: any) => params.data.receivedAmount ? pricePipe.transform(typeof params.data.receivedAmount === 'string' ? +params.data.receivedAmount.replaceAll(',', '') : params.data.receivedAmount) : 0
  // }
];

export const AgentListCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'branch', headerName: 'BRANCH', minWidth: 150 },
  { field: 'fullName', headerName: 'AGENT_NAME', minWidth: 150 },
  { field: 'personnelCode', headerName: 'PERSONNEL_CODE', minWidth: 150 },
  { field: '', headerName: 'ORGANIZATIONAL_POSITION', minWidth: 150 },
  { field: 'startDate', headerName: 'START_DATE_OF_COOPERATION', minWidth: 130, cellRenderer: DateRenderer },
  { field: 'baseSalaryAmount', headerName: 'base_salary', minWidth: 100, cellRenderer: (params: any) => pricePipe.transform(params.data.baseSalaryAmount) || 0 },
  { field: 'amountOfMinimumIncomePerDay', headerName: 'MINIMUM_INCOME_IN_DAY', minWidth: 100, cellRenderer: (params: any) => pricePipe.transform(params.data.amountOfMinimumIncomePerDay) || 0 },
  { field: 'agentIncomeCount', headerName: 'COUNT_MONTHS', minWidth: 80 },
  { field: 'monthIncomeRatio', headerName: 'OPTIMAL_INCOME_RATIO', minWidth: 80 },
  { field: 'minimumAllowedRatio', headerName: 'MINIMUM_ALLOWED_INCOME_RATIO', minWidth: 80 },
  { field: '', headerName: 'STATUS', pinned: 'left', minWidth: 190, cellRenderer: StatusRenderer, cellRendererParams: (params: any) => {
    params.data.statusName = ActiveStatuses[params.data.isActive ? 1 : 0];
    params.data.theme = params.data.isActive ? 'green' : 'red';
  }},
  { field: 'action', headerName: '', pinned: 'left', type: 'dropdown', minWidth: 85, suppressSizeToFit: true,
    cellRenderer: DropdownButtonRenderer, cellRendererParams: { dropdownItems: ['EDIT', 'CHANGE_STATUS', 'DELETE'] }
  }
];

export const AgentSalaryListCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'branch', headerName: 'BRANCH', minWidth: 150 },
  { field: 'salaryPeriod', headerName: 'SALARY_PERIODS', minWidth: 150 },
  { field: 'agentFullName', headerName: 'AGENT_NAME', minWidth: 150 },
  { field: 'personnelCode', headerName: 'PERSONNEL_CODE', minWidth: 150 },
  { field: 'functionalIncomeRatio', headerName: 'SALARY_INCOME_RATIO', minWidth: 80 },
  { field: 'countOfDaysWorked', headerName: 'COUNT_SALARY_DAYS', minWidth: 80 },
  { field: 'baseSalaryAmount', headerName: 'base_salary', minWidth: 100, cellRenderer: (params: any) => pricePipe.transform(params.data.baseSalaryAmount) || 0 },
  { field: 'baseSalaryReceivedAmount', headerName: 'RECEIVED_SALARY', minWidth: 100, cellRenderer: (params: any) => pricePipe.transform(params.data.baseSalaryReceivedAmount) || 0 },
  { field: 'finalSponsorshipReward', headerName: 'SPONSORSHIP_REWARD', minWidth: 100, cellRenderer: (params: any) => pricePipe.transform(params.data.finalSponsorshipReward) || 0 },
  { field: 'finalDonationReward', headerName: 'DONATION_REWARD', minWidth: 100, cellRenderer: (params: any) => pricePipe.transform(params.data.finalDonationReward) || 0 },
  { field: 'exteraReward', headerName: 'CONFERENCES_REWARD', minWidth: 100, cellRenderer: (params: any) => pricePipe.transform(params.data.exteraReward) || 0 },
  { field: 'fine', headerName: 'PENALTY', minWidth: 100, cellRenderer: (params: any) => pricePipe.transform(params.data.fine) || 0 },
  { field: '', headerName: 'ALERT', minWidth: 80, cellRenderer: (params: any) => {
    return params.data.warningCount ? '<i class="bx bx-check font-size-20 fw-bolder"> </i>' : '<i class="bx bx-x font-size-20 fw-bolder"> </i>';
  }},
  { field: 'calculatedSalary', headerName: 'TOTAL_FINAL', minWidth: 100, cellRenderer: (params: any) => pricePipe.transform(params.data.calculatedSalary) || 0 },
  { field: '', headerName: 'STATUS', pinned: 'left', minWidth: 190, cellRenderer: StatusRenderer, cellRendererParams: (params: any) => {
    params.data.statusName = ApprovalStatuses2[params.data.lastStatus.status];
    switch (params.data.lastStatus.status) {
      case 1: params.data.theme = 'yellow'; break;
      case 2: params.data.theme = 'green'; break;
      default: break;
    }
    return params;
  }},
  { field: 'action', headerName: '', pinned: 'left', minWidth: 85, suppressSizeToFit: true, cellRenderer: DropdownButtonRenderer, cellRendererParams: (params: any) => {
    let dropdownItems = [];
    switch (params.data.lastStatus.status) {
      case 1: dropdownItems = ['UPDATE_SALARY', 'REPORT_SALARY', 'FINAL_APPROVAL_2']; break;
      case 2: dropdownItems = ['REPORT_SALARY']; break;
      default: break;
    }
    return { dropdownItems: dropdownItems };
  }}
];

export const AgentSalaryListPrintCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'branch', headerName: 'BRANCH', minWidth: 150, class: 'col-4' },
  { field: 'salaryPeriod', headerName: 'SALARY_PERIODS', minWidth: 150, class: 'col-4' },
  { field: 'agentFullName', headerName: 'AGENT_NAME', minWidth: 150, class: 'col-4' },
  { field: 'personnelCode', headerName: 'PERSONNEL_CODE', minWidth: 150, class: 'col-4' },
  { field: 'baseSalaryAmount', headerName: 'BASE_AMOUNT_OF_RULE', minWidth: 100, class: 'col-4', cellRenderer: (params: any) => pricePipe.transform(params.data.baseSalaryAmount) || 0 },
  { field: 'amountOfMinimumIncomePerDay', headerName: 'MINIMUM_DAILY_INCOME', minWidth: 100, class: 'col-4', cellRenderer: (params: any) => pricePipe.transform(params.data.amountOfMinimumIncomePerDay) || 0 },
  { field: 'monthIncomeRatio', headerName: 'OPTIMAL_INCOME_RATIO', minWidth: 80, class: 'col-4' },
  { field: 'functionalIncomeRatio', headerName: 'AGENT_INCOME_RATIO', minWidth: 80, class: 'col-4' },
  { field: 'minimumAllowedRatio', headerName: 'MINIMUM_ALLOWED_INCOME_RATIO', minWidth: 80, class: 'col-4' },
  { field: 'countOfDaysWorked', headerName: 'COUNT_SALARY_DAYS', minWidth: 80, class: 'col-4' },
  { field: 'baseSalaryReceivedAmount', headerName: 'RECEIVED_SALARY', minWidth: 100, IQD: false, class: 'col-4', cellRenderer: (params: any) => pricePipe.transform(params.data.baseSalaryReceivedAmount) || 0 },
  { field: 'fine', headerName: 'PENALTY', minWidth: 100, class: 'col-4', cellRenderer: (params: any) => pricePipe.transform(params.data.fine) || 0 },
  { field: 'finalSponsorshipReward', headerName: 'SPONSORSHIP_REWARD', minWidth: 100, class: 'col-4', cellRenderer: (params: any) => pricePipe.transform(params.data.finalSponsorshipReward) || 0 },
  { field: 'finalDonationReward', headerName: 'DONATION_REWARD', minWidth: 100, class: 'col-4', cellRenderer: (params: any) => pricePipe.transform(params.data.finalDonationReward) || 0 },
  { field: 'exteraReward', headerName: 'CONFERENCES_REWARD', minWidth: 100, class: 'col-4', cellRenderer: (params: any) => pricePipe.transform(params.data.exteraReward) || 0 },
  { field: 'calculatedSalary', headerName: 'TOTAL_FINAL', minWidth: 100, class: 'col-4', cellRenderer: (params: any) => pricePipe.transform(params.data.calculatedSalary) || 0 },
  { field: 'status', headerName: 'STATUS', pinned: 'left', minWidth: 190, class: 'col-4', cellRenderer: StatusRenderer, cellRendererParams: (params: any) => {
    params.data.statusName = ApprovalStatuses2[params.data.lastStatus.status];
    switch (params.data.lastStatus.status) {
      case 1: params.data.theme = 'yellow'; break;
      case 2: params.data.theme = 'green'; break;
      default: break;
    }
    return params;
  }}
];

export const AgentWarningListCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'createDate', headerName: 'DATE', minWidth: 130, cellRenderer: DateRenderer },
  { field: 'salaryPeriod', headerName: 'SALARY_PERIODS', minWidth: 150 },
  { field: 'fullName', headerName: 'REGISTERAR', minWidth: 150 },
  { field: 'descripion', headerName: 'DESCRIPTION', minWidth: 200 },
];

export const AgentReceiveCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'receiptDate', headerName: 'RECEIVE_DATE', minWidth: 120, suppressSizeToFit: true, sortable: true, cellRenderer: (params: any) => datePipe.transform(params.data.receiptDate, 'YYYY-MM-dd') },
  { field: 'paperReceiptNumber', headerName: 'RECEIPT_PAPER_NUMBER', minWidth: 120, suppressSizeToFit: true, sortable: true },
  { field: 'receiptType', headerName: 'RECEIVE_TYPE', minWidth: 150, suppressSizeToFit: true, cellRenderer: (params: any) => translatePipe.transform(IncomeTypes[params.data.incomeType]) },
  { field: 'amount', headerName: 'AMOUNT', minWidth: 100, suppressSizeToFit: true, cellRenderer: (params: any) => pricePipe.transform(params.data.amount) },
  { field: 'fullName', headerName: 'AGENT_NAME', minWidth: 180 }
];

export const ProgramRequestListCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: '', headerName: 'REQUEST_DATE', minWidth: 0, cellRenderer: DateRenderer },
  { field: '', headerName: 'BRANCH', minWidth: 0 },
  { field: '', headerName: 'PROGRAM', minWidth: 0 },
  { field: '', headerName: 'FestivalTypes', minWidth: 0 },
  { field: '', headerName: 'PROGRAM_CODE', minWidth: 0 },
  { field: '', headerName: 'PROGRAM_SLOGAN', minWidth: 0 },
  { field: '', headerName: 'OPERATION_PLACE', minWidth: 0 },
  { field: '', headerName: 'START_DATE', minWidth: 0, cellRenderer: DateRenderer },
  { field: '', headerName: 'END_DATE', minWidth: 0, cellRenderer: DateRenderer },
  { field: '', headerName: 'PROGRAM_TARGET', minWidth: 0 },
  { field: '', headerName: 'SUM_AID', minWidth: 0, cellRenderer: (params: any) => pricePipe.transform(params.data.x) || 0 },
  { field: '', headerName: 'ADMINISTRATIVE_COSTS', minWidth: 0, cellRenderer: (params: any) => pricePipe.transform(params.data.x) || 0 },
  { field: '', headerName: 'PROCUREMENT_COSTS', minWidth: 0, cellRenderer: (params: any) => pricePipe.transform(params.data.x) || 0 },
  { field: 'status', headerName: 'STATUS', pinned: 'left', minWidth: 190, cellRenderer: StatusRenderer, cellRendererParams: (params: any) => {
    params.data.statusName = ProgramRequestStatuses[params.data.x];
    switch (params.data.x) {
      case 1: params.data.theme = ''; break;
      case 2: params.data.theme = ''; break;
      case 3: params.data.theme = ''; break;
      case 4: params.data.theme = ''; break;
      default: break;
    }
    return params;
  }},
  { field: 'action', headerName: '', pinned: 'left', type: 'dropdown', minWidth: 85, suppressSizeToFit: true,
    cellRenderer: DropdownButtonRenderer, cellRendererParams: { dropdownItems: ['EDIT', 'VIEW', 'PRINT', 'DELETE'] }
  }
];

export const TeamListCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'branch', headerName: 'BRANCH', minWidth: 150 },
  { field: 'title', headerName: 'TITLE', minWidth: 150 },
  { field: 'teamType', headerName: 'TYPE', minWidth: 100, cellRenderer: (params: any) => translatePipe.transform(TeamTypes[params.data.teamType]) },
  { field: 'mnagerFullName', headerName: 'RESPONSIBLE', minWidth: 150 },
  { field: 'employeesOther', headerName: 'OTHER_EMPLOYEES', minWidth: 200 },
  { field: 'others', headerName: 'OTHER_MEMBERS', minWidth: 200 },
  { field: 'action', headerName: '', pinned: 'left', type: 'dropdown', minWidth: 85, suppressSizeToFit: true,
    cellRenderer: DropdownButtonRenderer, cellRendererParams: { dropdownItems: ['VIEW', 'EDIT', 'DELETE'] }
  }
];

export const BeneficiaryListCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'familyCode', headerName: 'FAMILY_CODE', minWidth: 200, type: 'link', cellRenderer: LinkRenderer,
    cellRendererParams: (params: any) => { return { url: siteUrl + '/family/family-information/' + params.data.familyIdentifier }}
  },
  { field: 'familyMemberCode', headerName: 'MEMBER_CODE', minWidth: 150 },
  { field: 'fullName', headerName: 'MEMBER_NAME', minWidth: 150 },
  { field: 'action', headerName: '', minWidth: 85, pinned: 'left', cellRenderer: ButtonRenderer,
    cellRendererParams: { type: 'fill', color: 'danger', text: 'DELETE', action: 'delete' }
  }
];

export const PermissionGroupListCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'title', headerName: 'TITLE', minWidth: 150 },
  { field: 'action', headerName: '', pinned: 'left', type: 'dropdown', minWidth: 85, suppressSizeToFit: true,
    cellRenderer: DropdownButtonRenderer, cellRendererParams: { dropdownItems: ['EDIT', 'DELETE'] }
  }
];

export const DonationGoodReceiveListCols = [
  { field: '#', headerName: '#', minWidth: 60, pinned: 'right' },
  { field: 'branch', headerName: 'BRANCH', minWidth: 150 },
  { field: 'paperDate', headerName: 'RECEIPT_PAPER_DATE', minWidth: 120, sortable: true, cellRenderer: (params: any) => datePipe.transform(params.data.paperDate, 'YYYY-MM-dd') },
  { field: 'paperNumber', headerName: 'RECEIPT_PAPER_NUMBER', minWidth: 120, sortable: true },
  { field: 'donationTree', headerName: 'PROJECT_TREE', minWidth: 150 },
  { field: 'productTitle', headerName: 'GOOD_NAME', minWidth: 150 },
  { field: 'beneficiaryType', headerName: 'BENEFICIARY_TYPE', minWidth: 150 },
  { field: 'campaign', headerName: 'CAMPAIGN', minWidth: 150 },
  { field: 'tatviriPercent', headerComponent: CustomHeader, headerComponentParams: { data: ['TATVIRI_DONATION_PERCENTAGE'], symbol: '%' }, minWidth: 120,
    cellRenderer: (params: any) => params.data.tatviriPercent
  },
  { field: 'count', headerName: 'COUNT', minWidth: 120 },
  { field: 'totalAmount', headerName: 'TOTAL_AMOUNT_AR', minWidth: 120, cellRenderer: (params: any) => pricePipe.transform(params.data.totalAmount) },
  { field: 'agentUser', headerName: 'AGENT', minWidth: 150 },
  { field: 'donorCode', headerName: 'DONOR_CODE', minWidth: 150 },
  { field: 'donor', headerName: 'DONOR', minWidth: 150 },
  { field: 'requestCode', headerName: 'DONATION_REQUEST_CODE', minWidth: 150, type: 'link', cellRenderer: LinkRenderer, cellRendererParams: (params: any) => {
    return { url: siteUrl + '/donation/goods/view-request/' + params.data.objectiveDonationRequestId };
  }},
  { field: 'requestDate', headerName: 'DONATION_REQUEST_DATE', minWidth: 120, cellRenderer: (params: any) => datePipe.transform(params.data.requestDate, 'YYYY-MM-dd') },
  { field: 'warehouseReceiptDate', headerName: 'WAREHOUSE_RECEIPT_DATE', minWidth: 120, cellRenderer: (params: any) => datePipe.transform(params.data.warehouseReceiptDate, 'YYYY-MM-dd') },
  { field: 'transfereeUser', headerName: 'WAREHOUSING_RESPONSIBLE', minWidth: 150 },
  { field: 'evaluationDate', headerName: 'EVALUATION_DATE', minWidth: 120, cellRenderer: (params: any) => datePipe.transform(params.data.evaluationDate, 'YYYY-MM-dd') },
  { field: 'assessorsFullName', headerName: 'EVALUATOR', minWidth: 150 },
  { field: 'confirmDate', headerName: 'CONFIRM_DATE', minWidth: 120, cellRenderer: (params: any) => datePipe.transform(params.data.confirmDate, 'YYYY-MM-dd') },
  { field: 'corroborantName', headerName: 'CORROBORANT', minWidth: 150 },
  { field: '', headerName: 'ACCOUNTING_DOCUMENT_NUMBER', minWidth: 120, type: 'link', cellRenderer: LinkRenderer, cellRendererParams: (params: any) => {
    return { url: accountingSiteUrl + "/#/app/newdoc2/" + params.data.x };
  }},
  { field: '', headerName: 'STATUS', minWidth: 250, pinned: 'left', type: 'status', cellRenderer: StatusRenderer, cellRendererParams: (params: any) => {
    params.data.statusName = GoodDonationRequestStatuses[params.data.lastStatus.status];
    switch (params.data.lastStatus.status) {
      case 1: params.data.theme = 'yellow'; break;
      case 2: params.data.theme = 'yellow-pink'; break;
      case 3: params.data.theme = 'brown'; break;
      case 4: params.data.theme = 'orange'; break;
      case 5: params.data.theme = 'green'; break;
      default: break;
    }
    return params;
  }}
];

export const DonationServiceReceiveListCols = [
  { field: '#', headerName: '#', minWidth: 60, pinned: 'right' },
  { field: 'branch', headerName: 'BRANCH', minWidth: 150 },
  { field: 'receiptDate', headerName: 'RECEIPT_PAPER_DATE', minWidth: 120, sortable: true, cellRenderer: (params: any) => datePipe.transform(params.data.receiptDate, 'YYYY-MM-dd') },
  { field: 'receiptNumber', headerName: 'RECEIPT_PAPER_NUMBER', minWidth: 120, sortable: true },
  { field: 'donationTree', headerName: 'PROJECT_TREE', minWidth: 150 },
  { field: 'service', headerName: 'SERVICE', minWidth: 150 },
  { field: 'quantity', headerName: 'ESTIMATED_COUNT', minWidth: 120 },
  { field: 'totalAmount', headerName: 'TOTAL_AMOUNT_AR', minWidth: 120, cellRenderer: (params: any) => pricePipe.transform(params.data.totalAmount) },
  { field: 'beneficiaryType', headerName: 'BENEFICIARY_TYPE', minWidth: 150 },
  { field: 'campaign', headerName: 'CAMPAIGN_OR_FESTIVAL', minWidth: 150, cellRenderer: (params: any) => params.data.campaign || params.data.festival },
  { field: 'agentFullName', headerName: 'AGENT', minWidth: 150 },
  { field: 'donorCode', headerName: 'DONOR_CODE', minWidth: 150 },
  { field: 'donor', headerName: 'DONOR', minWidth: 150 },
  { field: 'requestCode', headerName: 'DONATION_REQUEST_CODE', minWidth: 150, type: 'link', cellRenderer: LinkRenderer, cellRendererParams: (params: any) => {
    return { url: siteUrl + '/donation/services/view-request-2?id=' + params.data.serviceDonationId };
  }},
  { field: 'requestDate', headerName: 'DONATION_REQUEST_DATE', minWidth: 120, cellRenderer: (params: any) => datePipe.transform(params.data.requestDate, 'YYYY-MM-dd') },
  { field: 'evaluationDate', headerName: 'EVALUATION_DATE', minWidth: 120, cellRenderer: (params: any) => datePipe.transform(params.data.evaluationDate, 'YYYY-MM-dd') },
  { field: 'evaluatorsFullName', headerName: 'EVALUATOR', minWidth: 150 },
  { field: 'confirmDate', headerName: 'CONFIRM_DATE', minWidth: 120, cellRenderer: (params: any) => datePipe.transform(params.data.confirmDate, 'YYYY-MM-dd') },
  { field: 'corroborantName', headerName: 'CORROBORANT', minWidth: 150 },
  { field: 'accountingDocumentNumber', headerName: 'ACCOUNTING_DOCUMENT_NUMBER', minWidth: 120, type: 'link', cellRenderer: LinkRenderer, cellRendererParams: (params: any) => {
    return { url: accountingSiteUrl + "/#/app/newdoc2/" + params.data.accountingDocumentNumber };
  }},
  { field: '', headerName: 'STATUS', minWidth: 250, pinned: 'left', type: 'status', cellRenderer: StatusRenderer, cellRendererParams: (params: any) => {
    params.data.statusName = ServiceDonationStatuses[params.data.lastStatus.status];
    switch (params.data.lastStatus.status) {
      case 1: params.data.theme = 'yellow'; break;
      case 2: params.data.theme = 'orange'; break;
      case 3: params.data.theme = 'blue'; break;
      case 4: params.data.theme = 'green'; break;
      case 5: params.data.theme = 'red'; break;
      default: break;
    }
    return params;
  }}
];

export const FixAssetListCols = [
  { field: '#', headerName: '#', minWidth: 60, pinned: 'right' },
  { field: '', headerName: 'BRANCH', minWidth: 150 },
  { field: '', headerName: 'ASSET_GROUP', minWidth: 150 },
  { field: '', headerName: 'PLATE_NUMBER', minWidth: 150 },
  { field: '', headerName: 'BUY_DATE', minWidth: 120, cellRenderer: (params: any) => datePipe.transform(params.data.x, 'YYYY-MM-dd') },
  { field: '', headerName: 'BUY_AMOUNT', minWidth: 120, cellRenderer: (params: any) => pricePipe.transform(params.data.x) },
  { field: '', headerComponent: CustomHeader, headerComponentParams: { data: ['BOOK_VALUE'], symbol: 'IQD' }, minWidth: 120, cellRenderer: (params: any) => pricePipe.transform(params.data.x) },
  { field: '', headerName: 'STATUS', minWidth: 250, pinned: 'left', type: 'status', cellRenderer: StatusRenderer, cellRendererParams: (params: any) => {
    params.data.statusName = FixAssetStatuses[params.data.x];
    switch (params.data.x) {
      case 1: params.data.theme = 'yellow'; break;
      case 2: params.data.theme = 'green'; break;
      case 3: params.data.theme = 'red'; break;
      default: break;
    }
    return params;
  }},
  { field: 'action', headerName: '', pinned: 'left', minWidth: 85, suppressSizeToFit: true, cellRenderer: DropdownButtonRenderer, cellRendererParams: (params: any) => {
    let dropdownItems = [];
    switch (params.data.x) {
      case 1: dropdownItems = ['RE_PRICING']; break;
      case 2: dropdownItems = ['SALE', 'ABORTION_2', 'DELETE']; break;
      case 3: dropdownItems = ['SALE']; break;
      default: break;
    }
    dropdownItems.push('BOOK_VALUE_HISTORY');
    return { dropdownItems: dropdownItems };
  }}
];

export const DepreciationListCols = [
  { field: '#', headerName: '#', minWidth: 60, pinned: 'right' },
  { field: '', headerName: 'BRANCH', minWidth: 150 },
  { field: '', headerName: 'FISCAL_YEAR', minWidth: 150 },
  { field: '', headerName: 'REGISTERAR', minWidth: 150 },
  { field: '', headerName: 'CREATE_DATE', minWidth: 130, cellRenderer: DateRenderer },
  { field: '', headerName: 'ACCOUNTING_DOCUMENT_NUMBER', minWidth: 120, type: 'link', cellRenderer: LinkRenderer, cellRendererParams: (params: any) => {
    return { url: accountingSiteUrl + "/#/app/newdoc2/" + params.data.x };
  }},
  { field: 'action', headerName: '', pinned: 'left', minWidth: 85, type: 'button', cellRenderer: { type: 'fill', color: 'danger', text: 'DELETE', action: 'delete' }}
];

export const FamilyLocationRecordCols = [
  { field: '#', headerName: '#', minWidth: 60, pinned: 'right' },
  { field: 'ownershipType', headerName: 'OWNERSHIP_TYPE', minWidth: 150 },
  { field: 'buildingType', headerName: 'BUILDING_TYPE', minWidth: 150 },
  { field: 'houseArea', headerComponent: CustomHeader, headerComponentParams: { data: ['HOUSE_AREA'], symbol: 'SQUARE_METER' }, minWidth: 120,
    cellRenderer: (params: any) => pricePipe.transform(params.data.houseArea)
  },
  { field: 'houseRentAmount', headerName: 'RENT_AMOUNT', minWidth: 120, cellRenderer: (params: any) => pricePipe.transform(params.data.houseRentAmount) },
  { field: 'familyRentAmount', headerName: 'FAMILY_RENT_AMOUNT', minWidth: 120, cellRenderer: (params: any) => pricePipe.transform(params.data.familyRentAmount) },
  { field: 'residenceStartDate', headerName: 'RESIDENCE_START_DATE', minWidth: 120, cellRenderer: DateRenderer },
  { field: 'residenceEndDate', headerName: 'RESIDENCE_END_DATE', minWidth: 120, cellRenderer: DateRenderer },
  { field: 'geoName', headerName: 'CITY', minWidth: 150 },
  { field: 'action', headerName: '', pinned: 'left', minWidth: 85, cellRenderer: ButtonRenderer,
    cellRendererParams: { type: 'fill', color: 'blue', size: 'sm', text: 'DETAIL', action: 'detail' }
  }
];

export const BookValueHistoryFixAssetCols = [
  { field: '#', headerName: '#', minWidth: 60, pinned: 'right' },
  { field: '', headerName: 'DATE', minWidth: 120, cellRenderer: (params: any) => datePipe.transform(params.data.x, 'YYYY-MM-dd') },
  { field: '', headerComponent: CustomHeader, headerComponentParams: { data: ['NEW_BOOK_VALUE'], symbol: 'IQD' }, minWidth: 120, cellRenderer: (params: any) => pricePipe.transform(params.data.x) },
  { field: 'description', headerName: 'DESCRIPTION', minWidth: 200 },
  { field: '', headerName: 'STATUS', minWidth: 250, pinned: 'left', type: 'status', cellRenderer: StatusRenderer, cellRendererParams: (params: any) => {
    params.data.statusName = BookValueHistoryFixAssetStatuses[params.data.lastStatus.status];
    switch (params.data.lastStatus.status) {
      case 1: params.data.theme = 'green'; break;
      case 2: params.data.theme = 'yellow'; break;
      case 3: params.data.theme = 'orange'; break;
      default: break;
    }
    return params;
  }}
];

export const ExportListCols = [
  { field: '#', headerName: '#', minWidth: 60, suppressSizeToFit: true, pinned: 'right' },
  { field: 'createDate', headerName: 'REQUEST_DATE', minWidth: 120, cellRenderer: (params: any) => datePipe.transform(params.data.createDate, 'YYYY-MM-dd HH:MM:SS') },
  { field: 'action', headerName: 'STATUS', minWidth: 85, suppressSizeToFit: true, pinned: 'left',
    cellRenderer: ButtonRenderer,
    cellRendererParams: (params: any) => {
      return { type: 'fill', color: 'blue', size: 'sm', text: params.data.exists ? 'DOWNLOAD' : 'PROGRESSING', action: 'download', disabled: !params.data.exists }
    }
  }
];

export const FamilyInfoPrintCols = [
  { field: '#', headerName: '#', pinned: 'right', minWidth: 60, suppressSizeToFit: true },
  { field: 'code', headerName: 'FAMILY_CODE', minWidth: 150, class: 'col-4' },
  { field: 'supervisor', headerName: 'SUPERVISOR', minWidth: 150, class: 'col-4',
    cellRenderer: (params: any) => params.data.supervisor.firstName + ' ' + params.data.supervisor.lastName
  },
  { field: 'familyRelationType', headerName: 'SUPERVISOR_RELATION', minWidth: 150, class: 'col-4',
    cellRenderer: (params: any) => params.data.supervisor.familyRelationType
  },
  { field: 'phone', headerName: 'SUPERVISOR_PHONE', minWidth: 150, class: 'col-4', cellRenderer: (params: any) => params.data.supervisor.phone },
  { field: 'address', headerName: 'SUPERVISOR_ADDRESS', minWidth: 150, class: 'col-8', cellRenderer: (params: any) => params.data.supervisor.address },
  { field: 'fatherName', headerName: 'FATHER_NAME', minWidth: 150, class: 'col-4' },
  { field: 'motherName', headerName: 'MOTHER_NAME', minWidth: 150, class: 'col-4' },
  { field: 'familyMembersCount', headerName: 'FAMILY_MEMBERS_COUNT', minWidth: 150, class: 'col-4' },
  { field: 'branch', headerName: 'BRANCH', minWidth: 150, class: 'col-4' },
  { field: 'beneficiaryType', headerName: 'BENEFICIARY_TYPE', minWidth: 150, class: 'col-4' },
  { field: 'familyType', headerName: 'FAMILY_TYPE', minWidth: 150, class: 'col-4',
    cellRenderer: (params: any) => translatePipe.transform(FamilyTypes[params.data.familyType])
  },
  { field: 'economicStatus', headerName: 'ECONOMIC_STATUS', minWidth: 150, class: 'col-4',
    cellRenderer: (params: any) => translatePipe.transform(EconomicStatuses[params.data.economicStatus])
  },
  { field: 'createDate', headerName: 'CREATE_DATE', minWidth: 150, class: 'col-4',
    cellRenderer: (params: any) => datePipe.transform(params.data.createDate, 'YYYY-MM-dd')
  },
  { field: 'statisticalNo', headerName: 'STATISTICAL_NUMBER', minWidth: 150, class: 'col-4' },
  { field: 'rationCardNumber', headerName: 'STOCK_CARD_NUMBER', minWidth: 150, class: 'col-4' },
  { field: 'foodIntakeStatus', headerName: 'FAMILY_FOOD_CONSUMPTION_STATUS', minWidth: 150, class: 'col-4',
    cellRenderer: (params: any) => FoodIntakeStatuses[params.data.foodIntakeStatus]
  },
  { field: 'isIncluded', headerName: 'IS_FAMILY_ELIGIBLE', minWidth: 150, class: 'col-4',
    cellRenderer: (params: any) => params.data.isIncluded === true ? 'YES' : (params.data.isIncluded === false ? 'NO' : '')
  },
  { field: 'lastFinalConfirm', headerName: 'LAST_DISCOVERY_DATE', minWidth: 150, class: 'col-4',
    cellRenderer: (params: any) => datePipe.transform(params.data.lastFinalConfirm, 'YYYY-MM-dd')
  },
  { field: 'mukhtar', headerName: 'MUKHTAR', minWidth: 150, class: 'col-4' },
  { field: 'address', headerName: 'ADDRESS', minWidth: 150, class: 'col-12' }
];
